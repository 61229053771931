import { useRef, useState } from "react"
import { useTranslation } from "react-i18next"

import { ActionType, ProColumns } from "@ant-design/pro-components"
import { Button, Col, Form, Input, Modal, Row } from "antd"

import { useForm } from "antd/es/form/Form"
import React from "react"
import { useNavigate, useParams } from "react-router-dom"
import { AntDProTable } from "../../../../components/antdProTable"
import { applicationService } from "../../../../services/applicationService"
import { Attachment } from "../../../../models/attachment"

import 'suneditor/dist/css/suneditor.min.css'; // Import Sun Editor's CSS File
import Swal from "sweetalert2"
import { replaceWithPlaceholders } from "../../../../tools/demo"
import { ApplicationYears } from "../../../../models/applicationYears"
import Editor, { DiffEditor, useMonaco, loader } from '@monaco-editor/react';

//Start
export function AttachmentSettingPage() {
    const { t } = useTranslation()
    const { id } = useParams();
    const navigate = useNavigate();
    const [loading, setLoading] = useState<boolean>(false)
    const [addAttachment, setAddAttachment] = useState<{ id: string, body: string }>()
    //const editor = useRef<SunEditorCore>();

    let tableRef = useRef<ActionType>()

    const [f2] = useForm();//createOne

    const [setting, setSetting] = useState<ApplicationYears>()
    React.useEffect(() => {
        getApplicationGroupSetting()
    }, [])
    function getApplicationGroupSetting() {
        applicationService.getSetting(id || "").then(d => {
            if (d.status == "OK") {
                setSetting(d.reference)
            } else {
                Swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: 'Something went wrong!'
                })
            }
        })
    }
    const reload = () => {
        tableRef.current?.reload()
    }
    //const getSunEditorInstance = (sunEditor: SunEditorCore) => {
    //    editor.current = sunEditor;
    //};

    const columns: ProColumns<Attachment>[] = [
        {
            title: t("id"),
            dataIndex: "id"
        },
        {
            title: t("Actions"),
            dataIndex: "",
            search: false,
            fixed: 'right',
            width: 300,

            render: (text, record) => {
                return (
                    <>
                        <Button
                            onClick={() => {
                                var win = window.open("", "Title", "toolbar=no,location=no,directories=no,status=no,menubar=no,scrollbars=yes,resizable=yes, width=812,height=842");
                                if (win) {
                                    win.document.body.innerHTML = replaceWithPlaceholders(record.body, setting);
                                    setTimeout(function () { win!.print() }, 1000);
                                }
                            }}
                            type="link"
                        >
                            {t("Preview")}
                        </Button>
                        <Button
                            onClick={() => {
                                setAddAttachment(record)
                            }}
                            type="link"
                        >
                            {t("Edit")}
                        </Button>
                        <Button
                            onClick={() => {
                                Swal.fire({
                                    title: 'Do you want to delete the attachment?',
                                    showCancelButton: true,
                                    confirmButtonText: 'Delete',
                                }).then((result) => {
                                    /* Read more about isConfirmed, isDenied below */
                                    if (result.isConfirmed) {
                                        applicationService.deleteAttachment(id || "", record.id).then((res) => {
                                            reload();
                                        })
                                    }
                                })
                            }}
                            type="link"
                        >
                            {t("Delete")}
                        </Button >

                    </>
                )
            }
        }
    ]

    return (
        <>

            <AntDProTable<Attachment>
                filterType={"light"}
                size={"small"}

                onRefCallback={(ref: any) => {
                    tableRef.current = ref.current
                }}
                pageSizeOptions={[10, 20, 50, 100, 200]}
                columns={columns}
                // columns={columns.filter((c) => c.key !== "remove")}
                rowKey="id"
                scroll={{ x: '80vw' }}
                // scroll={{ x: "max-content" }}
                toolbar={{
                    actions: [
                        <Button key="addAttachment" type="primary" onClick={() => setAddAttachment({ id: "", body: "" })}>
                            {t("New")}
                        </Button>
                    ],
                }}
                request={(
                    params,
                    sort,
                    filter,
                    skip,
                    take,
                    where,
                    sortBy,
                    order,
                    cb
                ) => {
                    setLoading(true);
                    applicationService.getAttachmentTemplateFromYearGroup(id || "", {
                        params,
                        sort,
                        filter,
                        skip,
                        take,
                        where,
                        sortBy: sortBy ? sortBy : "id",
                        order,
                    }).then(res => {
                        setLoading(false);
                        if (res.status === "OK") {
                            cb({
                                data: res.reference.result,
                                total: res.reference.recordTotal,
                                success: true,
                                page: params.current,
                            })
                        } else {
                            cb({
                                data: [],
                                total: 0,
                                success: false,
                                page: params.current,
                            })
                        }
                    }).catch(err => {
                        setLoading(false);
                        cb({
                            data: [],
                            total: 0,
                            success: false,
                            page: params.current,
                        })
                    })
                }}
            />

            {addAttachment && <Modal
                title={t("")}
                centered
                open={addAttachment !== undefined && addAttachment !== null}
                onOk={() => {
                    f2.submit();
                    //
                }}
                onCancel={() => setAddAttachment(undefined)}
                width={"100vw"}
            >
                <Form layout="vertical" form={f2} onFinish={props => {
                    let form = {
                        id: addAttachment?.id || "",
                        body: addAttachment?.body||""//editor.current!.getContents(true)
                    }
                    applicationService.updateAttachmentTemplate(id || "", form).then(d => {
                        if (d.status === "OK") {
                            setAddAttachment(undefined)
                            tableRef.current?.reload()
                        }
                    }).catch(p => {

                    })
                }}>
                    <Row gutter={16}>
                        <Col className="gutter-row" md={24} sm={24} xs={24}>
                            <Form.Item label={t("Attachment ID")} rules={[{ required: true }, { pattern: /^[a-zA-Z0-9_]+$/, message: t("Only letters, numbers and underscores allowed") }]}>
                                <Input value={addAttachment.id} onChange={(e) => {
                                    setAddAttachment({ ...addAttachment, id: e.target.value })
                                }} style={{ width: "100%" }} />
                            </Form.Item>
                        </Col>
                        <Col className="gutter-row" md={12} sm={24} xs={24}>
                         
                                <Editor
                                    height="90vh"
                                    defaultLanguage="html"
                                    onChange={e => setAddAttachment({ ...addAttachment, body: e! })}
                                    value={addAttachment.body || ""}
                                />
                                {/*<SunEditor
                                    setOptions={{ buttonList : [
                                        ['undo', 'redo', 'font', 'fontSize', 'formatBlock', 'paragraphStyle', 'blockquote'],
                                        ['bold', 'underline', 'italic', 'strike', 'subscript', 'superscript', 'removeFormat'],                              
                                        ['fontColor', 'hiliteColor', 'textStyle','removeFormat', 'outdent', 'indent', 'align', 'horizontalRule', 'list', 'table'],
                                        ['link', 'image',  'fullScreen', 'showBlocks', 'codeView', 'preview', 'print']
                                    ]}}
                                    getSunEditorInstance={getSunEditorInstance}
                                    defaultValue={addAttachment.body || ""}
                                />*/}
                        </Col>
                        <Col md={12} sm={24} xs={24}>
                            <iframe style={{ width: "100%", height: "90vh" }} srcDoc={addAttachment.body || ""} />
                        </Col>
                    </Row>
                </Form>
            </Modal>}

        </>
    )
}

