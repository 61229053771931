import axios from "axios";
import { Route, HashRouter  as Router, Routes } from "react-router-dom";
import './App.css';
import { Dashboard } from './views/dashboard/dashboard';
import { Landing } from './views/landing/landing';
import { LoginPage } from './views/login/login';
import { ApplicationForm } from "./views/applicationForm/applicationForm";
import ForgetPassword from "./views/login/forgetPassword";
import ForgetPasswordSuccessful from "./views/login/forgetPasswordSuccessful";
import ResetPassword from "./views/login/resetPassword";
import { Home } from "./views/dashboard/home/home";
import { ApplicationFormSubmitSuccessPage } from "./views/applicationForm/applicationFormSubmitSuccessPage";

axios.defaults.baseURL = process.env.NODE_ENV.toLocaleLowerCase() == "development" ? "http://localhost:8080" : process.env.REACT_APP_STAGING == "jellykite" ? "https://sscpsapi.jellykite.com" : "https://admissionapi.sscps.edu.hk";
axios.defaults.withCredentials = true;

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" Component={Landing}> </Route>
        <Route path="/admin-login" Component={LoginPage}></Route>
        <Route path="/forgetPasswordSuccessful" Component={ForgetPasswordSuccessful}></Route>
        <Route path="/resetPassword" Component={ResetPassword}></Route>
        <Route path="/forgetPassword" Component={ForgetPassword}></Route>
        <Route path="/:id/application-form" Component={ApplicationForm}></Route>
        <Route path="/:id/success/:applicationId" Component={ApplicationFormSubmitSuccessPage}></Route>
        <Route path="/dashboard/*" Component={Dashboard}>        
        </Route>
      </Routes>
    </Router >

  );
}

export default App;
