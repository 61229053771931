import { useTranslation } from "react-i18next";
import { FirstInterviewResult } from "../../../../models/application";
import React, { useEffect, useRef, useState } from "react";
import { Button, Form, Input, Space, Typography, Layout, Select, InputNumber, Row, Col, DatePicker, List, Skeleton, Modal } from "antd";
import { useForm } from "antd/es/form/Form";
import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";
import TextArea from "antd/es/input/TextArea";
import "./generalSetting.css";
import { applicationService } from "../../../../services/applicationService";
import { useParams } from "react-router-dom";
import SunEditor from "suneditor-react";
import SunEditorCore from "suneditor/src/lib/core";
import 'suneditor/dist/css/suneditor.min.css'; // Import Sun Editor's CSS File
import { replaceWithPlaceholders } from "../../../../tools/demo";
import { ApplicationYears } from "../../../../models/applicationYears";
import Swal from "sweetalert2";

const { Header, Content, Footer, Sider } = Layout;
const { RangePicker } = DatePicker;

const emailSort = [
    "firstInterview",
    "secondInterview",
    "thirdInterview",
    "modeBFirstInterview",
    "modeBSecondInterview",
    "modeBThirdInterview",
    "offerEmail",
    "failedEmail",
    "waitingList"
]
export function EmailTemplateSettingPage() {
    const [f1] = useForm();
    const { t } = useTranslation();
    const { id } = useParams();
    const [initLoading, setInitLoading] = useState(false);
    const [loading, setLoading] = useState(false);
    const [list, setList] = useState<{ id: string, subject: string, body: string, attachment: string }[]>([]);
    const [attachments, setAttachments] = useState<{ id: string }[]>([]);
    const [emailTemplate, setEmailTemplate] = useState<{ id: string, subject: string, body: string, attachment: string }>()
    const editor = useRef<SunEditorCore>();

    useEffect(() => {
        reloadTemplates();
        reloadAttachment();
    }, []);
    const [setting, setSetting] = useState<ApplicationYears>()
    React.useEffect(() => {
        getApplicationGroupSetting()
    }, [])
    function getApplicationGroupSetting() {
        applicationService.getSetting(id || "").then(d => {
            if (d.status == "OK") {
                setSetting(d.reference)
            } else {
                Swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: 'Something went wrong!'
                })
            }
        })
    }
    function reloadTemplates() {
        applicationService.getEmailTemplatesFromYearGroup(id || "").then(response => {
            if (response.status == "OK") {
                setList(response.reference.result.sort((a: any, b: any) => {
                    return emailSort.findIndex(c => c == a.id) - emailSort.findIndex(c => c == b.id)
                }))
            }
        })
    }

    function reloadAttachment() {
        applicationService.getAllAttachements(id || "").then(response => {
            if (response.status == "OK") {
                setAttachments(response.reference)
            }
        })
    }
    const getSunEditorInstance = (sunEditor: SunEditorCore) => {
        editor.current = sunEditor;
    };
    return <>

        <Row gutter={16}>
            <Col className="gutter-row" md={24} sm={24} xs={24}>
                <List
                    style={{ width: "100%" }}
                    loading={initLoading}
                    itemLayout="horizontal"
                    dataSource={list}
                    renderItem={(item) => (
                        <List.Item
                            actions={[<Button key="list-loadmore-edit" onClick={() => { setEmailTemplate(item); f1.setFieldsValue(item) }}>{t("Edit")}</Button>,<Button key="list-loadmore-edit" onClick={() => {  var win = window.open("", "Title", "toolbar=no,location=no,directories=no,status=no,menubar=no,scrollbars=yes,resizable=yes, width=812,height=842");
                            if (win) {
                                win.document.body.innerHTML = replaceWithPlaceholders(item.body, setting);
                                setTimeout(function () { win!.print() }, 1000);
                            }  }}>{t("Preview")}</Button>]}
                        >
                            <List.Item.Meta
                                title={<>{t(item.id)}</>}
                                description={<iframe style={{
                                    width: "100%",
                                    maxHeight: 300,
                                    border: "1px solid #ccc",
                                    borderRadius: 8
                                }} srcDoc={item.body}
                                    sandbox="allow-scripts"></iframe>}
                            />
                        </List.Item>
                    )}
                />
            </Col>
        </Row>
        {emailTemplate && <Modal
            title={t("")}
            centered
            open={emailTemplate !== undefined}
            onOk={() => {
                f1.submit();
            }}
            onCancel={() => setEmailTemplate(undefined)}
            width={1000}
        >
            <Form name="timeslotForm" layout="vertical" form={f1} initialValues={emailTemplate} onFinish={(value: { subject: string, attachment: string }) => {
                setLoading(true);
                applicationService.updateEmailTemplate(id || "", { ...emailTemplate, subject: value.subject, body: editor.current!.getContents(true), attachment: value.attachment }).then(response => {
                    if (response.status == "OK") {

                        setEmailTemplate(undefined);
                        reloadTemplates();
                    }
                    setLoading(false);
                })

            }}>
                <Row gutter={16}>


                    <Col className="gutter-row" md={24} sm={24} xs={24}>
                        <Form.Item label={t("Email Subject")} name={"subject"}>
                            <Input

                                style={{ width: '100%' }} />
                        </Form.Item>
                    </Col>
                    <Col className="gutter-row" md={24} sm={24} xs={24}>
                        <Form.Item label={t("Email Body")} name={"body"}>
                            <SunEditor
                                setOptions={{
                                    buttonList: [
                                        ['undo', 'redo', 'font', 'fontSize', 'formatBlock', 'paragraphStyle', 'blockquote'],
                                        ['bold', 'underline', 'italic', 'strike', 'subscript', 'superscript', 'removeFormat'],
                                        ['fontColor', 'hiliteColor', 'textStyle', 'removeFormat', 'outdent', 'indent', 'align', 'horizontalRule', 'list', 'table'],
                                        ['link', 'image', 'fullScreen', 'showBlocks', 'codeView', 'preview', 'print']
                                    ]
                                }}
                                getSunEditorInstance={getSunEditorInstance}
                                defaultValue={emailTemplate.body || ""}
                            />
                        </Form.Item>
                    </Col>
                    <Col className="gutter-row" md={24} sm={24} xs={24}>
                        <Form.Item label={t("Attachment")} name={"attachment"}>
                            <Select
                                allowClear
                                style={{ width: '100%' }}
                                options={attachments.map(p => { return { label: p.id, value: p.id } })}
                            />
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
        </Modal>}
    </>
}