import { Button, Card, Layout, Menu, theme } from 'antd';
import axios from "axios";
import React, { useEffect } from 'react';
import { toChineseDate } from '../../tools/translate';
import moment from 'moment';
import { Link } from 'react-router-dom';
import bg from "../../assests/images/home-page2.jpg"
import TweenOne from 'rc-tween-one';
import QueueAnim from 'rc-queue-anim';
import Title from 'antd/es/typography/Title';
import { MyFooter } from '../../components/footer';
import { MyHeader } from '../../components/header';
import { useTranslation } from 'react-i18next';
import { translate } from '../../locale/translation';
import i18n from "i18next"
import Countdown from 'antd/es/statistic/Countdown';
const { Header, Content, Footer, Sider } = Layout;

export function Landing() {
    const {
        token: { colorBgContainer },
    } = theme.useToken();
    const { t } = useTranslation();
    const [isDeadline, setIsDeadline] = React.useState(false);
    const [isStart, setIsStart] = React.useState(true);
    const [beginDate, setBeginDate] = React.useState<number>(0);
    const [endDate, setEndDate] = React.useState<number>(0);
    const [deadlineChinese, setDeadlineChinese] = React.useState("");
    const [deadlineEnglish, setDeadlineEnglish] = React.useState("");
    const [headerChinese, setHeaderChinese] = React.useState("");
    const [headerEnglish, setHeaderEnglish] = React.useState("");
    const [groupId, setGroupId] = React.useState();
    let chineseDate = ""
    useEffect(() => {
        let response = null;
        async function fetchData() {
            response = await axios.get('/application/getGradeOptions')
                .then(res => {
                    if (res && res.data && res.data.status === 'OK' && res.data.settings) {
                        console.log("res.data.settings");
                        console.log(res.data);
                        let getNow = res.data.settings.now;
                        if (res.data.settings.applicationFor == "G1") {
                            setHeaderChinese(`小一入學申請(${res.data.settings.year + '-' + (res.data.settings.year + 1)}年度)`)
                            setHeaderEnglish("G1 ADMISSION ONLINE APPLICATION (" + res.data.settings.year + '-' + (res.data.settings.year + 1) + ")")
                        } else if (res.data.settings.applicationFor == "G2") {
                            setHeaderChinese(`小二入學申請(${res.data.settings.year + '-' + (res.data.settings.year + 1)}年度)`)
                            setHeaderEnglish("G2 ADMISSION ONLINE APPLICATION (" + res.data.settings.year + '-' + (res.data.settings.year + 1) + ")")
                        } else if (res.data.settings.applicationFor == "G3") {
                            setHeaderChinese(`小三入學申請(${res.data.settings.year + '-' + (res.data.settings.year + 1)}年度)`)
                            setHeaderEnglish("G3 ADMISSION ONLINE APPLICATION (" + res.data.settings.year + '-' + (res.data.settings.year + 1) + ")")
                        } else if (res.data.settings.applicationFor == "G4") {
                            setHeaderChinese(`小四入學申請(${res.data.settings.year + '-' + (res.data.settings.year + 1)}年度)`)
                            setHeaderEnglish("G4 ADMISSION ONLINE APPLICATION (" + res.data.settings.year + '-' + (res.data.settings.year + 1) + ")")
                        } else if (res.data.settings.applicationFor == "G5") {
                            setHeaderChinese(`小五入學申請(${res.data.settings.year + '-' + (res.data.settings.year + 1)}年度)`)
                            setHeaderEnglish("G5 ADMISSION ONLINE APPLICATION (" + res.data.settings.year + '-' + (res.data.settings.year + 1) + ")")
                        } else if (res.data.settings.applicationFor == "G6") {
                            setHeaderChinese(`小六入學申請(${res.data.settings.year + '-' + (res.data.settings.year + 1)}年度)`)
                            setHeaderEnglish("G6 ADMISSION ONLINE APPLICATION (" + res.data.settings.year + '-' + (res.data.settings.year + 1) + ")")
                        } else if (res.data.settings.applicationFor == "G26") {
                            setHeaderChinese(`小二至小六入學申請(${res.data.settings.year + '-' + (res.data.settings.year + 1)}年度)`)
                            setHeaderEnglish("G2-G6 ADMISSION ONLINE APPLICATION (" + res.data.settings.year + '-' + (res.data.settings.year + 1) + ")")
                        } else {
                            setHeaderChinese(`入學申請`)
                            setHeaderEnglish("ADMISSION ONLINE APPLICATION")
                        }
                        if (res.data.settings.startDate) {
                            setBeginDate(res.data.settings.startDate);
                            if (getNow >= res.data.settings.endDate) {
                                setIsDeadline(true);
                                setIsStart(false);
                                setDeadlineEnglish(moment(res.data.settings.endDate).format('LLL'));
                                chineseDate = toChineseDate(res.data.settings.endDate);
                                setDeadlineChinese(chineseDate);
                            } else if (getNow > res.data.settings.startDate && getNow < res.data.settings.endDate) {
                                setIsDeadline(false);
                                setIsStart(true);
                                setDeadlineEnglish(moment(res.data.settings.startDate).format('LLL') + " - " + moment(res.data.settings.endDate).format('LLL'));
                                chineseDate = toChineseDate(res.data.settings.startDate);
                                setDeadlineChinese(chineseDate + " - " + toChineseDate(res.data.settings.endDate));
                            } else if (getNow <= res.data.settings.startDate) {
                                setIsDeadline(false);
                                setIsStart(false);
                                setDeadlineEnglish(moment(res.data.settings.startDate).format('LLL'));
                                chineseDate = toChineseDate(res.data.settings.startDate);
                                setDeadlineChinese(chineseDate);
                            }
                        }
                        if (res.data.settings.endDate) {
                            setEndDate(res.data.settings.endDate);
                        }
                        if (res.data.settings.groupId) {
                            setGroupId(res.data.settings.groupId);
                        }

                    }
                })
                .catch(err => {
                    console.error("[pages]-[HomePage]-[HomePage]-[useEffect1]-[err]");
                    console.error(err);
                });
        }
        fetchData();
    }, []);
    return (

        <Layout style={{ height: "100vh" }}>
            <div style={{ height: "100vh", width: "100vw", background: `url(${bg})`, backgroundSize: 'cover', position: "absolute", left: 0, top: 0, zIndex: 0 }}></div>
            <div style={{ height: "100vh", width: "100vw", background: "rgba(0, 0, 0,0.75)", position: "absolute", left: 0, top: 0, zIndex: 0 }}></div>
            {<MyHeader />}
            <Content className="site-layout" style={{ padding: '0', background: "transparent", zIndex: 2 }}>

                <div style={{ padding: "20px 20px", margin: 0 }}>
                    <div style={{
                        marginTop: 60,
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        flexDirection: "column"
                    }}>
                        <Title style={{ color: "white", textAlign: "center", marginTop: 0, marginBottom: "0.5em" }} level={1}>{t("ST STEPHEN'S COLLEGE PREPARATORY SCHOOL")}</Title>
                       
                        {groupId ? <>
                            <Title style={{ color: "white", textAlign: "center", marginTop: 0 }} level={3}> {i18n.language.toLowerCase().startsWith("en") ? `${headerEnglish}` : `${headerChinese}`} </Title>
                            <Title style={{ color: "white", textAlign: "center", marginTop: 0, marginBottom: "0.8em" }} level={5}>{isStart ? t("Application Start") : t("Application Deadline")}: {translate(deadlineEnglish, deadlineChinese, "")}</Title>
                            {
                                !isStart && !isDeadline && <Countdown title={<span style={{ color: "white" }}>{t("Application Start")}</span>} valueStyle={{ color: "white" }} value={beginDate} format={translate("DD:HH:mm:ss", "D 天 H 时 m 分 s 秒", "")} />
                            }
                            {
                                isStart && !isDeadline && <Countdown title={<span style={{ color: "white" }}>{t("Application Deadline")}</span>} valueStyle={{ color: "white" }} value={endDate} format={translate("DD:HH:mm:ss", "D 天 H 时 m 分 s 秒", "")} />
                            }
                            {!isStart || isDeadline ? (
                                ""
                            ) : (
                                <Link to={"/" + groupId + "/application-form"} style={{ color: "white", marginTop: 24 }}>
                                    <Button type="primary">{t("Fill In Application Form")}</Button>
                                </Link>
                            )}
                        </>:<><Title style={{ color: "white", textAlign: "center", marginTop: 0, marginBottom: "0.8em" }} level={3}>{t("Not yet open for admission applications")}</Title></>}
                    </div>
                </div>
            </Content>
            {<MyFooter />}
        </Layout>
    );
};

