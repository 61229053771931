import { useTranslation } from "react-i18next"

import { Breadcrumb, Tabs } from "antd"

import { HomeOutlined, TagsOutlined } from "@ant-design/icons"
import { Link } from "react-router-dom"
import { AllApplicationListPage } from "./applications/allapplications"
import { AttachmentSettingPage } from "./settings/attachementTemplate"
import { EmailTemplateSettingPage } from "./settings/emailTemplate"
import { GeneralSettingPage } from "./settings/generalSetting"
import { AllTimeslotListPage } from "./timeslots/alltimeslots"
import { DownloadApplicationListPage } from "./applications/download"
import { FirstInterviewResultPage } from "./applications/firstInterviewResult"
import { SecondInterviewResultPage } from "./applications/secondInterviewResult"
import { UploadApplicationListPage } from "./applications/upload"
import {TaskListPage} from "./applications/task"
//Start
export function ApplicationYearPage() {
    const { t } = useTranslation()

    return (
        <>
            <Breadcrumb
                style={{ marginBottom: 30 }}
                itemRender={(route, params, routes, paths) => {
                    if (route.href) {
                        return <Link to={route.href || "/"}> {route.title} </Link>
                    }
                    return route.title
                }}
                items={[
                    {
                        href: '/dashboard/',
                        title: <HomeOutlined />,
                    },
                    {
                        href: `/dashboard/applicationYears/`,
                        title: (
                            <>
                                <TagsOutlined />
                                <span>{t("Application Years")}</span>
                            </>
                        ),
                    },
                    {
                        title: t("Applications"),
                    },
                ]}
            />

            <Tabs
                defaultActiveKey="Applications"
                tabPosition={"left"}
                items={[{
                    label: t(`Applications`),
                    key: "Applications",
                    disabled: false,
                    children: <>
                        <Tabs
                            defaultActiveKey="AllApplications"
                            tabPosition={"top"}
                            items={[{
                                label: t(`All Applications`),
                                key: "AllApplications",
                                disabled: false,
                                children: <><AllApplicationListPage /></>,
                            }, {
                                label: t(`First Interview Task`),
                                key: "FirstInterviewResult",
                                disabled: false,
                                children: <><FirstInterviewResultPage /></>,
                            }, {
                                label: t(`Second Interview Task`),
                                key: "SecondInterviewResult",
                                disabled: false,
                                children: <><SecondInterviewResultPage /></>,
                            }, {
                                label: t(`Download`),
                                key: "download",
                                disabled: false,
                                children: <><DownloadApplicationListPage /></>,
                            }, {
                                label: t(`Upload`),
                                key: "upload",
                                disabled: false,
                                children: <><UploadApplicationListPage /></>,
                            }, {
                                label: t(`Task`),
                                key: "task",
                                disabled: false,
                                children: <><TaskListPage /></>,
                            }]}
                        />

                    </>,
                },
                {
                    label: t(`Timeslots`),
                    key: "Timeslots",
                    disabled: false,
                    children: <><AllTimeslotListPage /></>
                },
                {
                    label: t(`Settings`),
                    key: "Settings",
                    disabled: false,
                    children: <Tabs
                        defaultActiveKey="GeneralSetting"
                        tabPosition={"top"}
                        items={[{
                            label: t(`General`),
                            key: "GeneralSetting",
                            disabled: false,
                            children: <><GeneralSettingPage /></>,
                        }, {
                            label: t(`Email Template`),
                            key: "Email Template",
                            disabled: false,
                            children: <EmailTemplateSettingPage />,
                        }, {
                            label: t(`Attachments`),
                            key: "Attachments",
                            disabled: false,
                            children: <AttachmentSettingPage />,
                        }]}
                    />,
                }]}
            />
        </>
    )
}

