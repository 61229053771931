import { useEffect, useRef, useState } from "react"
import { useTranslation } from "react-i18next"

import { ActionType, ProColumns } from "@ant-design/pro-components"
import { Button, Switch, message, Image, Breadcrumb, Skeleton, Modal, Form, Row, Col, Input, Select } from "antd"

import { useNavigate, useParams } from "react-router-dom"
import moment from "moment"
import { DeleteOutlined, EditOutlined, HomeOutlined, KeyOutlined, LockOutlined, TagsOutlined, UnlockOutlined } from "@ant-design/icons"
import { User } from "../../../models/user"
import { AntDProTable } from "../../../components/antdProTable"
import { accountService } from "../../../services/accountService"
import Swal from "sweetalert2"
import { useForm } from "antd/es/form/Form"
//Start
export function AllUserListPage() {
    const { t } = useTranslation()
    const navigate = useNavigate();
    const [loading, setLoading] = useState<boolean>(false)
    const [menuActivekey, setMenuActiveKey] = useState<string>("application-all")
    const [filterByStatus, setFilterByStatus] = useState<number[]>([])
    const [changePasswordAccount, setChangePasswordAccount] = useState<User>()
    const [editAccount, setEditAccount] = useState<User>()
    const [changePasswordForm] = useForm();
    const [editAccountForm] = useForm();
    let tableRef = useRef<ActionType>()

    const reload = () => {
        tableRef.current?.reload()
    }
    useEffect(() => {
        reload();
    }, [filterByStatus])
    const columns: ProColumns<User>[] = [
        {
            title: t("Display Name"),
            dataIndex: "displayName",
            sorter: true,
        },
        {
            title: t("Role"),
            dataIndex: "role",
            sorter: true,
            valueEnum: {
                "admin": { text: t("Admin") },
                "user": { text: t("User") },
            }
        },
        {
            title: t("E-mail"),
            dataIndex: "email",
            sorter: true,
        },
        {
            title: t("Actions"),
            dataIndex: "",
            search: false,
            fixed: 'right',
            width: 300,

            render: (text, record) => {
                return (
                    <>
                        <Button
                            title={t("Change Password")}
                            type="link"
                            onClick={() => {
                                setChangePasswordAccount(record)
                                changePasswordForm.setFieldsValue({ password: "", confirmPassword: "" })
                            }}
                        >
                            <KeyOutlined />
                        </Button>
                        {!record.locked && <Button
                            onClick={() => {
                                Swal.fire({
                                    title: 'Do you want to lock the user account?',
                                    showCancelButton: true,
                                    confirmButtonText: 'Lock',
                                }).then((result) => {
                                    /* Read more about isConfirmed, isDenied below */
                                    if (result.isConfirmed) {
                                        accountService.lockUser(record.id).then((res) => {
                                            reload();
                                        })
                                    }
                                })
                            }}
                            title={t("Lock Account")}
                            type="link"
                        >
                            <LockOutlined />
                        </Button>}
                        {record.locked && <Button
                            onClick={() => {
                                Swal.fire({
                                    title: 'Do you want to unlock the user account?',
                                    showCancelButton: true,
                                    confirmButtonText: 'Lock',
                                }).then((result) => {
                                    /* Read more about isConfirmed, isDenied below */
                                    if (result.isConfirmed) {
                                        accountService.unlockUser(record.id).then((res) => {
                                            reload();
                                        })
                                    }
                                })
                            }}
                            title={t("Unlock Account")}
                            type="link"
                        >
                            <UnlockOutlined />
                        </Button>}
                        <Button
                            title={t("Edit")}
                            type="link"
                            onClick={() => {
                                setEditAccount(record)
                                editAccountForm.setFieldsValue(record)
                            }}
                        >
                            <EditOutlined />
                        </Button>

                        <Button
                            onClick={() => {
                                Swal.fire({
                                    title: 'Do you want to delete the attachment?',
                                    showCancelButton: true,
                                    confirmButtonText: 'Delete',
                                }).then((result) => {
                                    /* Read more about isConfirmed, isDenied below */
                                    if (result.isConfirmed) {
                                        accountService.deleteUser(record.id).then((res) => {
                                            reload();
                                        })
                                    }
                                })
                            }}
                            title={t("Delete Account")}
                            type="link"
                        >
                            <DeleteOutlined />
                        </Button>
                    </>
                )
            }
        }
    ]

    return (
        <>

            <AntDProTable<User>
                filterType={"light"}
                size={"small"}

                onRefCallback={(ref: any) => {
                    tableRef.current = ref.current
                }}
                pageSizeOptions={[10, 20, 50, 100, 200]}
                columns={columns}
                // columns={columns.filter((c) => c.key !== "remove")}
                rowKey="id"
                scroll={{ x: '80vw' }}
                // scroll={{ x: "max-content" }}
                toolbar={{

                    actions: [
                        <Button onClick={() => {
                            setEditAccount({ id: "", displayName: "", email: "", role: "admin", locked: false })
                            editAccountForm.setFieldsValue({ id: "", displayName: "", email: "", role: "admin", locked: false, password:"", confirmPassword:"" })
                        }} key="primary" type="primary">
                            {t("New Account")}
                        </Button>,
                    ],
                }}
                request={(
                    params,
                    sort,
                    filter,
                    skip,
                    take,
                    where,
                    sortBy,
                    order,
                    cb
                ) => {
                    if (filterByStatus.length > 0) {
                        where.Status = filterByStatus
                    }
                    setLoading(true);
                    accountService.getAllUser({
                        params,
                        sort,
                        filter,
                        skip,
                        take,
                        where,
                        sortBy,
                        order,
                    }).then(res => {
                        setLoading(false);
                        if (res.status === "OK") {
                            cb({
                                data: res.reference.result,
                                total: res.reference.recordTotal,
                                success: true,
                                page: params.current,
                            })
                        } else {
                            cb({
                                data: [],
                                total: 0,
                                success: false,
                                page: params.current,
                            })
                        }
                    }).catch(err => {
                        setLoading(false);
                        cb({
                            data: [],
                            total: 0,
                            success: false,
                            page: params.current,
                        })
                    })
                }}
            />
            <Modal
                title={t("")}
                centered
                open={changePasswordAccount !== undefined}
                onOk={() => {
                    changePasswordForm.submit();
                }}
                onCancel={() => setChangePasswordAccount(undefined)}
                width={1000}
            >
                <Form name="changePasswordForm" layout="vertical" form={changePasswordForm} initialValues={changePasswordAccount} onFinish={(props: { password: string, confirmPassword: string }) => {

                    accountService.changeUserPassword({ id: changePasswordAccount?.id || "", ...props }).then(d => {
                        if (d.status === "OK") {
                            setChangePasswordAccount(undefined)
                            tableRef.current?.reload()
                        }
                    }).catch(p => {

                    })
                }}>
                    <Row gutter={16}>
                        <Col className="gutter-row" md={24} sm={24} xs={24}>

                            <Form.Item
                                label={t("Password")}
                                name="password"
                                rules={[
                                    { required: true, message: t("Please input the new password!") },
                                ]}
                            >
                                <Input.Password />
                            </Form.Item>
                        </Col>
                        <Col className="gutter-row" md={24} sm={24} xs={24}>
                            <Form.Item
                                label={t("Confirm Password")}
                                name="confirmPassword"
                                rules={[
                                    { required: true, message: t("Please confirm your password!") },
                                    ({ getFieldValue }) => ({
                                        validator(_, value) {
                                            if (!value || getFieldValue("password") === value) {
                                                return Promise.resolve();
                                            }
                                            return Promise.reject(new Error(t("The two passwords that you entered do not match!")));
                                        },
                                    })
                                ]}
                            >
                                <Input.Password />
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </Modal>
            <Modal
                title={t("")}
                centered
                open={editAccount !== undefined}
                onOk={() => {
                    editAccountForm.submit();
                }}
                onCancel={() => setEditAccount(undefined)}
                width={1000}
            >
                <Form name="editAccountForm" layout="vertical" form={editAccountForm} initialValues={editAccount} onFinish={(props: { displayName: string, email: string, role: string }) => {
                    if (editAccount?.id == "") {
                        accountService.addtUser({ id: editAccount?.id || "", ...props }).then(d => {
                            if (d.status === "OK") {
                                setEditAccount(undefined)
                                tableRef.current?.reload()
                            }
                        }).catch(p => {

                        })
                    } else {
                        accountService.editUser({ id: editAccount?.id || "", ...props }).then(d => {
                            if (d.status === "OK") {
                                setEditAccount(undefined)
                                tableRef.current?.reload()
                            }
                        }).catch(p => {

                        })
                    }

                }}>
                    <Row gutter={16}>
                        <Col className="gutter-row" md={24} sm={24} xs={24}>
                            <Form.Item
                                label={t("Display Name")}
                                name="displayName"
                            >
                                <Input />
                            </Form.Item>
                        </Col>
                        <Col className="gutter-row" md={24} sm={24} xs={24}>

                            <Form.Item
                                label={t("Email")}
                                name="email"
                                rules={[
                                    { required: true, message: t("Please input the new password!") },
                                    { type: "email", message: t("Please enter a valid email!") },
                                ]}
                            >
                                <Input />
                            </Form.Item>
                        </Col>
                        <Col className="gutter-row" md={24} sm={24} xs={24}>

                            <Form.Item
                                label={t("Role")}
                                name="role"
                            >
                                <Select>
                                    <Select.Option value="admin">{t("Admin")}</Select.Option>
                                    <Select.Option value="user">{t("User")}</Select.Option>
                                </Select>
                            </Form.Item>
                        </Col>
                        {editAccount?.id == "" && <><Col className="gutter-row" md={24} sm={24} xs={24}>

                            <Form.Item
                                label={t("Password")}
                                name="password"
                                rules={[
                                    { required: true, message: t("Please input the new password!") },
                                ]}
                            >
                                <Input.Password />
                            </Form.Item>
                        </Col>
                            <Col className="gutter-row" md={24} sm={24} xs={24}>
                                <Form.Item
                                    label={t("Confirm Password")}
                                    name="confirmPassword"
                                    rules={[
                                        { required: true, message: t("Please confirm your password!") },
                                        ({ getFieldValue }) => ({
                                            validator(_, value) {
                                                if (!value || getFieldValue("password") === value) {
                                                    return Promise.resolve();
                                                }
                                                return Promise.reject(new Error(t("The two passwords that you entered do not match!")));
                                            },
                                        })
                                    ]}
                                >
                                    <Input.Password />
                                </Form.Item>
                            </Col></>}
                    </Row>
                </Form>
            </Modal>
        </>
    )
}

