import { TranslationOutlined } from "@ant-design/icons";
import { Menu } from "antd";
import { Header } from "antd/es/layout/layout";
import { useTranslation } from "react-i18next";


export function MyHeader() {
    const { t, i18n } = useTranslation()
    const changeLanguage = (lng: string) => {
      i18n.changeLanguage(lng) // Change language Globally
      // window.location.reload()
    }
    return (<Header style={{ display: 'flex', alignItems: 'center', zIndex:2 }}>
       
        <Menu
            theme="dark"
            mode="horizontal"
            style={{ display:"flex",flexDirection:"row-reverse"}}
        >
            <Menu.SubMenu
                key="lang"
                icon={<TranslationOutlined style={{ fontSize: '24px' }} />}
            >
                <Menu.Item
                    key="tc"
                    onClick={() => {
                        changeLanguage("tc")
                    }}
                >
                    繁
                </Menu.Item>
                <Menu.Item
                    key="en"
                    onClick={() => {
                        changeLanguage("en")
                    }}
                >
                    Eng
                </Menu.Item>
            </Menu.SubMenu>
        </Menu>
    </Header>);
}