export function toChineseDate(unix) {
    const d = new Date(unix);
    const str_year = String(d.getFullYear());
    const str_month = String(d.getMonth());
    const str_day = String(d.getDate());
    const int_hour = d.getHours();

    const json_chinese_one_to_ten = {
        '0': "零",
        '1': "一",
        '2': "二",
        '3': "三",
        '4': "四",
        '5': "五",
        '6': "六",
        '7': "七",
        '8': "八",
        '9': "九",
        '10': "十"
    }

    let str_chinese_year = '';
    let str_chinese_month = '';
    let str_chinese_day = '';
    let str_chinese_hour = '';

    for (var i = 0; i < str_year.length; i++) {
        str_chinese_year = str_chinese_year + json_chinese_one_to_ten[str_year[i]];
    }

    if (str_month < 10) {
        str_chinese_month = json_chinese_one_to_ten[(parseInt(str_month) + 1).toString()];
    } else {
        str_chinese_month = '十' + json_chinese_one_to_ten[(parseInt(str_month[1]) + 1).toString()]
    }

    if (str_day.length == 1) {
        str_chinese_day = json_chinese_one_to_ten[str_day];
    } else if (str_day[0] == '1') {
        if (str_day[1] == '0') {
            str_chinese_day = '十'
        } else {
            str_chinese_day = '十' + json_chinese_one_to_ten[str_day[str_day.length - 1]]
        }
    } else if (str_day[0] == '2') {
        if (str_day[1] == '0') {
            str_chinese_day = '二十'
        } else {
            str_chinese_day = '二十' + json_chinese_one_to_ten[str_day[str_day.length - 1]]
        }
    } else if (str_day[0] == '3') {

        if (str_day[1] == '0') {
            str_chinese_day = '三十'
        } else {
            str_chinese_day = '三十' + json_chinese_one_to_ten[str_day[str_day.length - 1]]
        }
    }

    if (int_hour < 12) {
        str_chinese_hour = '上午'
        if (int_hour == 11) {
            str_chinese_hour = str_chinese_hour + '十一'
        } else {
            str_chinese_hour = str_chinese_hour + json_chinese_one_to_ten[String(int_hour)]
        }
    } else if (int_hour <= 18) {
        str_chinese_hour = '下午'
        if (int_hour == 12) {
            str_chinese_hour = str_chinese_hour + '十二'
        } else {
            str_chinese_hour = str_chinese_hour + json_chinese_one_to_ten[String(int_hour - 12)]
        }
    } else {
        str_chinese_hour = '晚上'
        if (int_hour == 23) {
            str_chinese_hour = str_chinese_hour + '十一'
        } else if (int_hour == 24) {
            str_chinese_hour = str_chinese_hour + '十二'
        } else {
            str_chinese_hour = str_chinese_hour + json_chinese_one_to_ten[String(int_hour - 12)]
        }
    }

    return str_chinese_year + '年' + str_chinese_month + '月' + str_chinese_day + '日' + str_chinese_hour + '時正'

}
export function applicationStatusToEnum(status) {
    switch (status) {
        case 1: return "Applied";
        case 2: return "ContentError";
        case 3: return "ContentUpdated";
        case 4: return "ContentVerified";
        case 5: return "FirstRoundCandidate";
        case 6: return "FirstRoundArranged";
        case 7: return "FirstRoundAccepted";
        case 8: return "SecondRoundCandidate";
        case 9: return "SecondRoundArranged";
        case 10: return "SecondRoundAccepted";
        case 11: return "ThirdRoundCandidate";
        case 12: return "ThirdRoundArranged";
        case 13: return "ThirdRoundAccepted";
        case 14: return "Canceled";
        case 15: return "Successed";
        case 16: return "Rejected";
        case 17: return "OfferSent";
        case 18: return "OfferAccepted";
        case 19: return "OfferRejected";
        case 20: return "WaitingList";
        default: return status
    }
}