import i18n from "i18next"

export function translate(
  //   config: i18n,
  en: string,
  tc: string,
  fallback: string
) {
  const languageUsed = i18n.language.toLowerCase().startsWith("en")
    ? "en"
    : i18n.language.toLowerCase().startsWith("tc")
    ? "tc"   
    : i18n.options.fallbackLng
  return (
    (languageUsed == "en" ? en : languageUsed == "tc" ? tc : fallback) || fallback
  )
}
