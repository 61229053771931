import { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"

import { Button, Form, Select, Space, message } from "antd"

import ButtonGroup from "antd/es/button/button-group"
import { useNavigate, useParams } from "react-router-dom"
import LoadingButton from "../../../../components/loadingButton"
import { applicationService } from "../../../../services/applicationService"
import { Timeslot } from "../../../../models/timeslot"
import { Application, ApplicationStatus } from "../../../../models/application"
import dayjs from "dayjs"
import Swal from "sweetalert2"
//Start
export function ApplicationActionsPage(props: { application: Application }) {
    const { t } = useTranslation()
    const { id, app } = useParams();
    const [timeslots, setTimeslots] = useState<Timeslot[]>([])
    const [loading, setLoading] = useState<boolean>(false)
    const [firstInterviewForm] = Form.useForm();
    const [secondInterviewForm] = Form.useForm();
    const [thirdInterviewForm] = Form.useForm();
    const [offerDateForm] = Form.useForm();
    const [applicationStatusForm] = Form.useForm();
    const navigate = useNavigate();
    const [messageApi, contextHolder] = message.useMessage();

    useEffect(() => {
        applicationService.getAllTimeslots(id || "").then((res) => {
            if (res.status == "OK") {
                setTimeslots(res.reference)
                firstInterviewForm.setFieldsValue({ FirstInterviewDate: props.application?.FirstInterviewDate });
                secondInterviewForm.setFieldsValue({ SecondInterviewDate: props.application?.SecondInterviewDate });
                thirdInterviewForm.setFieldsValue({ ThirdInterviewDate: props.application?.ThirdInterviewDate });
                offerDateForm.setFieldsValue({ OfferDate: props.application?.OfferDate });
            }
        })
    }, [])
   
    return (
        <>
            <Form form={applicationStatusForm} initialValues={{ Status: props.application?.Status }} onFinish={(values) => {
                applicationService.updateApplicationStatus(id || "", app || "", values.Status).then((res) => {
                    if (res.status === "OK") {
                        message.success(t("Status updated"))
                        window.location.reload()
                    }
                })
            }}>
                <p style={{ fontWeight: "bold" }}>{t("Status")}</p>
                <Form.Item name="Status">
                    <Select
                        allowClear
                        style={{ width: '100%' }}
                        options={[{
                            label: t("Applied"),
                            value: ApplicationStatus.Applied
                        },
                        {
                            label: t("ContentError"),
                            value: ApplicationStatus.ContentError
                        },
                        {
                            label: t("ContentUpdated"),
                            value: ApplicationStatus.ContentUpdated
                        },
                        {
                            label: t("ContentVerified"),
                            value: ApplicationStatus.ContentVerified
                        },
                        {
                            label: t("FirstRoundCandidate"),
                            value: ApplicationStatus.FirstRoundCandidate
                        },
                        {
                            label: t("FirstRoundArranged"),
                            value: ApplicationStatus.FirstRoundArranged
                        },
                        {
                            label: t("FirstRoundAccepted"),
                            value: ApplicationStatus.FirstRoundAccepted
                        },
                        {
                            label: t("SecondRoundCandidate"),
                            value: ApplicationStatus.SecondRoundCandidate
                        },
                        {
                            label: t("SecondRoundArranged"),
                            value: ApplicationStatus.SecondRoundArranged
                        },
                        {
                            label: t("SecondRoundAccepted"),
                            value: ApplicationStatus.SecondRoundAccepted
                        },
                        {
                            label: t("ThirdRoundCandidate"),
                            value: ApplicationStatus.ThirdRoundCandidate
                        },
                        {
                            label: t("ThirdRoundArranged"),
                            value: ApplicationStatus.ThirdRoundArranged
                        },
                        {
                            label: t("ThirdRoundAccepted"),
                            value: ApplicationStatus.ThirdRoundAccepted
                        },
                        {
                            label: t("Canceled"),
                            value: ApplicationStatus.Canceled
                        },
                        {
                            label: t("Successed"),
                            value: ApplicationStatus.Successed
                        },
                        {
                            label: t("Rejected"),
                            value: ApplicationStatus.Rejected
                        },
                        {
                            label: t("OfferSent"),
                            value: ApplicationStatus.OfferSent
                        },
                        {
                            label: t("OfferAccepted"),
                            value: ApplicationStatus.OfferAccepted
                        },
                        {
                            label: t("OfferAccepted"),
                            value: ApplicationStatus.OfferRejected
                        },
                        {
                            label: t("WaitingList"),
                            value: ApplicationStatus.WaitingList
                        }]}
                    />
                </Form.Item>
                <Form.Item>
                    <Space>
                        <Button type="primary" htmlType="submit">
                            {t("Submit")}
                        </Button>
                    </Space>
                </Form.Item>
            </Form>
            <Form form={firstInterviewForm} initialValues={{ FirstInterviewDate: props.application?.FirstInterviewDate }} onFinish={(values) => {
                applicationService.updateApplicationFirstInterviewDate(id || "", app || "", values.FirstInterviewDate).then((res) => {
                    if (res.status === "OK") {
                        message.success(t("Status updated"))
                        window.location.reload()
                    }
                })
            }}>
                <p style={{ fontWeight: "bold" }}>{t("Select first interview timeslot")}</p>
                <Form.Item name="FirstInterviewDate">
                    <Select
                        disabled={props.application?.FirstInterviwer == "ModeB"}
                        placeholder={t("Select first interview timeslot")}
                        options={timeslots.filter((timeslot) => timeslot.timeslotType == 1).map((timeslot) => {
                            return {
                                label: `(${timeslot.used}/${timeslot.capacity}+${timeslot.extraCapacity}) ${dayjs(timeslot.timeslot).format("YYYY-MM-DD HH:mm")}`,
                                value: timeslot.id
                            }
                        })}
                        allowClear
                    >
                    </Select>
                </Form.Item>
                <Form.Item>
                    <Space>
                        <Button type="primary" htmlType="submit" disabled={props.application?.FirstInterviwer == "ModeB"}>
                            {t("Submit")}
                        </Button>
                    </Space>
                </Form.Item>
            </Form>
            <Form form={secondInterviewForm} initialValues={{ SecondInterviewDate: props.application?.SecondInterviewDate }} onFinish={(values) => {
                applicationService.updateApplicationSecondInterviewDate(id || "", app || "", values.SecondInterviewDate).then((res) => {
                    if (res.status === "OK") {
                        message.success(t("Status updated"))
                        window.location.reload()
                    }
                })
            }}>
                <p style={{ fontWeight: "bold" }}>{t("Select second interview timeslot")}</p>
                <Form.Item name="SecondInterviewDate">
                    <Select
                        placeholder={t("Select second interview timeslot")}
                        options={timeslots.filter((timeslot) => timeslot.timeslotType == 2).map((timeslot) => {
                            return {
                                label: `(${timeslot.used}/${timeslot.capacity}+${timeslot.extraCapacity}) ${dayjs(timeslot.timeslot).format("YYYY-MM-DD HH:mm")}`,
                                value: timeslot.id
                            }
                        })}
                        allowClear
                    >
                    </Select>
                </Form.Item>
                <Form.Item >
                    <Space>
                        <Button type="primary" htmlType="submit">
                            {t("Submit")}
                        </Button>
                    </Space>
                </Form.Item>
            </Form>
            <Form form={thirdInterviewForm} initialValues={{ ThirdInterviewDate: props.application?.ThirdInterviewDate }} onFinish={(values) => {
                applicationService.updateApplicationThirdInterviewDate(id || "", app || "", values.ThirdInterviewDate).then((res) => {
                    if (res.status === "OK") {
                        message.success(t("Status updated"))
                        window.location.reload()
                    }
                })
            }}>
                <p style={{ fontWeight: "bold" }}>{t("Select third interview timeslot")}</p>
                <Form.Item name="ThirdInterviewDate">
                    <Select
                        placeholder={t("Select third interview timeslot")}
                        options={timeslots.filter((timeslot) => timeslot.timeslotType == 3).map((timeslot) => {
                            return {
                                label: `(${timeslot.used}/${timeslot.capacity}+${timeslot.extraCapacity}) ${dayjs(timeslot.timeslot).format("YYYY-MM-DD HH:mm")}`,
                                value: timeslot.id
                            }
                        })}
                        allowClear
                    >
                    </Select>
                </Form.Item>
                <Form.Item>
                    <Space>
                        <Button type="primary" htmlType="submit">
                            {t("Submit")}
                        </Button>
                    </Space>
                </Form.Item>
            </Form>
            <Form form={offerDateForm} initialValues={{ OfferDate: props.application?.OfferDate }} onFinish={(values) => {
                applicationService.updateApplicationOfferDate(id || "", app || "", values.OfferDate).then((res) => {
                    if (res.status === "OK") {
                        message.success(t("Status updated"))
                        window.location.reload()
                    }
                })
            }}>
                <p style={{ fontWeight: "bold" }}>{t("Select offer timeslot")}</p>
                <Form.Item name="OfferDate">
                    <Select
                        placeholder={t("Select offer timeslot")}
                        options={timeslots.filter((timeslot) => timeslot.timeslotType == 10).map((timeslot) => {
                            return {
                                label: `(${timeslot.used}/${timeslot.capacity}+${timeslot.extraCapacity}) ${dayjs(timeslot.timeslot).format("YYYY-MM-DD HH:mm")} - ${dayjs(timeslot.toTimeslot).format("YYYY-MM-DD HH:mm")}`,
                                value: timeslot.id
                            }
                        })}
                        allowClear
                    >
                    </Select>
                </Form.Item>
                <Form.Item>
                    <Space>
                        <Button type="primary" htmlType="submit" >
                            {t("Submit")}
                        </Button>
                    </Space>
                </Form.Item>
            </Form>
            <LoadingButton disabled={loading} label={t("Resend First Interview Email")} load={async () => {
                try {
                    setLoading(true)
                    messageApi.open({
                        key: "sendEmail",
                        type: 'loading',
                        content: 'Loading...',
                    });
                    await applicationService.sendEmail(id || "", app || "", 1)
                } catch (e) {

                } finally {
                    setLoading(false)
                    messageApi.open({
                        key: "sendEmail",
                        type: 'success',
                        content: 'Done!',
                        duration: 2,
                    });
                    Swal.fire(
                        'Success',
                        'Email has been sent!',
                        'success'
                    )
                }
                return true
            }} />
            <LoadingButton disabled={loading} label={t("Resend Second Interview Email")} load={async () => {
                try {
                    setLoading(true)
                    messageApi.open({
                        key: "sendEmail",
                        type: 'loading',
                        content: 'Loading...',
                    });
                    await applicationService.sendEmail(id || "", app || "", 2)
                } catch (e) {

                } finally {
                    setLoading(false)
                    messageApi.open({
                        key: "sendEmail",
                        type: 'success',
                        content: 'Done!',
                        duration: 2,
                    });
                    Swal.fire(
                        'Success',
                        'Email has been sent!',
                        'success'
                    )
                }
                return true
            }} />
            <LoadingButton disabled={loading} label={t("Resend Third Interview Email")} load={async () => {
                try {
                    setLoading(true)
                    messageApi.open({
                        key: "sendEmail",
                        type: 'loading',
                        content: 'Loading...',
                    });
                    await applicationService.sendEmail(id || "", app || "", 3)
                } catch (e) {

                } finally {
                    setLoading(false)
                    messageApi.open({
                        key: "sendEmail",
                        type: 'success',
                        content: 'Done!',
                        duration: 2,
                    });
                    Swal.fire(
                        'Success',
                        'Email has been sent!',
                        'success'
                    )
                }

                return true
            }} />
            <LoadingButton disabled={loading} label={t("Resend Offer Email")} load={async () => {
                try {
                    setLoading(true)
                    messageApi.open({
                        key: "sendEmail",
                        type: 'loading',
                        content: 'Loading...',
                    });
                    await applicationService.sendEmail(id || "", app || "", 7)
                } catch (e) {

                } finally {
                    setLoading(false)
                    messageApi.open({
                        key: "sendEmail",
                        type: 'success',
                        content: 'Done!',
                        duration: 2,
                    });
                    Swal.fire(
                        'Success',
                        'Email has been sent!',
                        'success'
                    )
                }

                return true
            }} />
            <LoadingButton disabled={loading} label={t("Resend Reject Email")} load={async () => {
                try {
                    setLoading(true)
                    messageApi.open({
                        key: "sendEmail",
                        type: 'loading',
                        content: 'Loading...',
                    });
                    await applicationService.sendEmail(id || "", app || "", 8)
                } catch (e) {

                } finally {
                    setLoading(false)
                    messageApi.open({
                        key: "sendEmail",
                        type: 'success',
                        content: 'Done!',
                        duration: 2,
                    });
                    Swal.fire(
                        'Success',
                        'Email has been sent!',
                        'success'
                    )
                }

                return true
            }} />
            <LoadingButton disabled={loading} label={t("Resend Waiting List Email")} load={async () => {
                try {
                    setLoading(true)
                    messageApi.open({
                        key: "sendEmail",
                        type: 'loading',
                        content: 'Loading...',
                    });
                    await applicationService.sendEmail(id || "", app || "", 9)
                } catch (e) {

                } finally {
                    setLoading(false)
                    messageApi.open({
                        key: "sendEmail",
                        type: 'success',
                        content: 'Done!',
                        duration: 2,
                    });
                    Swal.fire(
                        'Success',
                        'Email has been sent!',
                        'success'
                    )
                }

                return true
            }} />

        </>
    )
}

