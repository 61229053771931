export const nationalityOption = [
    {
      value: "American 美國",
      label: "American 美國"
    },
    {
      value: "Australian 澳洲",
      label: "Australian 澳洲"
    },
    {
      value: "Canadian 加拿大",
      label: "Canadian 加拿大"
    },
    {
      value: "Chinese 中國",
      label: "Chinese 中國"
    },
    {
      value: "English 英國",
      label: "English 英國"
    },
    {
      value: "French 法國",
      label: "French 法國"
    },
    {
      value: "German 德國",
      label: "German 德國"
    },
    {
      value: "Japanese 日本",
      label: "Japanese 日本"
    },
    {
      value: "Korean 韓國",
      label: "Korean 韓國"
    },
    {
      value: "Malaysian 馬來西亞",
      label: "Malaysian 馬來西亞"
    },
    {
      value: "New Zealander 紐西蘭",
      label: "New Zealander 紐西蘭"
    },
    {
      value: "Portuguese 葡萄牙",
      label: "Portuguese 葡萄牙"
    },
    {
      value: "Singaporean 新加坡",
      label: "Singaporean 新加坡"
    },
    {
      value: "Swiss 瑞士",
      label: "Swiss 瑞士"
    },
    {
      value: "Other 其他",
      label: "Other 其他"
    }
];

export const genderOption = [
    {
      value: "M",
      label: "Male 男"
    },
    {
      value: "F",
      label: "Female 女"
    }
  ];

export const homeAddressRegionOptions = [
{
    value: "Hong Kong 香港",
    label: "Hong Kong 香港"
},
{
    value: "Kowloon 九龍",
    label: "Kowloon 九龍"
},
{
    value: "New Territories 新界",
    label: "New Territories 新界"
}
];

export const religionOption = [
{
    value: "Christian 基督教",
    label: "Christian 基督教"
},
{
    value: "Catholic 天主教",
    label: "Catholic 天主教"
},
{
    value: "Islam 伊斯蘭教",
    label: "Islam 伊斯蘭教" 
},
{
    value: "Hinduism 印度教",
    label: "Hinduism 印度教"
},
{
    value: "Buddhism 佛教",
    label: "Buddhism 佛教"
},
{
    value: "Taoism 道教",
    label: "Taoism 道教"
},
{
    value: "Sikhism 錫克教",
    label: "Sikhism 錫克教"
},
{
    value: "Judaism 猶太教",
    label: "Judaism 猶太教"
},
{
    value: "N/A 不適用",
    label: "N/A 不適用"
}
];

export const applyWithSiblingsOption = [
{
    value: true,
    label: "Yes"
},
{
    value: false,
    label: "No"
}
];

export const motherTongueOfTheApplicantOption = [
{
    value: "Cantonese 廣東話",
    label: "Cantonese"
},
{
    value: "Putonghua 普通語",
    label: "Putonghua"
},
{
    value: "English 英語",
    label: "English"
},
{
    value: "Other 其他",
    label: "Other"
},
];


export const siblingCampusOption = [
{
    value: "Preparatory School 小學",
    label: "Preparatory School"
},
{
    value: "College 中學",
    label: "College"
}
];


export const siblingPreparatoryGradeOption = [
{
    value: "G1",
    label: "G1"
},
{
    value: "G2",
    label: "G2"
},
{
    value: "G3",
    label: "G3"
},
{
    value: "G4",
    label: "G4"
},
{
    value: "G5",
    label: "G5"
},
{
    value: "G6",
    label: "G6"
}
];

export const siblingCollegeGradeOption = [
{
    value: "S1",
    label: "S1"
},
{
    value: "S2",
    label: "S2"
},
{
    value: "S3",
    label: "S3"
},
{
    value: "S4",
    label: "S4"
},
{
    value: "S5",
    label: "S5"
},
{
    value: "S6",
    label: "S6"
},
{
    value: "S7",
    label: "S7"
}
];

export const fatherCampusOption = [
{
    value: "",
    label: ""
},
{
    value: "Preparatory School 小學",
    label: "Preparatory School"
},
{
    value: "College 中學",
    label: "College",
}
];

export const motherCampusOption = [
{
    value: "",
    label: ""
},
{
    value: "Preparatory School 小學",
    label: "Preparatory School 小學"
},
{
    value: "College 中學",
    label: "College",
}
];

export const yearOptions:{value:string, label:string}[] = [];
const int_current_year = new Date().getFullYear() 

for(let i=int_current_year-10; i<=int_current_year; i++) {
    yearOptions.push({
        value: i.toString(),
        label: i.toString()
    })
}

export const siblingYearOption:{value:string, label:string}[] = [];
for(let i=int_current_year-20; i<=int_current_year; i++) {
    siblingYearOption.push({
        value: i.toString(),
        label: i.toString()
    })
}


export const fatherMotheryearOptions:{value:string, label:string}[] = [];
for(let i=1960; i<=int_current_year; i++) {
    fatherMotheryearOptions.push({
        value: i.toString(),
        label: i.toString()
    })
}
export const isSSCAAMembershipOption = [
{
    value: true,
    label: "Yes"
},
{
    value: false,
    label: "No"
}
];

export const hongkongDistrictOptions = [
{
    value: "Aberdeen 香港仔",
    label: "Aberdeen"
},
{
    value: "Ap Lei Chau 鴨脷洲",
    label: "Ap Lei Chau"
},
{
    value: "Central 中環",
    label: "Central"
},
{
    value: "Chai Wan 柴灣",
    label: "Chai Wan"
},
{
    value: "Happy Valley 跑馬地",
    label: "Happy Valley"
},
{
    value: "Jardine Lookout 渣甸山",
    label: "Jardine Lookout"
},
{
    value: "North Point 北角",
    label: "North Point"
},
{
    value: "Pok Fu Lam 薄扶林",
    label: "Pok Fu Lam"
},
{
    value: "Quarry Bay 鰂魚涌",
    label: "Quarry Bay"
},
{
    value: "Sai Wan 西環",
    label: "Sai Wan"
},
{
    value: "Siu Sai Wan 小西灣",
    label: "Siu Sai Wan"
},
{
    value: "Southern District 南區",
    label: "Southern District"
},
{
    value: "Stanley 赤柱",
    label: "Stanley"
},
{
    value: "Wan Chai 灣仔",
    label: "Wan Chai"
}
];

export const kowloonDistrictOptions = [
{
    value: "Diamond Hill 鑽石山",
    label: "Diamond Hill"
},
{
    value: "Ho Man Tin 何文田",
    label: "Ho Man Tin"
},
{
    value: "Hung Hom 紅磡",
    label: "Hung Hom"
},
{
    value: "Kowloon Bay 九龍灣",
    label: "Kowloon Bay"
},
{
    value: "Kowloon City 九龍城",
    label: "Kowloon City"
},
{
    value: "Kwun Tong 觀塘",
    label: "Kwun Tong"
},
{
    value: "Lai Chi Kok 荔枝角",
    label: "Lai Chi Kok"
},
{
    value: "Lam Tin 藍田",
    label: "Lam Tin"
},
{
    value: "Lok Fu 樂富",
    label: "Lok Fu"
},
{
    value: "Mei Foo 美孚",
    label: "Mei Foo"
},
{
    value: "Ngau Tau Kok 牛頭角",
    label: "Ngau Tau Kok"
},
{
    value: "Sau Mau Ping 秀茂坪",
    label: "Sau Mau Ping"
},
{
    value: "Sham Shui Po 深水埗",
    label: "Sham Shui Po"
},
{
    value: "To Kwa Wan 土瓜灣",
    label: "To Kwa Wan"
},
{
    value: "Tsim Sha Tsui 尖沙咀",
    label: "Tsim Sha Tsui"
},
{
    value: "Tsz Wan Shan 慈雲山",
    label: "Tsz Wan Shan"
},
{
    value: "Wong Tai Sin 黃大仙",
    label: "Wong Tai Sin"
},
{
    value: "Yau Ma Tei 油麻地",
    label: "Yau Ma Tei"
},
{
    value: "Yau Tong 油塘",
    label: "Yau Tong"
}
];

export const newterritoriesOptions = [
{
    value: "Cheungchau 長洲",
    label: "Cheungchau"
},
{
    value: "Fanling 粉嶺",
    label: "Fanling"
},
{
    value: "Fo Tan 火炭",
    label: "Fo Tan"
},
{
    value: "Kwai Chung 葵涌",
    label: "Kwai Chung"
},
{
    value: "Lantau Island 大嶼山",
    label: "Lantau Island"
},
{
    value: "Ma On Shan 馬鞍山",
    label: "Ma On Shan"
},
{
    value: "Sai Kung 西貢",
    label: "Sai Kung"
},
{
    value: "Sha Tin 沙田",
    label: "Sha Tin"
},
{
    value: "Sheung Shui 上水",
    label: "Sheung Shui"
},
{
    value: "Tai Po 大埔",
    label: "Tai Po"
},
{
    value: "Tin Shui Wai 天水圍",
    label: "Tin Shui Wai"
},
{
    value: "Tseung Kwan O 將軍澳",
    label: "Tseung Kwan O"
},
{
    value: "Tsing Yi 青衣",
    label: "Tsing Yi"
},
{
    value: "Tsuen Wan 荃灣",
    label: "Tsuen Wan"
},
{
    value: "Tuen Mun 屯門",
    label: "Tuen Mun"
},
{
    value: "Tung Chung 東涌",
    label: "Tung Chung"
},
{
    value: "Yuen Long 元朗",
    label: "Yuen Long"
}
];

export const applicationStatus = [
    {
      value:1,
      label:"Applied"
    },
    {
      value:2,
      label:"ContentError"
    },
    {
      value:3,
      label:"ContentUpdated"
    },
    {
      value:4,
      label:"ContentVerified"
    },
    {
      value:5,
      label:"FirstRoundCandidate"
    },
    {
      value:6,
      label:"FirstRoundArranged"
    },
    {
      value:7,
      label:"FirstRoundAccepted"
    },
    {
      value:8,
      label:"SecondRoundCandidate"
    },
    {
      value:9,
      label:"SecondRoundArranged"
    },
    {
      value:10,
      label:"SecondRoundAccepted"
    },
    {
      value:11,
      label:"ThirdRoundCandidate"
    },
    {
      value:12,
      label:"ThirdRoundArranged"
    },
    {
      value:13,
      label:"ThirdRoundAccepted"
    },
    {
      value:14,
      label:"Canceled"
    },
    {
      value:15,
      label:"Successed"
    },
    {
      value:16,
      label:"Rejected"
    },
  ]