import { Avatar, Card, Col, Layout, Row, theme, Typography } from 'antd';
import { Column } from '@ant-design/plots';
import React, { useEffect } from 'react';
import { EditOutlined, EllipsisOutlined, ManOutlined, SettingOutlined, WomanOutlined } from '@ant-design/icons';
import { applicationService } from '../../../services/applicationService';
import { t } from 'i18next';
const { Meta } = Card;
const { Title } = Typography;
export function Home() {
    const {
        token: { colorBgContainer },
    } = theme.useToken();
    const [state, setState] = React.useState<any>({
        noOfApplicationReceivedTotal: 0,
        noOfApplicationReceivedBoys: 0,
        noOfApplicationReceivedGrils: 0,
        noOfFirstRoundApplicationTotal: 0,
        noOfFirstRoundApplicationBoys: 0,
        noOfFirstRoundApplicationGirls: 0,
        noOfSecondRoundApplicationTotal: 0,
        noOfSecondRoundApplicationBoys: 0,
        noOfSecondRoundApplicationGirls: 0,
        noOfThirdRoundApplicationTotal: 0,
        noOfThirdRoundApplicationBoys: 0,
        noOfThirdRoundApplicationGirls: 0,
        noOfApplicationG2: 0,
        noOfApplicationG3: 0,
        noOfApplicationG4: 0,
        noOfApplicationG5: 0,
        noOfApplicationG6: 0,
        noOfApplicationG2M: 0,
        noOfApplicationG3M: 0,
        noOfApplicationG4M: 0,
        noOfApplicationG5M: 0,
        noOfApplicationG6M: 0,
        noOfApplicationG2F: 0,
        noOfApplicationG3F: 0,
        noOfApplicationG4F: 0,
        noOfApplicationG5F: 0,
        noOfApplicationG6F: 0,
        noOfApplicationTotalG1: 0,
        noOfApplicationTotalG26: 0,
        noOfApplicationTotal: 0
    })
    useEffect(() => {
        applicationService.getApplicationOverview().then((data:any) => {
            const values = data.reference;
            const upper = values["G1 DashBoard"];
            const lower = values["G2-G6 DashBoard"];
            setState({
              noOfApplicationReceivedBoys: upper.noOfApplicationReceivedBoys,
              noOfApplicationReceivedGrils: upper.noOfApplicationReceivedGrils,
              noOfAppliedApplicationBoys: upper.noOfAppliedApplicationBoys,
              noOfAppliedApplicationGirls: upper.noOfAppliedApplicationGirls,
              noOfFirstRoundApplicationBoys: upper.noOfFirstRoundApplicationBoys,
              noOfFirstRoundApplicationGirls: upper.noOfFirstRoundApplicationGirls,
              noOfSecondRoundApplicationBoys: upper.noOfSecondRoundApplicationBoys,
              noOfSecondRoundApplicationGirls: upper.noOfSecondRoundApplicationGirls,
              noOfThirdRoundApplicationBoys: upper.noOfThirdRoundApplicationBoys,
              noOfThirdRoundApplicationGirls: upper.noOfThirdRoundApplicationGirls,
              noOfWaitingListApplicationBoys: upper.noOfWaitingListApplicationBoys,
              noOfWaitingListApplicationGirls: upper.noOfWaitingListApplicationGirls,
              noOfOfferListApplicationBoys: upper.noOfOfferListApplicationBoys,
              noOfOfferApplicationGirls: upper.noOfOfferApplicationGirls,
              noOfRejectedApplicationBoys: upper.noOfRejectedApplicationBoys,
              noOfRejectedApplicationGirls: upper.noOfRejectedApplicationGirls,
  
              noOfApplicationG2: lower.noOfApplicationG2,
              noOfApplicationG2M: lower.noOfApplicationG2M,
              noOfApplicationG2F: lower.noOfApplicationG2F,
              noOfApplicationG3: lower.noOfApplicationG3,
              noOfApplicationG3M: lower.noOfApplicationG3M,
              noOfApplicationG3F: lower.noOfApplicationG3F,
              noOfApplicationG4: lower.noOfApplicationG4,
              noOfApplicationG4M: lower.noOfApplicationG4M,
              noOfApplicationG4F: lower.noOfApplicationG4F,
              noOfApplicationG5: lower.noOfApplicationG5,
              noOfApplicationG5M: lower.noOfApplicationG5M,
              noOfApplicationG5F: lower.noOfApplicationG5F,
              noOfApplicationG6: lower.noOfApplicationG6,
              noOfApplicationG6M: lower.noOfApplicationG6M,
              noOfApplicationG6F: lower.noOfApplicationG6F,
              noOfApplicationTotalG1: upper.noOfApplicationTotal,
              noOfApplicationTotalG26:lower.noOfApplicationTotal,
              noOfApplicationTotal:lower.noOfApplicationTotal+upper.noOfApplicationTotal,
            })
        })
    }, [])
    return (

        <>
            <Row gutter={24} style={{ marginBottom: 20 }}>
                <Col lg={8} md={12}>
                    <Card
                        title={t("Total Number of Applications")}
                    >
                        <Title level={2} style={{ textAlign: "right" }}>{state.noOfApplicationTotal}</Title>
                    </Card>
                </Col>
                <Col lg={8} md={24}>
                    <Card
                        title={t("Total Number of G1 Applications")}>
                        <Title level={2} style={{ textAlign: "right" }}>{state.noOfApplicationTotalG1}</Title>
                    </Card>
                </Col>
                <Col lg={8} md={24}>
                    <Card
                        title={t("Total Number of G2-G6 Applications")} >
                        <Title level={2} style={{ textAlign: "right" }}>{state.noOfApplicationTotalG26}</Title>
                    </Card>
                </Col>
            </Row>
            <Row gutter={24} style={{ marginBottom: 20 }}>
                <Col lg={16} md={24}>
                    <Card
                        title={t("G1 Applications Status")} >
                        <Column
                            data={[
                                {
                                    type: "Applied",
                                    gender: "M",
                                    value: state.noOfAppliedApplicationBoys
                                },
                                {
                                    type: "Applied",
                                    gender: "F",
                                    value: state.noOfApplicationReceivedGrils
                                },
                                {
                                    type: "FirstRound",
                                    gender: "M",
                                    value: state.noOfFirstRoundApplicationBoys
                                },
                                {
                                    type: "FirstRound",
                                    gender: "F",
                                    value: state.noOfFirstRoundApplicationGirls
                                },
                                {
                                    type: "SecondRound",
                                    gender: "M",
                                    value: state.noOfSecondRoundApplicationBoys
                                },
                                {
                                    type: "SecondRound",
                                    gender: "F",
                                    value:  state.noOfSecondRoundApplicationGirls
                                },
                                {
                                    type: "ThirdRound",
                                    gender: "M",
                                    value: state.noOfThirdRoundApplicationBoys
                                },
                                {
                                    type: "ThirdRound",
                                    gender: "F",
                                    value: state.noOfThirdRoundApplicationGirls
                                },
                                {
                                    type: "Waiting List",
                                    gender: "M",
                                    value: state.noOfWaitingListApplicationBoys
                                },
                                {
                                    type: "Waiting List",
                                    gender: "F",
                                    value: state.noOfWaitingListApplicationGirls
                                },
                                {
                                    type: "Offer",
                                    gender: "M",
                                    value: state.noOfOfferListApplicationBoys
                                },
                                {
                                    type: "Offer",
                                    gender: "F",
                                    value: state.noOfOfferApplicationGirls
                                },
                                {
                                    type: "Rejected",
                                    gender: "M",
                                    value: state.noOfRejectedApplicationBoys
                                },
                                {
                                    type: "Rejected",
                                    gender: "F",
                                    value: state.noOfRejectedApplicationGirls
                                }
                            ]}
                            xField={'type'}
                            yField={'value'}
                            stack={true}
                            colorField={'gender'}
                            scale={{ color: { palette: "tableau10" } }}
                            label={{
                                text: 'value',
                                textBaseline: 'bottom',
                                position: 'inside',
                            }}
                        />
                    </Card>
                </Col>
                <Col lg={8} md={24}>
                    <Card
                        title={t("G1 Applications Received")} >

                        <Column

                            data={[
                                {
                                    gender: "M",
                                    value: state.noOfApplicationReceivedBoys
                                },
                                {
                                    gender: "F",
                                    value: state.noOfApplicationReceivedGrils
                                },

                            ]}
                            xField={'gender'}
                            yField={'value'}
                            colorField={'gender'}
                            scale={{ color: { palette: "tableau10" } }}
                            label={{
                                text: 'value',
                                textBaseline: 'bottom',
                                position: 'inside',
                            }}
                        />
                    </Card>
                </Col>
            </Row>

            <Row gutter={24}>
                <Col lg={16} md={24}>
                    <Card
                        title={t("Grade Applied For")} >
                        <Column
                            data={[
                                {
                                    type: "G1",
                                    gender: "M",
                                    value: state.noOfApplicationReceivedBoys
                                },
                                {
                                    type: "G1",
                                    gender: "F",
                                    value: state.noOfApplicationReceivedGrils
                                },
                                {
                                    type: "G2",
                                    gender: "M",
                                    value: state.noOfApplicationG2M
                                },
                                {
                                    type: "G2",
                                    gender: "F",
                                    value: state.noOfApplicationG2F
                                },
                                {
                                    type: "G3",
                                    gender: "M",
                                    value: state.noOfApplicationG3M
                                },
                                {
                                    type: "G3",
                                    gender: "F",
                                    value: state.noOfApplicationG3F
                                },
                                {
                                    type: "G4",
                                    gender: "M",
                                    value: state.noOfApplicationG4M
                                },
                                {
                                    type: "G4",
                                    gender: "F",
                                    value: state.noOfApplicationG4F
                                },
                                {
                                    type: "G5",
                                    gender: "M",
                                    value: state.noOfApplicationG5M
                                },
                                {
                                    type: "G5",
                                    gender: "F",
                                    value: state.noOfApplicationG5F
                                },
                                {
                                    type: "G6",
                                    gender: "M",
                                    value: state.noOfApplicationG6M
                                },
                                {
                                    type: "G6",
                                    gender: "F",
                                    value: state.noOfApplicationG6F
                                }
                            ]}
                            xField={'type'}
                            yField={'value'}
                            stack={true}
                            colorField={'gender'}
                            scale={{ color: { palette: "tableau10" } }}
                            label={{
                                text: 'value',
                                textBaseline: 'bottom',
                                position: 'inside',
                            }}
                        />
                    </Card>
                </Col>
                <Col lg={8} md={24}>
                    <Card
                        title={t("Application Distribution")} >
                        <Title level={5}>G1: {state.noOfApplicationTotalG1}</Title>
                        <Title level={5}>G2: {state.noOfApplicationG2}</Title>
                        <Title level={5}>G3: {state.noOfApplicationG3}</Title>
                        <Title level={5}>G4: {state.noOfApplicationG4}</Title>
                        <Title level={5}>G5: {state.noOfApplicationG5}</Title>
                        <Title level={5}>G6: {state.noOfApplicationG6}</Title>
                    </Card>
                </Col>
            </Row>
        </>
    )
}

