// ** React Imports
import { Button, Col, Descriptions, Row, Space } from "antd"
import { t } from "i18next"
import {
  useState,
  ElementType,
  ChangeEvent,
  SyntheticEvent,
  useEffect,
} from "react"

import ReactQuill from "react-quill"
import "react-quill/dist/quill.snow.css"

const modules = {
  toolbar: [
    [{ header: "1" }, { header: "2" }, { header: "3" }, { font: [] }],
    [{ size: [] }],
    ["bold", "italic", "underline", "strike", "blockquote", "code", "code-block"],
    [
      { align: [] },
      { list: "ordered" },
      { list: "bullet" },
      { indent: "-1" },
      { indent: "+1" },
    ],
    ['direction', { 'align': [] }],
    ["link", "image", "video", 'formula'],
    ["clean"],
  ],
  clipboard: {
    // toggle to add extra line breaks when pasting HTML:
    matchVisual: false,
  },
}

interface Props {
  label:string
  disabled?:boolean
  load:  () => Promise<boolean>
}

//Start
const LoadingButton = (props: Props) => {
 
  const [isLoading, setIsLoading] = useState<boolean>(false)
  return (
    <>
        <Button disabled={props.disabled} loading={isLoading} onClick={()=>{
            setIsLoading(true);
            props.load().then(()=>{
                setIsLoading(false);
            })
        }}>{props.label}</Button>

    </>
  )
}

export default LoadingButton
