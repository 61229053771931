// import getConfig from "next/config"

import axios, { AxiosResponse } from "axios"

import { UserRegistrationForm } from "../models/userRegistration.form"
import apiClient, { handleError } from "./apiClient"

var defaultUser: any = undefined
if (typeof window !== "undefined") {
  // Perform localStorage action
  var usr = localStorage.getItem("user")
  if (usr) defaultUser = JSON.parse(usr)
}


export const userService = {
  login,
  logout
}

async function login(email: string, password: string) {
  try {
    let response = await apiClient
      .post<{
        status: "ok", token:string, reference: {
          displayName: string,
          email: string,
          photo: string,
          id: string
        }
      } | {
        status: "Error",
        error?: {
          msg: string
          code: string;
        }
      }>(`/login`, {
        email,
        password,
      });

    if (response.data.status === 'ok') {
      const user = response.data
      localStorage.setItem(
        "user",
          JSON.stringify({
            displayName: user.reference.displayName,
            email: user.reference.email,
            photo: user.reference.photo,
            id: user.reference.id,
          }
        )
      )
      return response.data
    } else {
      return response.data
    }
  } catch (e) {
    return handleError(e);
  }
}

function logout() {
  localStorage.clear()
}
