import { useTranslation } from "react-i18next"

import { Card } from "antd"
import logo from "../../assests/images/logo.png"

const ForgetPasswordSuccessful = () => {
  const { t } = useTranslation()

  return (
    <div style={{ width: "100%", margin: "0 auto" }}>
      <div
        style={{
          width: "100 %",
          minHeight: "100vh",
          display: "flex",
          flexDirection:"column",
          flexWrap: "wrap",
          justifyContent: "center",
          alignItems: "center",
          padding: 15,
          background: "#f2f2f2",
        }}
      >
        
        <img src={logo} style={{maxWidth:172, marginBottom:20}} />
        <Card
          style={{
            width: 500,
            borderRadius: 10,
            boxShadow: "5px 5px #e5e5e5  ",
            fontSize: "100px",
            paddingLeft: 20,
            paddingRight: 20,
          }}
        >
          <div style={{ fontSize: "25px", textAlign: "center" }}>
            <br />
            {t("Reset password email sent!")}
            <br />
            {t("Check your email")}
          </div>
        </Card>
      </div>
    </div>
  )
}

export default ForgetPasswordSuccessful
