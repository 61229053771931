import { useEffect, useRef, useState } from "react"
import { useTranslation } from "react-i18next"

import { ActionType, ProColumns } from "@ant-design/pro-components"
import { Button, Switch, message, Image, Breadcrumb, Skeleton, Modal } from "antd"

import { Application, FirstInterviewResult } from "../../../../models/application"
import { AntDProTable } from "../../../../components/antdProTable"
import axios from "axios"
import { applicationService } from "../../../../services/applicationService"
import { useNavigate, useParams } from "react-router-dom"
import moment from "moment"
import { AuditOutlined, HomeOutlined, TagsOutlined } from "@ant-design/icons"
import { FirstRoundInterviewResultPage } from "./firstround"
//Start
export function FirstInterviewResultPage() {
    const { t } = useTranslation()
    const { id } = useParams();
    const navigate = useNavigate();
    const [loading, setLoading] = useState<boolean>(false)
    const [menuActivekey, setMenuActiveKey] = useState<string>("application-all")
    const [filterByStatus, setFilterByStatus] = useState<number[]>([])
    const [application, setApplication] = useState<Application>()
    let tableRef = useRef<ActionType>()

    const reload = () => {
        tableRef.current?.reload()
    }
    useEffect(() => {
        reload();
    }, [filterByStatus])
    const columns: ProColumns<Application>[] = [
        {
            title: t("Application ID"),
            dataIndex: "ApplicationId",
            sorter: true,
            copyable: true,
        },
        {
            title: t("Applicant's Photo"),
            hideInSearch: true,
            dataIndex: "ApplicationForm.applicantPhoto",
            renderText(text, record, index, action) {
                return (
                    <><Image
                        preview={false}
                        src={text || ""}
                    /></>
                )
            }
        },
        {
            title: t("E-mail"),
            hideInSearch: true,
            copyable: true,
            dataIndex: "ApplicationForm.email"
        },
        {
            title: t("Surname (English)"),
            sorter: true,
            copyable: true,
            hideInSearch: true,
            dataIndex: "ApplicationForm.surname"
        },
        {
            title: t("Given Name (English)"),
            sorter: true,
            copyable: true,
            hideInSearch: true,
            dataIndex: "ApplicationForm.givenName"
        },
        {
            title: t("Gender"),
            hideInSearch: true,
            dataIndex: "ApplicationForm.gender"
        },
        {
            title: t("Grade"),
            sorter: true,
            hideInSearch: true,
            dataIndex: "ApplicationForm.gradeAppliedFor",
            valueEnum: {
                "G1": { text: t("G1") },
                "G2": { text: t("G2") },
                "G3": { text: t("G3") },
                "G4": { text: t("G4") },
                "G5": { text: t("G5") },
                "G6": { text: t("G6") },
            }
        },
        {
            title: t("Telephone"),
            hideInSearch: true,
            copyable: true,
            dataIndex: "ApplicationForm.telephone"
        },
        {
            title: t("Name in Chinese"),
            sorter: true,
            hideInSearch: true,
            copyable: true,
            dataIndex: "ApplicationForm.nameInChinese"
        },
        {
            title: t("Status"),
            dataIndex: "Status",
            hideInSearch: true,
            filters: true,
            sorter: true,
            onFilter: true,
            valueEnum: {
                1: { text: t("Applied") },
                2: { text: t("ContentError") },
                3: { text: t("ContentUpdated") },
                4: { text: t("ContentVerified") },
                5: { text: t("FirstRoundCandidate") },
                6: { text: t("FirstRoundArranged") },
                7: { text: t("FirstRoundAccepted") },
                8: { text: t("SecondRoundCandidate") },
                9: { text: t("SecondRoundArranged") },
                10: { text: t("SecondRoundAccepted") },
                11: { text: t("ThirdRoundCandidate") },
                12: { text: t("ThirdRoundArranged") },
                13: { text: t("ThirdRoundAccepted") },
                14: { text: t("Canceled") },
                15: { text: t("Successed") },
                16: { text: t("Rejected") },
                17: { text: t("OfferSent") },
                18: { text: t("OfferAccepted") },
                19: { text: t("OfferRejected") },
                20: { text: t("WaitingList") },
            }
        }, {
            title: t("Date of Application"),
            dataIndex: "ApplyDate",
            hideInSearch: true,
            sorter: true,
            renderText(text, record, index, action) {
                return (
                    <>{moment(new Date(text)).format("yyyy-MM-DD HH:mm:ss")}</>
                )
            }
        },
        {
            title: t("Actions"),
            dataIndex: "",
            search: false,
            hideInSearch: true,
            fixed: 'right',
            width: 300,

            render: (text, record) => {
                return (
                    <><Button
                        onClick={() => {
                            navigate(`/dashboard/applicationYears/${id}/${record.id}`)
                        }}
                        type="link"
                    >
                        {t("View")}
                    </Button>
                        <Button
                            onClick={() => {
                                setApplication(record);
                            }}
                            type="link"
                        >
                            <AuditOutlined />
                        </Button>
                    </>
                )
            }
        }
    ]

    return (
        <>

            <AntDProTable<Application>
                filterType={"light"}
                size={"small"}

                onRefCallback={(ref: any) => {
                    tableRef.current = ref.current
                }}
                pageSizeOptions={[10, 20, 50, 100, 200]}
                columns={columns}
                // columns={columns.filter((c) => c.key !== "remove")}
                rowKey="id"
                scroll={{ x: '80vw' }}
                // scroll={{ x: "max-content" }}

                toolbar={{

                    menu: {
                        type: 'dropdown',
                        activeKey: menuActivekey,
                        onChange: (activeKey) => {
                            let key = activeKey?.toString() || ""
                            switch (key) {
                                case 'application-all':
                                    setFilterByStatus([]);
                                    break;
                                case 'application-first':
                                    setFilterByStatus([5, 6, 7])
                                    break;
                                case 'application-second':
                                    setFilterByStatus([8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20])
                                    break;
                                case 'application-third':
                                    setFilterByStatus([11, 12, 13])
                                    break;
                                case 'application-final':
                                    setFilterByStatus([14, 15, 16, 17, 18, 19, 20])
                                    break;
                            }
                            setMenuActiveKey(key);
                        },
                        items: [
                            {
                                key: 'application-all',
                                label: <span>{t("All Applications")}</span>,
                            },
                            {
                                key: 'application-first',
                                label: <span>{t("First Interview")}</span>,
                            },
                            {
                                key: 'application-second',
                                label: <span>{t("Second Interview")}</span>,
                            },
                            {
                                key: 'application-third',
                                label: <span>{t("Third Interview")}</span>,
                            },
                            {
                                key: 'application-final',
                                label: <span>{t("Final Offer")}</span>,
                            },

                        ],
                    },
                    actions: [],
                }}
                request={(
                    params,
                    sort,
                    filter,
                    skip,
                    take,
                    where,
                    sortBy,
                    order,
                    cb
                ) => {
                    if (filterByStatus.length > 0) {
                        where.Status = filterByStatus
                    }
                    setLoading(true);
                    applicationService.getFirstRounNonCompleteApplicationsFromYearGroup(id || "", {
                        params,
                        sort,
                        filter,
                        skip,
                        take,
                        where,
                        sortBy,
                        order,
                    }).then(res => {
                        setLoading(false);
                        if (res.status === "OK") {
                            cb({
                                data: res.reference.result.map(d => {
                                    return {
                                        id: d.ApplicationId,
                                        ...d,
                                    }
                                }),
                                total: res.reference.recordTotal,
                                success: true,
                                page: params.current,
                            })
                        } else {
                            cb({
                                data: [],
                                total: 0,
                                success: false,
                                page: params.current,
                            })
                        }
                    }).catch(err => {
                        setLoading(false);
                        cb({
                            data: [],
                            total: 0,
                            success: false,
                            page: params.current,
                        })
                    })
                }}
            />
            <Modal title={t("")}
                centered
                open={application != undefined}

                footer={<></>}
                onCancel={() => setApplication(undefined)}
                onOk={() => setApplication(undefined)}
                width={1000}
            >
                {application && <FirstRoundInterviewResultPage form={application.FirstInterviewComments} onUpdate={(applicationForm: FirstInterviewResult | string) => {
                    setApplication({ ...application, FirstInterviewComments: applicationForm })
                    applicationService.updateFirstRoundInterviewResult(id || "", application?.id || "", applicationForm).then(res => {
                        if (res.status === "OK") {
                            setApplication(undefined)
                            tableRef.current?.reload()
                            message.success(t("Update Success"))
                        }
                    })
                }} />}
            </Modal>
        </>
    )
}

