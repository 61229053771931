// import getConfig from "next/config"

import axios, { AxiosResponse } from "axios"

import { UserRegistrationForm } from "../models/userRegistration.form"
import apiClient, { handleError } from "./apiClient"
import { Application } from "../models/application"
import { Timeslot } from "../models/timeslot"
import { ApplicationYears } from "../models/applicationYears"

var defaultUser: any = undefined
if (typeof window !== "undefined") {
  // Perform localStorage action
  var usr = localStorage.getItem("user")
  if (usr) defaultUser = JSON.parse(usr)
}


export const settingService = {
    getHashTags
}
async function getHashTags() {
  try {

   

    let response = await apiClient
      .post<{
        status: "OK", reference: {
            Language:string[]
            NegativeLanguage:string[]
            NegativePersonality:string[]            
            NegativePersonalityAbilities:string[]            
            NegativePersonalityAppearances:string[]            
            PositiveLanguage:string[]            
            PositivePersonality:string[]            
            PositivePersonalityAbilities:string[]            
            PositivePersonalityAppearances:string[]            
            Skill:string[]
        }
      } | {
        status: "Error",
        error?: {
          msg: string
          code: string;
        }
      }>(`/admin/maintenance/getHashtags`);

    if (response.data.status === 'OK') {
      const user = response.data

      return response.data
    } else {
      return response.data
    }
  } catch (e) {
    return handleError(e);
  }
}

