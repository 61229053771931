import {
    ProCard
} from '@ant-design/pro-components';
import { Button, Checkbox, Col, DatePicker, Descriptions, Divider, Form, Input, Row, Select, Steps, Switch, message, Modal } from 'antd';

import { Layout, theme } from 'antd';
import FormItem from 'antd/es/form/FormItem';
import Title from 'antd/es/typography/Title';
import Upload from 'antd/es/upload';
import axios from 'axios';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import photoRequirement from "../../assests/images/photo-requirement.png";
import { MyFooter } from '../../components/footer';
import { MyHeader } from '../../components/header';
import { applyWithSiblingsOption, genderOption, homeAddressRegionOptions, hongkongDistrictOptions, isSSCAAMembershipOption, kowloonDistrictOptions, motherTongueOfTheApplicantOption, nationalityOption, newterritoriesOptions, religionOption, siblingCollegeGradeOption, siblingPreparatoryGradeOption, siblingYearOption, yearOptions } from '../../options/options';
import { within20Years } from '../../tools/datafn';
import "./applicationForm.css";
import { applicationService } from '../../services/applicationService';
import { Link, useNavigate, useParams } from 'react-router-dom';
import dayjs from 'dayjs';
import { LoadingOutlined } from '@ant-design/icons';

const { Header, Content, Footer, Sider } = Layout;

const { Option } = Select;


export function ApplicationFormSubmitSuccessPage(props: { admin?: boolean, groupId?: string }) {
    const { t } = useTranslation();
    const { id, applicationId } = useParams();
    useEffect(() => {

    }, []);



    return (
        <>
            <Layout style={{ minHeight: "100vh" }}>
                {!(props.admin) && <MyHeader />}
                <Content style={{ padding: '0 50px' }}>
                    <Layout style={{ padding: '24px 0', margin: "24px auto", backgroundColor: "white", borderRadius: 24 }}>

                        <Col style={{ textAlign: "center" }} >
                            <Title style={{ textAlign: "center", marginTop: 0 }} level={3}> {t("ADMISSION APPLICATION COMPLETED")}</Title>
                        </Col>
                        <Col style={{ textAlign: "center" }} >
                            <Title style={{ textAlign: "center", marginTop: 0 }} level={3}> {t("Application Number")}: {applicationId}</Title>
                        </Col>
                        <Col style={{ textAlign: "center" }} >
                            <Title style={{ textAlign: "center", marginTop: 0 }} level={3}>{t("A confirmation email with the interview details included will be sent to you soon.")}<br />{t("In case of any queries, please feel free to contact us at 2813-0272 or admission@sscps.edu.hk")}</Title>
                        </Col>

                        <Col style={{ textAlign: "center", marginTop:32 }}>
                            <Link to="/" > <Button color="primary">{t("Back")}</Button></Link>
                        </Col>
                    </Layout>
                </Content >

                {< MyFooter />}
            </Layout >
        </>
    );
};