import { useTranslation } from "react-i18next"
// ** React Imports
import {  useNavigate } from "react-router-dom"
// ** Next Imports

import { Button, Card, Form, Input, Space } from "antd"

import {MyFooter} from "../../components/footer"
import logo from "../../assests/images/logo.png"
import { userService } from "../../services/userService"

export function LoginPage ()  {
  const { t } = useTranslation() // Here's where i18n comes from.

  const navigate = useNavigate()


  const onFinish = (values: {
    email:string,
    password:string
  }) => {
    userService.login(values.email, values.password).then(d=>{
        console.log(d);
        if(d.status =="ok"){        
            localStorage.setItem('userToken', d.token)
            navigate("/dashboard");
        }else{

        }
    })
  }

  return (
    <div style={{ width: "100%", margin: "0 auto" }}>
      <div
        style={{
          width: "100%",
          minHeight: "100vh",
          display: "flex",
          flexWrap: "wrap",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          padding: 15,
          background: "#f2f2f2",
        }}
      >
        <img src={logo} style={{maxWidth:172, marginBottom:20}} />
        <Card
          title={
            <div style={{ fontSize: "25px", textAlign: "center" }}>
              {t("SSCPS Admission System")}
            </div>
          }
          style={{
            width: 500,
            borderRadius: 10,
            boxShadow: "5px 5px #e5e5e5  ",
            fontSize: "100px",
            paddingLeft: 20,
            paddingRight: 20,
          }}
        >
          <Form onFinish={onFinish} autoComplete="off" layout="vertical">           
            <Form.Item
              label={t("Email")}
              name="email"
              rules={[
                { required: true, message: "Please input your email!" },
              ]}
            >
              <Input type="email" />
            </Form.Item>
            <Form.Item
              label={t("Password")}
              name="password"
              rules={[
                { required: true, message: "Please input your password!" },
              ]}
            >
              <Input.Password />
            </Form.Item>

            <Form.Item>
              <Space>
                <Button type="primary" htmlType="submit">
                  {t("Login")}
                </Button>
                <Button
                  type="default"
                  onClick={() => {
                    navigate("/forgetPassword")
                  }}
                >
                  {t("Forget Password")}
                </Button>
                
          
              </Space>
            </Form.Item>
          </Form>
        </Card>
        <div style={{ marginTop: 20 }}>
          <MyFooter />
        </div>
      </div>
    </div>
  )
}


