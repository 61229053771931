import {
    ProCard
} from '@ant-design/pro-components';
import { Button, Checkbox, Col, DatePicker, Descriptions, Divider, Form, Input, Row, Select, Steps, Switch, message, Modal } from 'antd';

import { Layout, theme } from 'antd';
import FormItem from 'antd/es/form/FormItem';
import Title from 'antd/es/typography/Title';
import Upload from 'antd/es/upload';
import axios from 'axios';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import photoRequirement from "../../assests/images/photo-requirement.png";
import { MyFooter } from '../../components/footer';
import { MyHeader } from '../../components/header';
import { applyWithSiblingsOption, genderOption, homeAddressRegionOptions, hongkongDistrictOptions, isSSCAAMembershipOption, kowloonDistrictOptions, motherTongueOfTheApplicantOption, nationalityOption, newterritoriesOptions, religionOption, siblingCollegeGradeOption, siblingPreparatoryGradeOption, siblingYearOption, yearOptions } from '../../options/options';
import { within20Years } from '../../tools/datafn';
import "./applicationForm.css";
import { applicationService } from '../../services/applicationService';
import { useNavigate, useParams } from 'react-router-dom';
import dayjs from 'dayjs';
import { LoadingOutlined } from '@ant-design/icons';
import Swal from 'sweetalert2';

const { Header, Content, Footer, Sider } = Layout;

const { Option } = Select;


export function ApplicationForm(props: { admin?: boolean, groupId?: string }) {
    const { t } = useTranslation();
    const [current, setCurrent] = useState(0)
    const { token } = theme.useToken();
    const { id } = useParams();
    const navigate = useNavigate();
    const [step0Form] = Form.useForm();
    const [step1Form] = Form.useForm();
    const [step2Form] = Form.useForm();
    const [step3Form] = Form.useForm();
    let today = new Date();
    const dd = String(today.getDate()).padStart(2, '0');
    const mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
    const yyyy = today.getFullYear();
    const todayDDMMYYY = dd + '/' + mm + '/' + yyyy;
    const todayISO = today.toISOString();

    const [isPhotoValid, setIsPhotoValid] = React.useState(false);

    const [gradeOption, setGradeOption] = React.useState<{ value: string, label: string }[]>([]);

    const [isLoading, setIsLoading] = React.useState<boolean>(false);
    // 1. Documentation of Studnet
    const [birthCertificate, setBirthCerticate] = React.useState("");
    const [birthCertificateFileType, setBirthCerticateFileType] = React.useState("");
    const [hkIDPassportCopy, setHKIDPassportCopy] = React.useState("");
    const [hkIDPassportCopyFileType, setHKIDPassportCopyFileType] = React.useState("");
    const [applicantPhoto, setApplicantPhoto] = React.useState("");
    const [applicantPhotoFileType, setApplicantPhotoFileType] = React.useState("");
    const [bankReceiptCopy, setBankReceiptCopy] = React.useState("");
    const [bankReceiptCopyFileType, setBankReceiptCopyFileType] = React.useState("");

    const [bankReferenceNumber, setBankReferenceNumber] = React.useState("");
    const [dateOfApplication, setDateOfApplication] = React.useState(todayDDMMYYY);

    // 2. Student's Information
    const [surname, setSurname] = React.useState("");
    const [givenName, setGivenName] = React.useState("");
    const [nameInChinese, setNameInChinese] = React.useState("");
    const [nationality, setNationality] = React.useState("");
    const [nationalityOther, setNationalityOther] = React.useState("");
    const [gender, setGender] = React.useState("");
    const [dateOfBirth, setDateOfBirth] = React.useState<string>("");
    const [placeOfBirth, setPlaceOfBirth] = React.useState("");
    const [hkBirthCertificatePassport, setHKBirthCertificatePassport] = React.useState("");

    const [homeAddressRegion, setHomeAddressRegion] = React.useState("");
    const [homeAddressDistrict, setHomeAddressDistrict] = React.useState("");
    const [homeAddress, setHomeAddress] = React.useState("");
    const [telephone, setTelephone] = React.useState("");
    const [email, setEmail] = React.useState("");

    const [religion, setReligion] = React.useState("");
    const [noOfSiblings, setNoOfSiblings] = React.useState("1");
    const [applicantPositionAmongSiblings, setApplicantPositionAmongSiblings] = React.useState("1");
    const [applyWithSiblings, setApplyWithSiblings] = React.useState(false);
    const [gradeAppliedFor, setGradeAppliedFor] = React.useState("");
    const [motherTongueOfTheApplicant, setMotherTongueOfTheApplicant] = React.useState("");
    const [motherTongueOther, setMotherTongueOther] = React.useState("");
    const [presentSchool, setPresentSchool] = React.useState("");
    const [yearsAttendedA, setYearsAttendedA] = React.useState("");
    const [yearsAttendedB, setYearsAttendedB] = React.useState("");

    const [siblingNameOfAttend, setSiblingNameOfAttend] = React.useState("");
    const [siblingYearOfAttendA, setSiblingYearOfAttendA] = React.useState("");
    const [siblingYearOfAttendB, setSiblingYearOfAttendB] = React.useState("");
    const [siblingCampusPreparatory, setSiblingCampusPreparatory] = React.useState(false);
    const [siblingCampusCollege, setSiblingCampusCollege] = React.useState(false);
    const [siblingGrade, setSiblingGrade] = React.useState("");

    // 3. Parents' Information
    const [fatherInformation, setFatherInformation] = React.useState<boolean>(true);
    const [fatherEnglishName, setFatherEnglishName] = React.useState<string>("");
    const [fatherChineseName, setFatherChineseName] = React.useState<string>("");
    const [fatherIDCardPassport, setFatherIDCardPassport] = React.useState("");
    const [fatherOccupation, setFatherOccupation] = React.useState<string>("");
    const [fatherTitle, setFatherTitle] = React.useState<string>("");
    const [fatherTelephoneOffice, setFatherTelephoneOffice] = React.useState<string>("");
    const [fatherTelephoneMobile, setFatherTelephoneMobile] = React.useState<string>("");
    const [fatherOfficeAddress, setFatherOfficeAddress] = React.useState<string>("");
    const [fatherYearOfAttendanceA, setFatherYearOfAttendanceA] = React.useState("");
    const [fatherYearOfAttendanceB, setFatherYearOfAttendanceB] = React.useState<string>("");
    const [fatherCampusPreparatorySchool, setFatherCampusPreparatorySchool] = React.useState<boolean>(false);
    const [fatherCampusCollege, setFatherCampusCollege] = React.useState<boolean>(false);
    const [fatherIsSSCAAMembership, setFatherIsSSCAAMembership] = React.useState<boolean>(false);
    const [fatherSSCAAMembershipNumber, setFatherSSCAAMembershipNumber] = React.useState<string>("");

    const [motherInformation, setMotherInformation] = React.useState<boolean>(true);
    const [motherEnglishName, setMotherEnglishName] = React.useState<string>("");
    const [motherChineseName, setMotherChineseName] = React.useState<string>("");
    const [motherIDCardPassport, setMotherIDCardPassport] = React.useState<string>("");
    const [motherOccupation, setMotherOccupation] = React.useState<string>("");
    const [motherTitle, setMotherTitle] = React.useState<string>("");
    const [motherTelephoneOffice, setMotherTelephoneOffice] = React.useState<string>("");
    const [motherTelephoneMobile, setMotherTelephoneMobile] = React.useState<string>("");
    const [motherOfficeAddress, setMotherOfficeAddress] = React.useState<string>("");
    const [motherYearOfAttendanceA, setMotherYearOfAttendanceA] = React.useState<string>("");
    const [motherYearOfAttendanceB, setMotherYearOfAttendanceB] = React.useState<string>("");
    const [motherCampusPreparatorySchool, setMotherCampusPreparatorySchool] = React.useState<boolean>(false);
    const [motherCampusCollege, setMotherCampusCollege] = React.useState<boolean>(false);
    const [motherIsSSCAAMembership, setMotherIsSSCAAMembership] = React.useState<boolean>(false);
    const [motherSSCAAMembershipNumber, setMotherSSCAAMembershipNumber] = React.useState<string>("");
    const [messageApi, contextHolder] = message.useMessage();


    const steps = [
        {
            title: t("Documentation of Student")
        },
        {
            title: t("Student's Information")
        },
        {
            title: t("Parents' /Guardians' Information")
        },
        {
            title: t("Your Application")
        },
    ]
    useEffect(() => {
        let response = null;
        async function fetchData() {
            let gradeOptionArray: { value: string, label: string }[] = [];
            if (props.admin) {
                applicationService.getSetting(id || "").then(res => {
                    if (res.status == "OK") {
                        if (res.reference.applicationFor == "G1") {
                            gradeOptionArray.push({ value: "G1", label: "G1" })
                        } else if (res.reference.applicationFor == "G2") {
                            gradeOptionArray.push({ value: "G2", label: "G2" })
                        } else if (res.reference.applicationFor == "G3") {
                            gradeOptionArray.push({ value: "G3", label: "G3" })
                        } else if (res.reference.applicationFor == "G4") {
                            gradeOptionArray.push({ value: "G4", label: "G4" })
                        } else if (res.reference.applicationFor == "G5") {
                            gradeOptionArray.push({ value: "G5", label: "G5" })
                        } else if (res.reference.applicationFor == "G6") {
                            gradeOptionArray.push({ value: "G6", label: "G6" })
                        } else if (res.reference.applicationFor == "G26") {
                            gradeOptionArray.push({ value: "G2", label: "G2" })
                            gradeOptionArray.push({ value: "G3", label: "G3" })
                            gradeOptionArray.push({ value: "G4", label: "G4" })
                            gradeOptionArray.push({ value: "G5", label: "G5" })
                            gradeOptionArray.push({ value: "G6", label: "G6" })
                        }
                        setGradeOption(gradeOptionArray);
                    } else {
                        // TODO: Handling Error 
                    }
                })
            } else {
                let url = `/application/getGradeOptions/${id}/`
                response = await axios.get(url)
                    .then(res => {

                        if (res && res.data && res.data.status === 'OK' && res.data.settings) {
                            let getNow = res.data.settings.now;
                            let startDate = res.data.settings.startDate;
                            let endDate = res.data.settings.endDate;
                            let groupId = res.data.settings.groupId;
                            if (groupId != id) {
                                message.error(t("Permission Denied"))
                                navigate("/")
                            } else if (getNow < startDate) {
                                message.error(t("Application is not open yet"))
                                navigate("/")
                            } else if (getNow > endDate) {
                                message.error(t("Application is closed"))
                                navigate("/")
                            }
                        }
                        if (res && res.data && res.data.status === 'OK' && res.data.reference) {
                            let array = res.data.reference as { value: string, label: string }[];
                            for (const [key, value] of Object.entries(array)) {
                                let tmp: any = {};
                                tmp["value"] = value["value"] ? value["value"] : "";
                                tmp["label"] = value["label"] ? value["label"] : "";
                                gradeOptionArray.push(tmp);
                            }
                            setGradeOption(gradeOptionArray);
                        } else {
                            // TODO: Handling Error 
                        }
                    })
                    .catch(err => {
                        console.error("[view_dashboard]-[Applications]-[Application2Page]-[useEffect1]-[err]");
                        console.error(err);
                    });
            }

        }
        fetchData();
    }, []);

    const onFinishStep0 = (value: { bankReferenceNumber: string }) => {
        //setBankReferenceNumber(value.bankReferenceNumber)
        setCurrent(1);
    };
    const onFinishStep1 = (value: {
        surname: string,
        givenName: string,
        nameInChinese: string,
        gender: string,
        nationality: string,
        nationalityOther: string,
        dateOfBirth: dayjs.Dayjs,
        placeOfBirth: string,
        hkBirthCertificatePassport: string,
        homeAddressRegion: string,
        homeAddressDistrict: string,
        homeAddress: string,
        telephone: string,
        email: string,
        religion: string,
        gradeAppliedFor: string,
        motherTongueOfTheApplicant: string,
        motherTongueOther: string,
        presentSchool: string,
        yearsAttendedA: string,
        yearsAttendedB: string,
        noOfSiblings: string,
        applicantPositionAmongSiblings: string,
        applyWithSiblings: boolean,
        siblingNameOfAttend: string,
        siblingYearOfAttendA: string,
        siblingYearOfAttendB: string,
        siblingGrade: string,
        grade: string
    }) => {
        setSurname(value.surname);
        setGivenName(value.givenName);
        setNameInChinese(value.nameInChinese);
        setNationalityOther(value.nationalityOther);
        setDateOfBirth(value.dateOfBirth.format("YYYY-MM-DD"))
        setPlaceOfBirth(value.placeOfBirth);
        setHKBirthCertificatePassport(value.hkBirthCertificatePassport);
        setHomeAddress(value.homeAddress)
        setTelephone(value.telephone)
        setEmail(value.email)
        setNoOfSiblings(value.noOfSiblings)
        setApplicantPositionAmongSiblings(value.applicantPositionAmongSiblings)
        setApplyWithSiblings(value.applyWithSiblings)
        setHomeAddressDistrict(value.homeAddressDistrict)
        setHomeAddressRegion(value.homeAddressRegion)
        setReligion(value.religion)
        setGradeAppliedFor(value.gradeAppliedFor)
        setMotherTongueOther(value.motherTongueOther)
        setPresentSchool(value.presentSchool)
        setYearsAttendedA(value.yearsAttendedA)
        setYearsAttendedB(value.yearsAttendedB)
        setSiblingNameOfAttend(value.siblingNameOfAttend)
        setSiblingYearOfAttendA(value.siblingYearOfAttendA)
        setSiblingYearOfAttendB(value.siblingYearOfAttendB)
        setSiblingGrade(value.siblingGrade)
        setCurrent(2);
    };
    const onFinishStep2 = (value: {

        fatherEnglishName: string
        fatherChineseName: string
        fatherIDCardPassport: string
        fatherOccupation: string
        fatherTitle: string
        fatherTelephoneOffice: string
        fatherTelephoneMobile: string
        fatherOfficeAddress: string
        fatherYearOfAttendanceA: string
        fatherYearOfAttendanceB: string
        fatherCampusPreparatorySchool: boolean
        fatherCampusCollege: boolean
        fatherIsSSCAAMembership: boolean
        fatherSSCAAMembershipNumber: string

        motherEnglishName: string
        motherChineseName: string
        motherIDCardPassport: string
        motherOccupation: string
        motherTitle: string
        motherTelephoneOffice: string
        motherTelephoneMobile: string
        motherOfficeAddress: string
        motherYearOfAttendanceA: string
        motherYearOfAttendanceB: string
        motherCampusPreparatorySchool: boolean
        motherCampusCollege: boolean
        motherIsSSCAAMembership: boolean
        motherSSCAAMembershipNumber: string
    }) => {
        if (fatherInformation) {
            setFatherEnglishName(value.fatherEnglishName);
            setFatherChineseName(value.fatherChineseName);
            setFatherIDCardPassport(value.fatherIDCardPassport);
            setFatherOccupation(value.fatherOccupation);
            setFatherTitle(value.fatherTitle);
            setFatherTelephoneOffice(value.fatherTelephoneOffice)
            setFatherTelephoneMobile(value.fatherTelephoneMobile)
            setFatherOfficeAddress(value.fatherOfficeAddress)
            setFatherYearOfAttendanceA(value.fatherYearOfAttendanceA)
            setFatherYearOfAttendanceB(value.fatherYearOfAttendanceB)

            setFatherIsSSCAAMembership(value.fatherIsSSCAAMembership)
            setFatherSSCAAMembershipNumber(value.fatherSSCAAMembershipNumber)
        } else {
            setFatherEnglishName("");
            setFatherChineseName("");
            setFatherIDCardPassport("")
            setFatherOccupation("");
            setFatherTitle("");
            setFatherTelephoneOffice("");
            setFatherTelephoneMobile("");
            setFatherOfficeAddress("");
            setFatherYearOfAttendanceA("")
            setFatherYearOfAttendanceB("");
            setFatherCampusPreparatorySchool(false);
            setFatherCampusCollege(false);
            setFatherIsSSCAAMembership(false);
            setFatherSSCAAMembershipNumber("");
        }
        if (motherInformation) {
            setMotherEnglishName(value.motherEnglishName)
            setMotherChineseName(value.motherChineseName)
            setMotherIDCardPassport(value.motherIDCardPassport)
            setMotherOccupation(value.motherOccupation)
            setMotherTitle(value.motherTitle)
            setMotherTelephoneOffice(value.motherTelephoneOffice)
            setMotherTelephoneMobile(value.motherTelephoneMobile)
            setMotherOfficeAddress(value.motherOfficeAddress)
            setMotherYearOfAttendanceA(value.motherYearOfAttendanceA)
            setMotherYearOfAttendanceB(value.motherYearOfAttendanceB)
            setMotherIsSSCAAMembership(value.motherIsSSCAAMembership);
            setMotherSSCAAMembershipNumber(value.motherSSCAAMembershipNumber);

        } else {
            setMotherEnglishName("");
            setMotherChineseName("");
            setMotherIDCardPassport("")
            setMotherOccupation("");
            setMotherTitle("");
            setMotherTelephoneOffice("");
            setMotherTelephoneMobile("");
            setMotherOfficeAddress("");
            setMotherYearOfAttendanceA("")
            setMotherYearOfAttendanceB("");
            setMotherCampusPreparatorySchool(false);
            setMotherCampusCollege(false);
            setMotherIsSSCAAMembership(false);
            setMotherSSCAAMembershipNumber("");
        }
        if (fatherInformation || motherInformation) {
            setCurrent(3);
        }
    };
    const onFinishStep3 = () => {
        let form = {
            birthCertificate: birthCertificateFileType.startsWith("application") ? `data:${birthCertificateFileType};base64,${birthCertificate}` : `data:${birthCertificateFileType};base64,${birthCertificate}`,
            hkIDPassportCopy: hkIDPassportCopyFileType.startsWith("application") ? `data:${hkIDPassportCopyFileType};base64,${hkIDPassportCopy}` : `data:${hkIDPassportCopyFileType};base64,${hkIDPassportCopy}`,
            applicantPhoto: `data:${applicantPhotoFileType};base64,${applicantPhoto}`,
            bankReceiptCopy: bankReceiptCopyFileType.startsWith("application") ? `data:${bankReceiptCopyFileType};base64,${bankReceiptCopy}` : `data:${bankReceiptCopyFileType};base64,${bankReceiptCopy}`,
            bankReferenceNumber,
            dateOfApplication: today.toISOString(),
            surname,
            givenName,
            nameInChinese,
            gender,
            nationality,
            nationalityOther,
            dateOfBirth,
            placeOfBirth,
            hkBirthCertificatePassport,
            homeAddressRegion,
            homeAddressDistrict,
            homeAddress,
            telephone,
            email,
            religion,
            gradeAppliedFor,
            motherTongueOfTheApplicant,
            motherTongueOther,
            presentSchool,
            yearsAttendedA,
            yearsAttendedB,
            noOfSiblings,
            applicantPositionAmongSiblings,
            applyWithSiblings,
            siblingNameOfAttend,
            siblingYearOfAttendA,
            siblingYearOfAttendB,
            siblingCampusPreparatory,
            siblingCampusCollege,
            siblingGrade,
            fatherInformation,
            fatherEnglishName,
            fatherChineseName,
            fatherIDCardPassport,
            fatherOccupation,
            fatherTitle,
            fatherTelephoneOffice,
            fatherTelephoneMobile,
            fatherOfficeAddress,
            fatherYearOfAttendanceA,
            fatherYearOfAttendanceB,
            fatherCampusPreparatorySchool,
            fatherCampusCollege,
            fatherIsSSCAAMembership,
            fatherSSCAAMembershipNumber,
            motherInformation,
            motherEnglishName,
            motherChineseName,
            motherIDCardPassport,
            motherOccupation,
            motherTitle,
            motherTelephoneOffice,
            motherTelephoneMobile,
            motherOfficeAddress,
            motherYearOfAttendanceA,
            motherYearOfAttendanceB,
            motherCampusPreparatorySchool,
            motherCampusCollege,
            motherIsSSCAAMembership,
            motherSSCAAMembershipNumber
        }

        if (props.admin) {
            Modal.confirm({
                title: t('Confirm Submission'),
                content: (
                    <div>
                        <p>{t("Are you sure you want to submit your application?")}</p>
                        <p>{t("Once confirmed, you are not allowed to modify the information.")}</p>
                    </div>
                ),
                okText: t('Confirm'),
                cancelText: t('Cancel'),
                onOk() {
                    setIsLoading(true)
                    applicationService.adminApplyApplication(id || "", form).then((res) => {
                        setIsLoading(false)
                        if (res.status == "OK" && res.reference) {
                            Swal.fire({
                                icon: 'success',
                                title: '',
                                text: t("Application ID")+": "+res.reference,
                            })
                            navigate(`/dashboard/applicationYears/${id}`)
                        } else if  (res.status =="Error") {
                            let resErrors = {}; let resErrorsArray = [];

                            if (res.msg && res.msg.msg) {
                                resErrors = res.msg.msg;
                            } else {
                                let e = "Something goes wrong when submitting new applications";
                                resErrorsArray.push(e);
                            }
                            for (const [key, value] of Object.entries(resErrors)) {
                                let e = `${value}`
                                resErrorsArray.push(e);
                            }
                            Swal.fire({
                                icon: 'error',
                                title: '',
                                text: resErrorsArray.join("\n"),
                            })
                        }
                    })
                },
                onCancel() {
                },
            });
        } else {

            Modal.confirm({
                title: t('Confirm Submission'),
                content: (
                    <div>
                        <p>{t("Are you sure you want to submit your application?")}</p>
                        <p>{t("Once confirmed, you are not allowed to modify the information.")}</p>
                    </div>
                ),
                okText: t('Confirm'),
                cancelText: t('Cancel'),
                onOk() {
                    setIsLoading(true)
                    applicationService.applyApplication(id || "", form).then((res) => {
                        setIsLoading(false)
                        if (res.status == "OK" && res.reference) {
                            navigate(`/${id}/success/${res.reference}`)
                        } else if  (res.status =="Error") {
                            let resErrors = {}; let resErrorsArray = [];

                            if (res.msg && res.msg.msg) {
                                resErrors = res.msg.msg;
                            } else {
                                let e = "Something goes wrong when submitting new applications";
                                resErrorsArray.push(e);
                            }

                            for (const [key, value] of Object.entries(resErrors)) {
                                let e = `${value}`
                                resErrorsArray.push(e);
                            }
                            Swal.fire({
                                icon: 'error',
                                title: '',
                                text: resErrorsArray.join("\n"),
                            })
                        }
                    })
                },
                onCancel() {
                },
            });

        }
    };


    return (
        <>
            <Layout style={{ minHeight: "100vh" }}>
                {!(props.admin) && <MyHeader />}
                <Content style={{ padding: '0 50px' }}>
                    <Layout style={{ padding: '24px 0', maxWidth: 1000, margin: "0 auto" }}>
                        <Steps current={current} items={steps} style={{ marginTop: 30, marginBottom: 20 }} />
                        {current == 0 && <ProCard>
                            <Form form={step0Form} name="documentation" layout="vertical" onFinish={onFinishStep0}
                                initialValues={{ bankReferenceNumber, birthCertificate, hkIDPassportCopy, applicantPhoto, bankReceiptCopy }}

                                onFinishFailed={({ values, errorFields, outOfDate }) => { console.error(errorFields) }} >
                                <ol>
                                    <li style={{ marginBottom: "1em", borderBottom: "1px solid #eee", paddingBottom: "1em" }}>
                                        <Form.Item
                                            name="hkIDPassportCopy"
                                            label={t("HKID/Passport Copy")}
                                            valuePropName="fileList"
                                            rules={[
                                                { required: true, message: t("Please upload HKID/Passport Copy") },
                                            ]}
                                        >
                                            <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", flexWrap: "wrap" }}>
                                                <div style={{ flex: 1 }}>
                                                    <Title level={5}>{t("HKID/Passport Copy")}</Title>
                                                    <p>{t("For applicants born overseas, please submit passport or travel document with the page containing 'Permitted date' chop information.")}</p>
                                                    {(hkIDPassportCopy) && hkIDPassportCopyFileType.startsWith("application") && <iframe
                                                        id="hkIDPassportCopyFrame"
                                                        src={`data:${hkIDPassportCopyFileType};base64,${hkIDPassportCopy}`}
                                                        style={{ width: "100%", height: 500 }}
                                                        width="" height=""></iframe>}
                                                    {(hkIDPassportCopy) && hkIDPassportCopyFileType.startsWith("image") && <img style={{ width: "100%", maxWidth: 500 }} src={`data:${hkIDPassportCopyFileType};base64,${hkIDPassportCopy}`} />}
                                                </div>
                                                <div style={{ display: "flex", flexDirection: "row", alignItems: "center", textAlign: "center", justifyContent: "center" }}>
                                                    <Upload fileList={[]} maxCount={1} multiple={false} accept=".png, .jpg, .jpeg, .pdf" beforeUpload={(file, fileList) => {
                                                        if (file) {
                                                            if (file.type == "application/pdf" || file.type == "image/png" || file.type == "image/jpeg") {
                                                                file.arrayBuffer().then((buffer) => {

                                                                    let bytes = btoa(new Uint8Array(buffer).reduce((data, byte) => {
                                                                        return data + String.fromCharCode(byte);
                                                                    }, ''));
                                                                    //let base64 = window.btoa( bytes )
                                                                    setHKIDPassportCopy(bytes);
                                                                    setHKIDPassportCopyFileType(file.type)
                                                                })
                                                            }
                                                        }
                                                        return false;
                                                    }}>
                                                        <Button shape="round" size="large" >{t("Upload")}</Button>
                                                    </Upload>
                                                </div>
                                            </div>
                                        </Form.Item>

                                    </li>
                                    <li style={{ marginBottom: "1em", borderBottom: "1px solid #eee", paddingBottom: "1em" }}>
                                        <Form.Item
                                            name="birthCertificate"
                                            label={t("Birth Certificate")}
                                            valuePropName="fileList"
                                            rules={[
                                                { required: true, message: t("Please upload Birth Certificate") },
                                            ]}
                                        >
                                            <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", flexWrap: "wrap" }}>
                                                <div style={{ flex: 1 }}>
                                                    <Title level={5}>{t("Birth Certificate")}</Title>
                                                    {(birthCertificate) && birthCertificateFileType.startsWith("application") && <iframe
                                                        id="birthCertificateFrame"
                                                        src={`data:${birthCertificateFileType};base64,${birthCertificate}`}
                                                        style={{ width: "100%", height: 500 }}
                                                        width="" height=""></iframe>}
                                                    {(birthCertificate) && birthCertificateFileType.startsWith("image") && <img style={{ width: "100%", maxWidth: 500 }} src={`data:${birthCertificateFileType};base64,${birthCertificate}`} />}
                                                </div>
                                                <div style={{ display: "flex", flexDirection: "row", alignItems: "center", textAlign: "center", justifyContent: "center" }}>
                                                    <Upload fileList={[]} maxCount={1} multiple={false} accept=".png, .jpg, .jpeg, .pdf" beforeUpload={(file, fileList) => {
                                                        if (file) {
                                                            if (file.type == "application/pdf" || file.type == "image/png" || file.type == "image/jpeg") {
                                                                file.arrayBuffer().then((buffer) => {
                                                                    let bytes = btoa(new Uint8Array(buffer).reduce((data, byte) => {
                                                                        return data + String.fromCharCode(byte);
                                                                    }, ''));
                                                                    //let base64 = window.btoa( bytes )
                                                                    setBirthCerticate(bytes);
                                                                    setBirthCerticateFileType(file.type)
                                                                })

                                                            }
                                                        }
                                                        return false;
                                                    }}>
                                                        <Button shape="round" size="large" >{t("Upload")}</Button>
                                                    </Upload>
                                                </div>
                                            </div>
                                        </Form.Item>
                                    </li>
                                    <li style={{ marginBottom: "1em", borderBottom: "1px solid #eee", paddingBottom: "1em" }}>
                                        <Form.Item
                                            name="applicantPhoto"
                                            label={t("Applicant's Photo")}
                                            valuePropName="fileList"
                                            rules={[
                                                { required: true, message: t("Please upload Applicant's Photo") },
                                            ]}
                                        >
                                            <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", flexWrap: "wrap" }}>
                                                <div style={{ flex: 1 }}>
                                                    <Title level={5}>{t("Applicant's Photo")}</Title>
                                                    <p>{t("Photo Requirements")}</p>
                                                    <div>
                                                        <ul>
                                                            <li>{t("Color")}</li>
                                                            <li>{t("Photo Format: JPEG")}</li>
                                                            <li>{t("Photo Size: 40mm (width) * 50 mm (height)")}</li>
                                                            <li>{t("The photograh should show the full frontal face of the student with clear facial features.")}</li>
                                                        </ul>
                                                        <img style={{ maxWidth: 300, width: "100%", padding: "20px 0" }} src={photoRequirement} />

                                                    </div>
                                                    {(applicantPhoto) && <img
                                                        id="applicantPhotoFrame"
                                                        src={`data:${applicantPhotoFileType};base64,${applicantPhoto}`}
                                                        style={{ width: "100%", maxWidth: 500 }} />}

                                                </div>
                                                <div style={{ display: "flex", flexDirection: "row", alignItems: "center", textAlign: "center", justifyContent: "center" }}>
                                                    <Upload fileList={[]} maxCount={1} multiple={false} accept=".png, .jpg, .jpeg" beforeUpload={(file, fileList) => {
                                                        if (file) {
                                                            if (file.type == "image/png" || file.type == "image/jpeg") {
                                                                file.arrayBuffer().then((buffer) => {
                                                                    let bytes = btoa(new Uint8Array(buffer).reduce((data, byte) => {
                                                                        return data + String.fromCharCode(byte);
                                                                    }, ''));
                                                                    setApplicantPhoto(bytes);
                                                                    setApplicantPhotoFileType(file.type)
                                                                    messageApi.open({
                                                                        type: 'loading',
                                                                        content: t('Verify Face in progress..'),
                                                                        duration: 0,
                                                                    });
                                                                    // Dismiss manually and asynchronously

                                                                    applicationService.verifyFace(bytes).then(d => {
                                                                        if (d.status == "OK") {
                                                                            if (d.result.status == true) {
                                                                                messageApi.destroy();
                                                                                message.success(t("Applicant's Photo is valid"))
                                                                                setIsPhotoValid(true)
                                                                            } else {
                                                                                messageApi.destroy();
                                                                                message.error(d.result.msg)
                                                                                setIsPhotoValid(false)
                                                                            }
                                                                        }

                                                                        //setIsPhotoValid(d)
                                                                    })
                                                                })
                                                            }
                                                        }
                                                        return false;
                                                    }}>
                                                        <Button shape="round" size="large" >{t("Upload")}</Button>
                                                    </Upload>
                                                </div>
                                            </div>
                                        </Form.Item>
                                    </li>
                                    <li style={{ marginBottom: "1em", borderBottom: "1px solid #eee", paddingBottom: "1em" }}>
                                        <Form.Item
                                            name="bankReceiptCopy"
                                            label={t("Bank Receipt Copy")}
                                            valuePropName="fileList"
                                            rules={[
                                                { required: true, message: t("Please upload Bank Receipt Copy") },
                                            ]}
                                        >
                                            <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", flexWrap: "wrap" }}>
                                                <div style={{ flex: 1 }}>
                                                    <Title level={5}>{t("Bank Receipt Copy")}</Title>
                                                    <p>{t("Application Fee")}: $200</p>
                                                    <p>{t("Please deposit the application fee into the following bank account")}:</p>
                                                    <p>{t("HSBC Account")}: 002-245678-004</p>
                                                    <p>{t("Account Name")}: ST. STEPHEN'S COLLEGE</p>
                                                    <p>{t("Remarks: Acceptable payment methods")}</p>
                                                    <ol>
                                                        <li>{t("ATM Transfer")}</li>
                                                        <li>{t("Bank Deposit")}</li>
                                                        <li>{t("Online-transfer")}</li>
                                                    </ol>
                                                    {(bankReceiptCopy) && bankReceiptCopyFileType.startsWith("application") && <iframe
                                                        id="bankReceiptCopyFrame"
                                                        src={`data:${bankReceiptCopyFileType};base64,${bankReceiptCopy}`}
                                                        style={{ width: "100%", height: 500 }}
                                                        width="" height=""></iframe>}
                                                    {(bankReceiptCopy) && bankReceiptCopyFileType.startsWith("image") && <img style={{ width: "100%", maxWidth: 500 }} src={`data:${bankReceiptCopyFileType};base64,${bankReceiptCopy}`} />}
                                                    <p style={{ color: "red" }}>* {t("Application fee is irredeemable.")}</p>
                                                </div>
                                                <div style={{ display: "flex", flexDirection: "row", alignItems: "center", textAlign: "center", justifyContent: "center" }}>
                                                    <Upload fileList={[]} maxCount={1} multiple={false} accept=".png, .jpg, .jpeg, .pdf" beforeUpload={(file, fileList) => {
                                                        if (file) {
                                                            if (file.type == "application/pdf" || file.type == "image/png" || file.type == "image/jpeg") {
                                                                file.arrayBuffer().then((buffer) => {
                                                                    let bytes = btoa(new Uint8Array(buffer).reduce((data, byte) => {
                                                                        return data + String.fromCharCode(byte);
                                                                    }, ''));
                                                                    setBankReceiptCopy(bytes);
                                                                    setBankReceiptCopyFileType(file.type)
                                                                    setTimeout(() => {

                                                                    }, 1000)
                                                                })
                                                            }
                                                        }
                                                        return false;
                                                    }}>
                                                        <Button shape="round" size="large" >{t("Upload")}</Button>
                                                    </Upload>
                                                </div>
                                            </div>
                                        </Form.Item>
                                    </li>
                                </ol>
                                <Row gutter={16}>
                                    {/*<Col className="gutter-row" span={12}>
                                        <Form.Item label={t("Bank Reference Number")} name="bankReferenceNumber" rules={[
                                            { required: true, message: t("Please enter Bank Reference Number") },
                                        ]}>
                                            <Input />
                                        </Form.Item>
                                    </Col>*/}
                                    <Col className="gutter-row" span={24}>
                                        <Form.Item label={t("Date of Application")}>
                                            <Input readOnly={true} value={dateOfApplication} />
                                        </Form.Item>
                                    </Col>
                                </Row>

                                <div style={{ textAlign: "right" }}>
                                    <Button type="primary" size='large' htmlType="submit">
                                        {t("Next")}
                                    </Button>
                                </div>
                            </Form>
                        </ProCard>}
                        {current == 1 && <ProCard>
                            <Form form={step1Form} name="studentInformation" layout="vertical" onFinish={onFinishStep1} initialValues={{
                                surname,
                                givenName,
                                nameInChinese,
                                gender,
                                nationality,
                                nationalityOther,
                                placeOfBirth,
                                hkBirthCertificatePassport,
                                homeAddressRegion,
                                homeAddressDistrict,
                                homeAddress,
                                telephone,
                                email,
                                religion,
                                gradeAppliedFor,
                                motherTongueOfTheApplicant,
                                motherTongueOther,
                                presentSchool,
                                yearsAttendedA,
                                yearsAttendedB,
                                noOfSiblings,
                                applicantPositionAmongSiblings,
                                applyWithSiblings,
                                siblingNameOfAttend,
                                siblingYearOfAttendA,
                                siblingYearOfAttendB,
                                siblingGrade
                            }}>
                                <Row gutter={16}>
                                    <Col className="gutter-row" md={8} sm={24} xs={24}>
                                        <Form.Item name={"surname"} label={t("Surname (English)")} rules={[
                                            { required: true, message: t("Please enter Surname (English)") },
                                            { pattern: /^[a-zA-Z ]+$/, message: t("Please enter Surname (English)") }
                                        ]}>
                                            <Input />
                                        </Form.Item>
                                    </Col>
                                    <Col className="gutter-row" md={8} sm={24} xs={24}>
                                        <Form.Item name={"givenName"} label={t("Given Name (English)")} rules={[
                                            { required: true, message: t("Please enter Given Name (English)") },
                                            { pattern: /^[a-zA-Z ]+$/, message: t("Please enter Given Name (English)") }
                                        ]}>
                                            <Input />
                                        </Form.Item>
                                    </Col>
                                    <Col className="gutter-row" md={8} sm={24} xs={24}>
                                        <Form.Item name={"nameInChinese"} label={t("Name in Chinese")} rules={[
                                            { pattern: /^[\u4E00-\u9FFF]{0,}$/, message: t("Please enter Name in Chinese") }
                                        ]}>
                                            <Input />
                                        </Form.Item>
                                    </Col>
                                    <Col className="gutter-row" span={24}>
                                        <Form.Item name="gender" label={t("Gender")} rules={[{ required: true, message: t("Please enter Gender") }]}>
                                            <Select
                                                onChange={(o) => (setGender(o))}
                                                allowClear>
                                                {genderOption.map(option => (
                                                    <Option key={`geneder.${option.value}`} value={option.value}>{t(option.label)}</Option>
                                                ))}
                                            </Select>
                                        </Form.Item>
                                    </Col>
                                    <Col className="gutter-row" span={24}>
                                        <Form.Item name="nationality" label={t("Nationality")} rules={[{ required: true, message: t("Please enter Nationality") }]}>
                                            <Select
                                                onChange={(o) => (setNationality(o))}
                                                allowClear>
                                                {nationalityOption.map(option => (
                                                    <Option key={`nationalityOption.${option.value}`} value={option.value}>{t(option.label)}</Option>
                                                ))}
                                            </Select>
                                        </Form.Item>
                                    </Col>
                                    {nationality === 'Other 其他' && (
                                        <Col className="gutter-row" span={24}>
                                            <Form.Item name="nationalityOther" label={t("Other Nationality")} rules={[{ required: true, message: t("Please enter Other Nationality") }]}>
                                                <Input />
                                            </Form.Item>
                                        </Col>
                                    )}
                                    <Col className="gutter-row" span={24}>
                                        <Form.Item initialValue={dayjs(dateOfBirth == "" ? new Date() : dateOfBirth)} name="dateOfBirth" label={t("Date of Birth")} rules={[{ required: true, message: t("Please enter Date of Birth") }]}>
                                            <DatePicker disabledDate={within20Years} style={{ width: "100%" }} placeholder='' />
                                        </Form.Item>
                                    </Col>
                                    <Col className="gutter-row" span={24}>
                                        <Form.Item name={"placeOfBirth"} label={t("Place of Birth")} rules={[{ required: true, message: t("Please enter Place of Birth") }]}>
                                            <Input />
                                        </Form.Item>
                                    </Col>
                                    <Col className="gutter-row" span={24}>
                                        <Form.Item name={"hkBirthCertificatePassport"} label={t("HK Birth Certificate/Passport No.")} rules={[{ required: true, message: t("Please enter HK Birth Certificate/Passport No.") }]}>
                                            <Input />
                                        </Form.Item>
                                    </Col>
                                    <Col className="gutter-row" span={12} >
                                        <Form.Item name={"homeAddressRegion"} label={t("Home Address Region")} rules={[{ required: true, message: t("Please enter Home Address Region") }]}>
                                            <Select
                                                onChange={o => {
                                                    setHomeAddressRegion(o);
                                                    step1Form.resetFields(["homeAddressDistrict"]);
                                                }}
                                                allowClear>
                                                {homeAddressRegionOptions.map(homeAddressRegion => (
                                                    <Option key={`homeAddressRegion.${homeAddressRegion.value}`} value={homeAddressRegion.value}>{t(homeAddressRegion.label)}</Option>
                                                ))}
                                            </Select>

                                        </Form.Item>
                                    </Col>
                                    <Col className="gutter-row" span={12} >
                                        <Form.Item name={"homeAddressDistrict"} label={t("Home Address District")} rules={[{ required: true, message: t("Please enter Home Address District") }]}>
                                            <Select
                                                allowClear>
                                                {homeAddressRegion == "Hong Kong 香港" && hongkongDistrictOptions.map(homeAddressRegion => (
                                                    <Option key={`homeAddressDistrict.${homeAddressRegion.value}`} value={homeAddressRegion.value}>{t(homeAddressRegion.label)}</Option>
                                                ))}
                                                {homeAddressRegion == "Kowloon 九龍" && kowloonDistrictOptions.map(homeAddressRegion => (
                                                    <Option key={`homeAddressDistrict.${homeAddressRegion.value}`} value={homeAddressRegion.value}>{t(homeAddressRegion.label)}</Option>
                                                ))}
                                                {homeAddressRegion == "New Territories 新界" && newterritoriesOptions.map(homeAddressRegion => (
                                                    <Option key={`homeAddressDistrict.${homeAddressRegion.value}`} value={homeAddressRegion.value}>{t(homeAddressRegion.label)}</Option>
                                                ))}
                                            </Select>
                                        </Form.Item>
                                    </Col>
                                    <Col className="gutter-row" span={24} >
                                        <Form.Item name={"homeAddress"} label={t("Home Address")} rules={[{ required: true, message: t("Please enter Home Address") }]}>
                                            <Input />
                                        </Form.Item>
                                    </Col>
                                    <Col className="gutter-row" span={12} >
                                        <Form.Item name={"telephone"} label={t("Telephone")} rules={[{ required: true, message: t("Please enter Telephone") }, { pattern: /[0-9 () \- + ]$/, message: t("Please enter Telephone") }]}>
                                            <Input type='tel' />
                                        </Form.Item>
                                    </Col>
                                    <Col className="gutter-row" span={12} >
                                        <Form.Item name={"email"} label={t("E-mail")} rules={[{ required: true, message: t("Please enter E-mail") }, { pattern: /^[a-zA-Z0-9.!#$%&'*+\/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/, message: t("Please enter E-mail") }]}>
                                            <Input type='email' />
                                        </Form.Item>
                                    </Col>
                                    <Col className="gutter-row" span={24} >
                                        <Form.Item name={"religion"} label={t("Religion")} rules={[{ required: true, message: t("Please enter Religion") }]}>
                                            <Select
                                                allowClear>
                                                {religionOption.map((option) => (
                                                    <Option key={`religionOption.${option.value}`} value={option.value}>{t(option.label)}</Option>
                                                ))}
                                            </Select>
                                        </Form.Item>
                                    </Col>
                                    <Col className="gutter-row" span={24} >
                                        <Form.Item name={"gradeAppliedFor"} label={t("Grade Applied For")} rules={[{ required: true, message: t("Please enter Grade Applied For") }]}>
                                            <Select
                                                allowClear>
                                                {gradeOption.map((option) => (
                                                    <Option key={`gradeOption.${option.value}`} value={option.value}>{t(option.label)}</Option>
                                                ))}
                                            </Select>
                                        </Form.Item>
                                    </Col>
                                    <Col className="gutter-row" span={24} >
                                        <Form.Item name={"motherTongueOfTheApplicant"} label={t("Mother Tongue of the Applicant")} rules={[{ required: true, message: t("Please enter Mother Tongue of the Applicant") }]}>
                                            <Select
                                                value={motherTongueOfTheApplicant}
                                                onChange={o => {
                                                    setMotherTongueOfTheApplicant(o);
                                                }}
                                                allowClear>
                                                {motherTongueOfTheApplicantOption.map((option) => (
                                                    <Option key={`motherTongueOfTheApplicantOption.${option.value}`} value={option.value}>{t(option.label)}</Option>
                                                ))}
                                            </Select>
                                        </Form.Item>
                                    </Col>
                                    {motherTongueOfTheApplicant === 'Other 其他' && (
                                        <Col className="gutter-row" span={24}>
                                            <Form.Item name="motherTongueOther" label={t("Other Mother Tongue")} rules={[{ required: true, message: t("Please enter Other Mother Tongue") }]}>
                                                <Input />
                                            </Form.Item>
                                        </Col>
                                    )}
                                    <Col className="gutter-row" span={24}>
                                        <Form.Item name="presentSchool" label={t("Present School")} rules={[{ required: true, message: t("Please enter Present School") }]}>
                                            <Input />
                                        </Form.Item>
                                    </Col>
                                    <Col className="gutter-row" span={12} >
                                        <Form.Item name={"yearsAttendedA"} label={t("Years Attended From")} rules={[
                                            { required: true, message: t("Please enter Years Attended From") },
                                            ({ getFieldValue }) => ({
                                                validator(rule, value) {
                                                    if (parseInt(value) > parseInt(getFieldValue("yearsAttendedB"))) {
                                                        return Promise.reject(t("Please enter Years Attended From"));
                                                    } else {
                                                        return Promise.resolve();
                                                    }
                                                }
                                            })
                                        ]}>
                                            <Select
                                                value={yearsAttendedA}
                                                onChange={o => {
                                                    setYearsAttendedA(o);
                                                }}
                                                allowClear>
                                                {yearOptions.map((option) => (
                                                    <Option key={`yearOptionsA.${option.value}`} value={option.value}>{t(option.label)}</Option>
                                                ))}
                                            </Select>
                                        </Form.Item>
                                    </Col>
                                    <Col className="gutter-row" span={12} >
                                        <Form.Item name={"yearsAttendedB"} label={t("Years Attended To")} rules={[
                                            { required: true, message: t("Please enter Years Attended To") },
                                            ({ getFieldValue }) => ({
                                                validator(rule, value) {
                                                    if (parseInt(value) < parseInt(getFieldValue("yearsAttendedA"))) {
                                                        return Promise.reject(t("Please enter Years Attended To"));
                                                    } else {
                                                        return Promise.resolve();
                                                    }
                                                }
                                            })
                                        ]}>
                                            <Select
                                                value={yearsAttendedB}
                                                onChange={o => {
                                                    setYearsAttendedB(o);
                                                }}
                                                allowClear>
                                                {yearOptions.map((option) => (
                                                    <Option key={`yearOptionsB.${option.value}`} value={option.value}>{t(option.label)}</Option>
                                                ))}
                                            </Select>
                                        </Form.Item>
                                    </Col>
                                    <Col className="gutter-row" span={12} >
                                        <Form.Item name={"noOfSiblings"} label={t("Number of Siblings (Including the Applicant)")} rules={[
                                            { required: true, message: t("Please enter Number of Siblings") },
                                            { type: 'integer', transform: v => parseInt(v), message: t("Please enter an integer") },
                                            ({ getFieldValue }) => ({
                                                validator(rule, value) {
                                                    if (parseInt(value) < 1) {
                                                        return Promise.reject(t("The minimun number is") + " " + 1);
                                                    } else {
                                                        return Promise.resolve();
                                                    }
                                                }
                                            })
                                        ]}>
                                            <Input />
                                        </Form.Item>
                                    </Col>
                                    <Col className="gutter-row" span={12} >
                                        <Form.Item name={"applicantPositionAmongSiblings"} label={t("Applicant's position among Siblings")} rules={[
                                            { required: true, message: t("Please enter Applicant's position among Siblings") },
                                            { type: 'integer', transform: v => parseInt(v), message: t("Please enter an integer") },
                                            ({ getFieldValue }) => ({
                                                validator(rule, value) {
                                                    if (parseInt(value) > parseInt(getFieldValue("noOfSiblings"))) {
                                                        return Promise.reject(t("The maximun number is") + " " + (parseInt(getFieldValue("noOfSiblings") || 0)));
                                                    } else {
                                                        return Promise.resolve();
                                                    }
                                                }
                                            }),
                                            ({ getFieldValue }) => ({
                                                validator(rule, value) {
                                                    if (parseInt(value) < 1) {
                                                        return Promise.reject(t("The minimun number is") + " " + 1);
                                                    } else {
                                                        return Promise.resolve();
                                                    }
                                                }
                                            })
                                        ]}>
                                            <Input />
                                        </Form.Item>
                                    </Col>
                                    <Col className="gutter-row" span={24} >
                                        <Form.Item name={"applyWithSiblings"} label={t("Apply with Siblings")} rules={[{ required: true, message: t("Please enter Apply with Siblings") }]}>
                                            <Select
                                                value={applyWithSiblings}
                                                onChange={o => {
                                                    setApplyWithSiblings(o);
                                                }}
                                                allowClear>
                                                {applyWithSiblingsOption.map((option) => (
                                                    <Option key={`applyWithSiblingsOption.${option.value}`} value={option.value}>{t(option.label)}</Option>
                                                ))}
                                            </Select>
                                        </Form.Item>
                                    </Col>
                                    <Col className="gutter-row" span={24} >
                                        <Form.Item name={"siblingNameOfAttend"} label={t("Sibling's Name of Attend (Optional)")}>
                                            <Input />
                                        </Form.Item>
                                    </Col>
                                    <Col className="gutter-row" span={12} >
                                        <Form.Item name={"siblingYearOfAttendA"}
                                            label={t("SiblingYears Attended From (Optional)")} rules={[
                                                ({ getFieldValue }) => ({
                                                    validator(rule, value) {
                                                        if (parseInt(value) > parseInt(getFieldValue("siblingYearOfAttendB"))) {
                                                            return Promise.reject(t("Please enter SiblingYears Attended From"));
                                                        } else {
                                                            return Promise.resolve();
                                                        }
                                                    }
                                                })
                                            ]}>
                                            <Select
                                                value={siblingYearOfAttendA}
                                                onChange={o => {
                                                    setSiblingYearOfAttendA(o);
                                                }}
                                                allowClear>
                                                {siblingYearOption.map((option) => (
                                                    <Option key={`yearOptionsA.${option.value}`} value={option.value}>{t(option.label)}</Option>
                                                ))}
                                            </Select>
                                        </Form.Item>
                                    </Col>
                                    <Col className="gutter-row" span={12} >
                                        <Form.Item name={"siblingYearOfAttendB"} label={t("Sibling Years Attended To (Optional)")} rules={[
                                            ({ getFieldValue }) => ({
                                                validator(rule, value) {
                                                    if (parseInt(value) < parseInt(getFieldValue("siblingYearOfAttendA"))) {
                                                        return Promise.reject(t("Please enter Sibling Years Attended To"));
                                                    } else {
                                                        return Promise.resolve();
                                                    }
                                                }
                                            })
                                        ]}>
                                            <Select
                                                value={siblingYearOfAttendB}
                                                onChange={o => {
                                                    setSiblingYearOfAttendB(o);
                                                }}
                                                allowClear>
                                                {siblingYearOption.map((option) => (
                                                    <Option key={`yearOptionsB.${option.value}`} value={option.value}>{t(option.label)}</Option>
                                                ))}
                                            </Select>
                                        </Form.Item>
                                    </Col>
                                    <Col className='gutter-row' span={24}>
                                        <Form.Item label={t("Siblings' Campus (Optional)")}>
                                            <Row>
                                                <Col span={8}>
                                                    <Checkbox name='siblingCampusPreparatory' onClick={() => {
                                                        setSiblingCampusPreparatory(!siblingCampusPreparatory);
                                                    }} checked={siblingCampusPreparatory}>
                                                        {t("Preparatory School")}
                                                    </Checkbox>
                                                </Col>
                                                <Col span={8}>
                                                    <Checkbox name='siblingCampusCollege' onClick={() => {
                                                        setSiblingCampusCollege(!siblingCampusCollege);
                                                    }} checked={siblingCampusCollege} >
                                                        {t("College")}
                                                    </Checkbox>
                                                </Col>
                                            </Row>
                                        </Form.Item>
                                    </Col>
                                    <Col className='gutter-row' span={24}>
                                        <Form.Item name={"siblingGrade"} label={t("Siblings' Grade (Optional)")}>
                                            <Select
                                                allowClear>
                                                {siblingCampusPreparatory && (
                                                    siblingPreparatoryGradeOption.map((option) => (
                                                        <Option key={`siblingPreparatoryGradeOption.${option.value}`} value={option.value}>{t(option.label)}</Option>
                                                    ))
                                                )}
                                                {siblingCampusCollege && (
                                                    siblingCollegeGradeOption.map((option) => (
                                                        <Option key={`siblingCollegeGradeOption.${option.value}`} value={option.value}>{t(option.label)}</Option>
                                                    ))
                                                )}
                                            </Select>
                                        </Form.Item>
                                    </Col>
                                </Row>
                                <div style={{ display: "flex", justifyContent: "space-between" }}>
                                    <Button type="default" size='large' onClick={o => { setCurrent(0) }}>
                                        {t("Back")}
                                    </Button>
                                    <Button type="primary" size='large' htmlType="submit">
                                        {t("Next")}
                                    </Button>
                                </div>
                            </Form>
                        </ProCard>}
                        {current == 2 && <ProCard>
                            <Form form={step2Form} name="parentInformation" layout="vertical"
                                initialValues={{
                                    fatherInformation,
                                    fatherEnglishName,
                                    fatherChineseName,
                                    fatherIDCardPassport,
                                    fatherOccupation,
                                    fatherTitle,
                                    fatherTelephoneOffice,
                                    fatherTelephoneMobile,
                                    fatherOfficeAddress,
                                    fatherYearOfAttendanceA,
                                    fatherYearOfAttendanceB,
                                    fatherCampusPreparatorySchool,
                                    fatherCampusCollege,
                                    fatherIsSSCAAMembership,
                                    fatherSSCAAMembershipNumber,
                                    motherInformation,
                                    motherEnglishName,
                                    motherChineseName,
                                    motherIDCardPassport,
                                    motherOccupation,
                                    motherTitle,
                                    motherTelephoneOffice,
                                    motherTelephoneMobile,
                                    motherOfficeAddress,
                                    motherYearOfAttendanceA,
                                    motherYearOfAttendanceB,
                                    motherCampusPreparatorySchool,
                                    motherCampusCollege,
                                    motherIsSSCAAMembership,
                                    motherSSCAAMembershipNumber
                                }}

                                onFinish={onFinishStep2}>
                                {!fatherInformation && !motherInformation && <p style={{ color: "red" }}><b>{t("Please provide at least one parent Information")}</b></p>}
                                <ProCard
                                    title={<Title level={5} type={!fatherInformation && !motherInformation ? "danger" : "secondary"}>{t("Father's Information")}</Title>}

                                    collapsible
                                    collapsibleIconRender={({
                                        collapsed: buildInCollapsed,
                                    }: {
                                        collapsed: boolean;
                                    }) => (buildInCollapsed ? <span></span> : <span></span>)}
                                    collapsed={!fatherInformation}
                                    extra={
                                        <FormItem>
                                            <Switch
                                                checkedChildren={t("Enable")} unCheckedChildren={t("N/A")}
                                                checked={fatherInformation}
                                                onChange={o => {
                                                    setFatherInformation(o);
                                                }}
                                            />
                                        </FormItem>
                                    }
                                >
                                    {fatherInformation && <Row gutter={16}>
                                        <Col className="gutter-row" md={12} sm={24} xs={24}>
                                            <Form.Item name={"fatherEnglishName"} label={t("Father's Name (English)")} rules={[
                                                { required: true, message: t("Please enter Father's Name (English)") },
                                                { pattern: /^[a-zA-Z ]+$/, message: t("Please enter Father's Name (English)") }
                                            ]}>
                                                <Input />
                                            </Form.Item>
                                        </Col>
                                        <Col className="gutter-row" md={12} sm={24} xs={24}>
                                            <Form.Item name={"fatherChineseName"} label={t("Father's Name (Chinese)")} rules={[
                                                { pattern: /^[\u4E00-\u9FFF]{0,}$/, message: t("Please enter Father's Name (Chinese)") }
                                            ]}>
                                                <Input />
                                            </Form.Item>
                                        </Col>
                                        <Col className="gutter-row" span={24}>
                                            <Form.Item name={"fatherIDCardPassport"} label={t("Father's ID Card/Passport No.")} rules={[
                                                { required: true, message: t("Please enter Father's ID Card/Passport No.") },
                                            ]}>
                                                <Input />
                                            </Form.Item>
                                        </Col>
                                        <Col className="gutter-row" md={12} sm={24} xs={24}>
                                            <Form.Item name={"fatherOccupation"} label={t("Father's Occupation")} rules={[
                                                { required: true, message: t("Please enter Father's Occupation") },
                                            ]}>
                                                <Input />
                                            </Form.Item>
                                        </Col>
                                        <Col className="gutter-row" md={12} sm={24} xs={24}>
                                            <Form.Item name={"fatherTitle"} label={t("Father's Title")} rules={[
                                                { required: true, message: t("Please enter Father's Title") },
                                            ]}>
                                                <Input />
                                            </Form.Item>
                                        </Col>
                                        <Col className="gutter-row" md={12} sm={24} xs={24}>
                                            <Form.Item name={"fatherTelephoneOffice"} label={t("Father's Telephone (Office)")}
                                                rules={[{ pattern: /[0-9 () \- + ]$/, message: t("Please enter Father's Telephone (Office)") }
                                                ]}>
                                                <Input type='tel' />
                                            </Form.Item>
                                        </Col>
                                        <Col className="gutter-row" md={12} sm={24} xs={24}>
                                            <Form.Item name={"fatherTelephoneMobile"} label={t("Father's Telephone (Mobile)")}
                                                rules={[{ required: true, message: t("Please enter Father's Telephone (Mobile)") },
                                                { pattern: /[0-9 () \- + ]$/, message: t("Please enter Father's Telephone (Mobile)") }]}>
                                                <Input type='tel' />
                                            </Form.Item>
                                        </Col>
                                        <Col className="gutter-row" md={24} sm={24} xs={24}>
                                            <Form.Item name={"fatherOfficeAddress"} label={t("Father's Office Address")}
                                                rules={[{ required: true, message: t("Please enter Father's Office Address") }
                                                ]}>
                                                <Input />
                                            </Form.Item>
                                        </Col>
                                        <Col className="gutter-row" md={12} sm={24} xs={24}>
                                            <Form.Item name={"fatherYearOfAttendanceA"} label={t("Father's Year of Attendance From")}
                                                rules={[
                                                    ({ getFieldValue }) => ({
                                                        validator(rule, value) {
                                                            if (value != "") {
                                                                let num = parseInt(value);
                                                                if (isNaN(num) || num < 1800 || num > new Date().getFullYear()) {
                                                                    return Promise.reject(t("Please enter a valid year"));
                                                                } else if (parseInt(value) > parseInt(getFieldValue("fatherYearOfAttendanceB"))) {
                                                                    return Promise.reject(t("Please enter Father's Year of Attendance From"));
                                                                }
                                                            }
                                                            return Promise.resolve();
                                                        }
                                                    })
                                                ]}>
                                                <Input maxLength={4} />
                                            </Form.Item>
                                        </Col>
                                        <Col className="gutter-row" md={12} sm={24} xs={24}>
                                            <Form.Item name={"fatherYearOfAttendanceB"} label={t("Father's Year of Attendance To")}
                                                rules={[
                                                    ({ getFieldValue }) => ({
                                                        validator(rule, value) {
                                                            if (value != "") {
                                                                let num = parseInt(value);
                                                                if (isNaN(num) || num < 1800 || num > new Date().getFullYear()) {
                                                                    return Promise.reject(t("Please enter a valid year"));
                                                                } else if (parseInt(value) < parseInt(getFieldValue("fatherYearOfAttendanceA"))) {
                                                                    return Promise.reject(t("Please enter Father's Year of Attendance To"));
                                                                }
                                                            }
                                                            return Promise.resolve();
                                                        }
                                                    })
                                                ]}
                                            >
                                                <Input maxLength={4} />
                                            </Form.Item>
                                        </Col>
                                        <Col className='gutter-row' span={24}>
                                            <Form.Item label={t("Father Campus (Optional)")}>
                                                <Row>
                                                    <Col span={8}>
                                                        <Checkbox name='fatherCampusPreparatorySchool' onClick={() => {
                                                            setFatherCampusPreparatorySchool(!fatherCampusPreparatorySchool);
                                                        }} checked={fatherCampusPreparatorySchool}>
                                                            {t("Preparatory School")}
                                                        </Checkbox>
                                                    </Col>
                                                    <Col span={8}>
                                                        <Checkbox name='fatherCampusCollege' onClick={() => {
                                                            setFatherCampusCollege(!fatherCampusCollege);
                                                        }} checked={fatherCampusCollege} >
                                                            {t("College")}
                                                        </Checkbox>
                                                    </Col>
                                                </Row>
                                            </Form.Item>
                                        </Col>
                                        <Col className="gutter-row" md={12} sm={24} xs={24}>
                                            <Form.Item name={"fatherIsSSCAAMembership"} label={t("SSCAA Membership")}>
                                                <Select
                                                    value={fatherIsSSCAAMembership}
                                                    onChange={o => {
                                                        setFatherIsSSCAAMembership(o);
                                                    }}
                                                    allowClear>
                                                    {isSSCAAMembershipOption.map((option) => (
                                                        <Option key={`fatherIsSSCAAMembershipOption.${option.value}`} value={option.value}>{t(option.label)}</Option>
                                                    ))}
                                                </Select>
                                            </Form.Item>
                                        </Col>
                                        <Col className="gutter-row" md={12} sm={24} xs={24}>
                                            <Form.Item required={fatherIsSSCAAMembership} name={"fatherSSCAAMembershipNumber"} label={t("SSCAA Membership Number")}
                                                rules={[{ required: fatherIsSSCAAMembership, message: t("Please enter SSCAA Membership Number") }
                                                ]}>
                                                <Input disabled={!fatherIsSSCAAMembership} />
                                            </Form.Item>
                                        </Col>
                                    </Row>}
                                </ProCard>
                                <Divider />
                                <ProCard
                                    title={<Title level={5} type={!fatherInformation && !motherInformation ? "danger" : "secondary"}>{t("Mother's Information")}</Title>}
                                    collapsible
                                    collapsibleIconRender={({
                                        collapsed: buildInCollapsed,
                                    }: {
                                        collapsed: boolean;
                                    }) => (buildInCollapsed ? <span></span> : <span></span>)}
                                    collapsed={!motherInformation}
                                    extra={
                                        <FormItem>
                                            <Switch
                                                checkedChildren={t("Enable")} unCheckedChildren={t("N/A")}
                                                checked={motherInformation}
                                                onChange={o => {
                                                    setMotherInformation(o);
                                                }}
                                            />
                                        </FormItem>
                                    }
                                >
                                    {motherInformation && <Row gutter={16}>
                                        <Col className="gutter-row" md={12} sm={24} xs={24}>
                                            <Form.Item name={"motherEnglishName"} label={t("Mother's Name (English)")} rules={[
                                                { required: true, message: t("Please enter Mother's Name (English)") },
                                                { pattern: /^[a-zA-Z ]+$/, message: t("Please enter Mother's Name (English)") }
                                            ]}>
                                                <Input />
                                            </Form.Item>
                                        </Col>
                                        <Col className="gutter-row" md={12} sm={24} xs={24}>
                                            <Form.Item name={"motherChineseName"} label={t("Mother's Name (Chinese)")} rules={[
                                                { pattern: /^[\u4E00-\u9FFF]{0,}$/, message: t("Please enter Mother's Name (Chinese)") }
                                            ]}>
                                                <Input />
                                            </Form.Item>
                                        </Col>
                                        <Col className="gutter-row" span={24}>
                                            <Form.Item name={"motherIDCardPassport"} label={t("Mother's ID Card/Passport No.")} rules={[
                                                { required: true, message: t("Please enter Mother's ID Card/Passport No.") },
                                            ]}>
                                                <Input />
                                            </Form.Item>
                                        </Col>
                                        <Col className="gutter-row" md={12} sm={24} xs={24}>
                                            <Form.Item name={"motherOccupation"} label={t("Mother's Occupation")} rules={[
                                                { required: true, message: t("Please enter Mother's Occupation") },
                                            ]}>
                                                <Input />
                                            </Form.Item>
                                        </Col>
                                        <Col className="gutter-row" md={12} sm={24} xs={24}>
                                            <Form.Item name={"motherTitle"} label={t("Mother's Title")} rules={[
                                                { required: true, message: t("Please enter Mother's Title") },
                                            ]}>
                                                <Input />
                                            </Form.Item>
                                        </Col>
                                        <Col className="gutter-row" md={12} sm={24} xs={24}>
                                            <Form.Item name={"motherTelephoneOffice"} label={t("Mother's Telephone (Office)")}
                                                rules={[{ pattern: /[0-9 () \- + ]$/, message: t("Please enter Mother's Telephone (Office)") }
                                                ]}>
                                                <Input type='tel' />
                                            </Form.Item>
                                        </Col>
                                        <Col className="gutter-row" md={12} sm={24} xs={24}>
                                            <Form.Item name={"motherTelephoneMobile"} label={t("Mother's Telephone (Mobile)")}
                                                rules={[{ required: true, message: t("Please enter Mother's Telephone (Mobile)") },
                                                { pattern: /[0-9 () \- + ]$/, message: t("Please enter Mother's Telephone (Mobile)") }]}>
                                                <Input type='tel' />
                                            </Form.Item>
                                        </Col>
                                        <Col className="gutter-row" md={24} sm={24} xs={24}>
                                            <Form.Item name={"motherOfficeAddress"} label={t("Mother's Office Address")}
                                                rules={[{ required: true, message: t("Please enter Mother's Office Address") }
                                                ]}>
                                                <Input />
                                            </Form.Item>
                                        </Col>
                                        <Col className="gutter-row" md={12} sm={24} xs={24}>
                                            <Form.Item name={"motherYearOfAttendanceA"} label={t("Mother's Year of Attendance From")}
                                                rules={[
                                                    ({ getFieldValue }) => ({
                                                        validator(rule, value) {
                                                            if (value != "") {
                                                                let num = parseInt(value);
                                                                if (isNaN(num) || num < 1800 || num > new Date().getFullYear()) {
                                                                    return Promise.reject(t("Please enter a valid year"));
                                                                } else if (parseInt(value) > parseInt(getFieldValue("motherYearOfAttendanceB"))) {
                                                                    return Promise.reject(t("Please enter a valid year"));
                                                                }
                                                            }
                                                            return Promise.resolve();
                                                        }
                                                    })
                                                ]}>
                                                <Input maxLength={4} />
                                            </Form.Item>
                                        </Col>
                                        <Col className="gutter-row" md={12} sm={24} xs={24}>
                                            <Form.Item name={"motherYearOfAttendanceB"} label={t("Mother's Year of Attendance To")}
                                                rules={[
                                                    ({ getFieldValue }) => ({
                                                        validator(rule, value) {
                                                            if (value != "") {
                                                                let num = parseInt(value);
                                                                if (isNaN(num) || num < 1800 || num > new Date().getFullYear()) {
                                                                    return Promise.reject(t("Please enter a valid year"));
                                                                } else if (parseInt(value) < parseInt(getFieldValue("motherYearOfAttendanceA"))) {
                                                                    return Promise.reject(t("Please enter a valid year"));
                                                                }
                                                            }
                                                            return Promise.resolve();
                                                        }
                                                    })
                                                ]}
                                            >
                                                <Input maxLength={4} />
                                            </Form.Item>
                                        </Col>
                                        <Col className='gutter-row' span={24}>
                                            <Form.Item label={t("Mother Campus (Optional)")}>
                                                <Row>
                                                    <Col span={8}>
                                                        <Checkbox name='motherCampusPreparatorySchool' onClick={() => {
                                                            setMotherCampusPreparatorySchool(!motherCampusPreparatorySchool);
                                                        }} checked={motherCampusPreparatorySchool}>
                                                            {t("Preparatory School")}
                                                        </Checkbox>
                                                    </Col>
                                                    <Col span={8}>
                                                        <Checkbox name='motherCampusCollege' onClick={() => {
                                                            setMotherCampusCollege(!motherCampusCollege);
                                                        }} checked={motherCampusCollege} >
                                                            {t("College")}
                                                        </Checkbox>
                                                    </Col>
                                                </Row>
                                            </Form.Item>
                                        </Col>
                                        <Col className="gutter-row" md={12} sm={24} xs={24}>
                                            <Form.Item name={"motherIsSSCAAMembership"} label={t("SSCAA Membership")}>
                                                <Select
                                                    value={motherIsSSCAAMembership}
                                                    onChange={o => {
                                                        setMotherIsSSCAAMembership(o);
                                                    }}
                                                    allowClear>
                                                    {isSSCAAMembershipOption.map((option) => (
                                                        <Option key={`motherIsSSCAAMembershipOption.${option.value}`} value={option.value}>{t(option.label)}</Option>
                                                    ))}
                                                </Select>
                                            </Form.Item>
                                        </Col>
                                        <Col className="gutter-row" md={12} sm={24} xs={24}>
                                            <Form.Item required={motherIsSSCAAMembership} name={"motherSSCAAMembershipNumber"} label={t("SSCAA Membership Number")}
                                                rules={[{ required: motherIsSSCAAMembership, message: t("Please enter SSCAA Membership Number") }
                                                ]}>
                                                <Input disabled={!motherIsSSCAAMembership} />
                                            </Form.Item>
                                        </Col>
                                    </Row>}
                                </ProCard>
                                <div style={{ display: "flex", justifyContent: "space-between" }}>
                                    <Button type="default" size='large' onClick={o => { setCurrent(1) }}>
                                        {t("Back")}
                                    </Button>
                                    <Button type="primary" size='large' htmlType="submit">
                                        {t("Next")}
                                    </Button>
                                </div>
                            </Form>
                        </ProCard>}
                        {current == 3 && <ProCard style={{ display: current == 3 ? "visible" : "none" }}>
                            <Form form={step3Form} name="confirmation" layout="vertical" onFinish={onFinishStep3}>
                                <p style={{ color: "red" }}  >
                                    <b>{t("Please make sure all information is correct, no amendment shall be made after submission!")}</b>
                                </p>
                                <Descriptions bordered size={"small"} title={steps[0].title}>
                                    <Descriptions.Item span={3} label={t("Birth Certificate")}>
                                        {(birthCertificate) && birthCertificateFileType.startsWith("application") && <iframe
                                            id="birthCertificateFrame"
                                            src={`data:${birthCertificateFileType};base64,${birthCertificate}`}
                                            style={{ width: "100%", height: 500 }}
                                            width="" height=""></iframe>}
                                        {(birthCertificate) && birthCertificateFileType.startsWith("image") && <img style={{ width: "100%", maxWidth: 500 }} src={`data:${birthCertificateFileType};base64,${birthCertificate}`} />}
                                    </Descriptions.Item>
                                    <Descriptions.Item span={3} label={t("HKID/Passport Copy")}>
                                        {(hkIDPassportCopy) && hkIDPassportCopyFileType.startsWith("application") && <iframe
                                            id="hkIDPassportCopyFrame"
                                            src={`data:${hkIDPassportCopyFileType};base64,${hkIDPassportCopy}`}
                                            style={{ width: "100%", height: 500 }}
                                            width="" height=""></iframe>}
                                        {(hkIDPassportCopy) && hkIDPassportCopyFileType.startsWith("image") && <img style={{ width: "100%", maxWidth: 500 }} src={`data:${hkIDPassportCopyFileType};base64,${hkIDPassportCopy}`} />}
                                    </Descriptions.Item>
                                    <Descriptions.Item span={3} label={t("Applicant's Photo")}>
                                        {(applicantPhoto) && <img
                                            id="applicantPhotoFrame"
                                            src={`data:${applicantPhotoFileType};base64,${applicantPhoto}`}
                                            style={{ width: "100%", maxWidth: 500 }} />}
                                    </Descriptions.Item>
                                    <Descriptions.Item span={3} label={t("Bank Receipt Copy")}>
                                        {(bankReceiptCopy) && bankReceiptCopyFileType.startsWith("application") && <iframe
                                            id="bankReceiptCopyFrame"
                                            src={`data:${bankReceiptCopyFileType};base64,${bankReceiptCopy}`}
                                            style={{ width: "100%", height: 500 }}
                                            width="" height=""></iframe>}
                                        {(bankReceiptCopy) && bankReceiptCopyFileType.startsWith("image") && <img style={{ width: "100%", maxWidth: 500 }} src={`data:${bankReceiptCopyFileType};base64,${bankReceiptCopy}`} />}
                                    </Descriptions.Item>

                                    {/*<Descriptions.Item label={t("Bank Reference Number")}>
                                        {bankReferenceNumber}
                                        </Descriptions.Item>*/}
                                    <Descriptions.Item label={t("Date of Application")}>
                                        {dateOfApplication}
                                    </Descriptions.Item>
                                </Descriptions>
                                <Descriptions bordered size={"small"} title={steps[1].title} style={{ marginTop: 24 }}>
                                    <Descriptions.Item span={1.5} label={t("Surname (English)")}>
                                        {surname}
                                    </Descriptions.Item>
                                    <Descriptions.Item span={1.5} label={t("Given Name (English)")}>
                                        {givenName}
                                    </Descriptions.Item>
                                    <Descriptions.Item span={3} label={t("Name in Chinese")}>
                                        {nameInChinese}
                                    </Descriptions.Item>
                                    <Descriptions.Item span={3} label={t("Gender")} >
                                        {gender}
                                    </Descriptions.Item>
                                    <Descriptions.Item span={nationality === 'Other 其他' ? 1 : 3} label={t("Nationality")} >
                                        {nationality}
                                    </Descriptions.Item>
                                    {nationality === 'Other 其他' && (
                                        <Descriptions.Item span={2} label={t("Other Nationality")} >
                                            {nationalityOther}
                                        </Descriptions.Item>
                                    )}
                                    <Descriptions.Item span={3} label={t("Date of Birth")} >
                                        {dateOfBirth}
                                    </Descriptions.Item>
                                    <Descriptions.Item span={3} label={t("Place of Birth")}>
                                        {placeOfBirth}
                                    </Descriptions.Item>
                                    <Descriptions.Item span={3} label={t("HK Birth Certificate/Passport No.")}>
                                        {hkBirthCertificatePassport}
                                    </Descriptions.Item>
                                    <Descriptions.Item span={1.5} label={t("Home Address Region")} >
                                        {homeAddressRegion}
                                    </Descriptions.Item>
                                    <Descriptions.Item span={1.5} label={t("Home Address District")} >
                                        {homeAddressDistrict}
                                    </Descriptions.Item>
                                    <Descriptions.Item span={3} label={t("Home Address")} >
                                        {homeAddress}
                                    </Descriptions.Item>
                                    <Descriptions.Item span={1.5} label={t("Telephone")} >
                                        {telephone}
                                    </Descriptions.Item>
                                    <Descriptions.Item span={1.5} label={t("E-mail")} >
                                        {email}
                                    </Descriptions.Item>
                                    <Descriptions.Item span={3} label={t("Religion")} >
                                        {religion}
                                    </Descriptions.Item>
                                    <Descriptions.Item span={3} label={t("Grade Applied For")} >
                                        {gradeAppliedFor}
                                    </Descriptions.Item>
                                    <Descriptions.Item span={motherTongueOfTheApplicant === 'Other 其他' ? 1 : 3} label={t("Mother Tongue of the Applicant")} >
                                        {motherTongueOfTheApplicant}
                                    </Descriptions.Item>
                                    {motherTongueOfTheApplicant === 'Other 其他' && (
                                        <Descriptions.Item span={2} label={t("Other Mother Tongue")} >
                                            {motherTongueOther}
                                        </Descriptions.Item>
                                    )}
                                    <Descriptions.Item span={3} label={t("Present School")} >
                                        {presentSchool}
                                    </Descriptions.Item>
                                    <Descriptions.Item span={1.5} label={t("Years Attended From")} >
                                        {yearsAttendedA}
                                    </Descriptions.Item>
                                    <Descriptions.Item span={1.5} label={t("Years Attended To")} >
                                        {yearsAttendedB}
                                    </Descriptions.Item>
                                    <Descriptions.Item span={1.5} label={t("Number of Siblings (Including the Applicant)")} >
                                        {noOfSiblings}
                                    </Descriptions.Item>
                                    <Descriptions.Item span={1.5} label={t("Applicant's position among Siblings")}>
                                        {applicantPositionAmongSiblings}
                                    </Descriptions.Item>
                                    <Descriptions.Item span={3} label={t("Apply with Siblings")} >
                                        {applyWithSiblings ? "Y" : "N"}
                                    </Descriptions.Item>
                                    <Descriptions.Item span={3} label={t("Sibling's Name of Attend (Optional)")}>
                                        {siblingNameOfAttend}
                                    </Descriptions.Item>
                                    <Descriptions.Item span={1.5} label={t("SiblingYears Attended From (Optional)")} >
                                        {siblingYearOfAttendA}
                                    </Descriptions.Item>
                                    <Descriptions.Item span={1.5} label={t("Sibling Years Attended To (Optional)")} >
                                        {siblingYearOfAttendB}
                                    </Descriptions.Item>
                                    <Descriptions.Item span={3} label={t("Siblings' Campus (Optional)")}>
                                        {[(siblingCampusPreparatory ? t("Preparatory School") : ""), (siblingCampusCollege ? t("College") : "")].filter(t => t != "").join(", ")}
                                    </Descriptions.Item>
                                    <Descriptions.Item span={3} label={t("Siblings' Grade (Optional)")}>
                                        {siblingGrade}
                                    </Descriptions.Item>
                                </Descriptions>
                                <Descriptions bordered size={"small"} title={steps[2].title} style={{ marginTop: 24 }}>
                                    <Descriptions.Item span={3} label={t("Father's Information")}> {fatherInformation ? "Y" : "N"}</Descriptions.Item>
                                    <Descriptions.Item span={1.5} label={t("Father's Name (English)")}> {fatherEnglishName}</Descriptions.Item>
                                    <Descriptions.Item span={1.5} label={t("Father's Name (Chinese)")}> {fatherChineseName}</Descriptions.Item>
                                    <Descriptions.Item span={3} label={t("Father's ID Card/Passport No.")}> {fatherIDCardPassport}</Descriptions.Item>
                                    <Descriptions.Item span={3} label={t("Father's Occupation")}> {fatherOccupation}</Descriptions.Item>
                                    <Descriptions.Item span={3} label={t("Father's Title")}> {fatherTitle}</Descriptions.Item>
                                    <Descriptions.Item span={1.5} label={t("Father's Telephone (Office)")}> {fatherTelephoneOffice}</Descriptions.Item>
                                    <Descriptions.Item span={1.5} label={t("Father's Telephone (Mobile)")}> {fatherTelephoneMobile}</Descriptions.Item>
                                    <Descriptions.Item span={3} label={t("Father's Office Address")}> {fatherOfficeAddress}</Descriptions.Item>
                                    <Descriptions.Item span={1.5} label={t("Father's Year of Attendance From")}> {fatherYearOfAttendanceA}</Descriptions.Item>
                                    <Descriptions.Item span={1.5} label={t("Father's Year of Attendance To")}> {fatherYearOfAttendanceB}</Descriptions.Item>
                                    <Descriptions.Item span={3} label={t("Father Campus (Optional)")}> {[(fatherCampusPreparatorySchool ? t("Preparatory School") : ""), (fatherCampusCollege ? t("College") : "")].filter(t => t != "").join(", ")}</Descriptions.Item>
                                    <Descriptions.Item span={1.5} label={t("SSCAA Membership")}> {fatherIsSSCAAMembership ? "Y" : "N"}</Descriptions.Item>
                                    <Descriptions.Item span={1.5} label={t("SSCAA Membership Number")}> {fatherSSCAAMembershipNumber}</Descriptions.Item>
                                    <Descriptions.Item span={3} label={""}> </Descriptions.Item>
                                    <Descriptions.Item span={3} label={t("Mother's Information")}> {motherInformation ? "Y" : "N"}</Descriptions.Item>
                                    <Descriptions.Item span={1.5} label={t("Mother's Name (English)")}> {motherEnglishName}</Descriptions.Item>
                                    <Descriptions.Item span={1.5} label={t("Mother's Name (Chinese)")}> {motherChineseName}</Descriptions.Item>
                                    <Descriptions.Item span={3} label={t("Mother's ID Card/Passport No.")}> {motherIDCardPassport}</Descriptions.Item>
                                    <Descriptions.Item span={3} label={t("Mother's Occupation")}> {motherOccupation}</Descriptions.Item>
                                    <Descriptions.Item span={3} label={t("Mother's Title")}> {motherTitle}</Descriptions.Item>
                                    <Descriptions.Item span={1.5} label={t("Mother's Telephone (Office)")}> {motherTelephoneOffice}</Descriptions.Item>
                                    <Descriptions.Item span={1.5} label={t("Mother's Telephone (Mobile)")}> {motherTelephoneMobile}</Descriptions.Item>
                                    <Descriptions.Item span={3} label={t("Mother's Office Address")}> {motherOfficeAddress}</Descriptions.Item>
                                    <Descriptions.Item span={1.5} label={t("Mother's Year of Attendance From")}> {motherYearOfAttendanceA}</Descriptions.Item>
                                    <Descriptions.Item span={1.5} label={t("Mother's Year of Attendance To")}> {motherYearOfAttendanceB}</Descriptions.Item>
                                    <Descriptions.Item span={3} label={t("Mother Campus (Optional)")}>{[(motherCampusPreparatorySchool ? t("Preparatory School") : ""), (motherCampusCollege ? t("College") : "")].filter(t => t != "").join(", ")} { }</Descriptions.Item>
                                    <Descriptions.Item span={1.5} label={t("SSCAA Membership")}> {motherIsSSCAAMembership ? "Y" : "N"}</Descriptions.Item>
                                    <Descriptions.Item span={1.5} label={t("SSCAA Membership Number")}> {motherSSCAAMembershipNumber}</Descriptions.Item>

                                </Descriptions>
                                <div style={{ display: "flex", justifyContent: "space-between", marginTop: 24 }}>
                                    <Button type="default" size='large' onClick={o => { setCurrent(2) }}>
                                        {t("Back")}
                                    </Button>
                                    <Button type="primary" size='large' htmlType="submit">
                                        {t("Next")}
                                    </Button>
                                </div>
                            </Form>
                        </ProCard>}
                    </Layout>
                </Content >
                <Modal title="" closeIcon={null} confirmLoading={true} open={isLoading} style={{ textAlign: "center" }} footer={null}>
                    <LoadingOutlined style={{ margin: "0 auto", fontSize: 40 }} spin={true} />
                </Modal>
                {< MyFooter />}
            </Layout >
        </>
    );
};