import { useEffect, useRef, useState } from "react"
import { useTranslation } from "react-i18next"

import { ActionType, ProColumns } from "@ant-design/pro-components"
import { Button, Switch, message, Image, Breadcrumb, Descriptions, Tabs, Table, Skeleton } from "antd"

import { Application,  FirstInterviewResult, SecondInterviewResult } from "../../../../models/application"
import { AntDProTable } from "../../../../components/antdProTable"
import axios from "axios"
import { applicationService } from "../../../../services/applicationService"
import { Link, useNavigate, useParams } from "react-router-dom"
import moment from "moment"
import { HomeOutlined, UserOutlined, FolderOpenOutlined, TagOutlined, TagsOutlined, ReloadOutlined } from '@ant-design/icons';
import { ApplicationFormModifyPage } from "./applicationPreview"
import { applicationStatusToEnum } from "../../../../tools/translate"
import { FirstRoundInterviewResultPage } from "./firstround"
import { SecondRoundInterviewResultPage } from "./secondround"
import ButtonGroup from "antd/es/button/button-group"
import { ApplicationActionsPage } from "./applicationActions"
import { ApplicationForm } from "../../../applicationForm/applicationForm"

//Start

export function NewApplicationPage() {
    const { t } = useTranslation()
    const { id, app } = useParams();

    const [application, setApplication] = useState<Application>()

    useEffect(() => {
    }, [])
    return (
        <>
            <Breadcrumb
                itemRender={(route, params, routes, paths) => {
                    if (route.href) {
                        return <Link to={route.href || "/"}> {route.title} </Link>
                    }
                    return route.title
                }}
                style={{ marginBottom: 30 }}
                items={[
                    {
                        href: '/dashboard/',
                        title: <HomeOutlined />,
                    },
                    {
                        href: `/dashboard/applicationYears/`,
                        title: (
                            <>
                                <TagsOutlined />
                                <span>{t("Application Years")}</span>
                            </>
                        ),
                    },
                    {
                        href: `/dashboard/applicationYears/${id}`,
                        title: (
                            <>
                                <TagOutlined />
                                <span>{t("Applications")}</span>
                            </>
                        ),
                    },
                    {
                        title: t('Application'),
                    },
                ]}
            />

            <ApplicationForm admin={true} />,

        </>
    )
}

