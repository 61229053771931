import { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { useNavigate, useParams } from "react-router-dom"

import { Button, Card, Form, Input } from "antd"
import logo from "../../assests/images/logo.png"



const ResetPassword = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const [loading, setLoading] = useState(false)


  const { token } = useParams()

  let error =
    !/^[0-9a-fA-F]{8}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{12}$/g.test(
      token || ""
    )

  useEffect(() => {
    console.log(token)
  }, [])

  const onFinish = (values: any) => {
    setLoading(true)
   
  }

  return error ? (
    <div style={{ width: "100%", margin: "0 auto" }}>
      <div
        style={{
          width: "100%",
          minHeight: "100vh",
          display: "flex",
          flexWrap: "wrap",
          justifyContent: "center",
          alignItems: "center",
          padding: 15,
          background: "#f2f2f2",
        }}
      >
        <img src={logo} style={{maxWidth:172, marginBottom:20}} />
        <Card
          style={{
            // width: 500,
            borderRadius: 10,
            boxShadow: "5px 5px #e5e5e5",
            // fontSize: "100px",
            padding: 20,
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {t("Something went wrong")}
        </Card>
      </div>
    </div>
  ) : (
    <div style={{ width: "100%", margin: "0 auto" }}>
      <div
        style={{
          width: "100 %",
          minHeight: "100vh",
          display: "flex",
          flexWrap: "wrap",
          justifyContent: "center",
          alignItems: "center",
          padding: 15,
          background: "#f2f2f2",
        }}
      >
        <img src={logo} style={{maxWidth:172, marginBottom:20}} />

        <Card
          title={
            <div style={{ fontSize: "25px", textAlign: "center" }}>
             
              <br />
              {t("Reset Password")}
            </div>
          }
          style={{
            width: 500,
            borderRadius: 10,
            boxShadow: "5px 5px #e5e5e5  ",
            fontSize: "100px",
            paddingLeft: 20,
            paddingRight: 20,
          }}
        >
          <Form onFinish={onFinish} autoComplete="off" layout="vertical">
            <Form.Item
              label={t("Company Code")}
              name="companyCode"
              rules={[
                { required: true, message: "Please input your company code!" },
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              label={t("Username")}
              name="username"
              rules={[
                { required: true, message: "Please input your username!" },
              ]}
            >
              <Input />
            </Form.Item>

            <Form.Item
              name={"password"}
              label={t("New password")}
             
              // valuePropName={"value"}
            >
              <Input.Password />
            </Form.Item>

            <Form.Item
              name={"confirm password"}
              label={t("Confirm New Password")}
              rules={[
                {
                  required: true,
                  message: t("Please confirm your password!"),
                },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (!value || getFieldValue("password") === value) {
                      return Promise.resolve()
                    }
                    return Promise.reject(
                      new Error(t("The passwords do not match!"))
                    )
                  },
                }),
              ]}
              // valuePropName={"value"}
            >
              <Input.Password />
            </Form.Item>

            {/* <Form.Item
              label={t("New Password")}
              name="password"
              rules={[
                { required: true, message: "Please input your password!" },
              ]}
            >
              <Input.Password />
            </Form.Item> */}

            <Form.Item>
              <Button
                style={{
                  marginRight: 10,
                }}
                type="primary"
                htmlType="submit"
              >
                {t("Reset Password")}
              </Button>

            </Form.Item>
          </Form>
        </Card>
      </div>
    </div>
  )
}

export default ResetPassword
