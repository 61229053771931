import { useTranslation } from "react-i18next";
import { FirstInterviewResult } from "../../../../models/application";
import React from "react";
import { Button, Form, Input, Space, Typography, Layout, Select, InputNumber, Row, Col, Statistic, Divider } from "antd";
import { useForm } from "antd/es/form/Form";
import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";
import TextArea from "antd/es/input/TextArea";
import { settingService } from "../../../../services/setting";
const { Header, Content, Footer, Sider } = Layout;

export function FirstRoundInterviewResultPage(props: { form?: FirstInterviewResult | string, onUpdate: (applicationForm: FirstInterviewResult | string) => void }) {
    const { t } = useTranslation();
    const [modify, setModify] = React.useState(true);
    const [f1] = useForm();

    const [scoreToy, setScoreToy] = React.useState<number>();
    const [scoreReading, setScoreReading] = React.useState<number>();
    const [scoreOverall, setScoreOverall] = React.useState<number>();
    const [scorePerformance, setScorePerformance] = React.useState<number>();
    const [totalScore, setTotalScore] = React.useState<number>();
    const [remark, setRemark] = React.useState("");
    const [languages, setLanguages] = React.useState<string[]>([]);
    const [positive, setPositive] = React.useState<string[]>([]);
    const [negative, setNegative] = React.useState<string[]>([]);
    const [positiveOptions, setPositiveOptions] = React.useState<string[]>([]);
    const [negativeOptions, setNegativeOptions] = React.useState<string[]>([]);
    const [languageOptions, setLanguageOptions] = React.useState<string[]>([]);
    React.useEffect(() => {
        resetForm();
    }, [props.form])
    React.useEffect(() => {
        settingService.getHashTags().then(res => {
            if (res.status == "OK") {
                setPositiveOptions(res.reference.PositivePersonality)
                setNegativeOptions(res.reference.NegativePersonality)
                setLanguageOptions(res.reference.Language)
            }

        })
    }, [])

    function resetForm() {
        if (props.form && typeof props.form === 'object') {
            setScoreToy(props.form.firstInResultScoure["談玩具"])
            setScoreReading(props.form.firstInResultScoure["聽說"])
            setScoreOverall(props.form.firstInResultScoure["總表現"])
            setScorePerformance(props.form.firstInResultScoure["表演"])
            setTotalScore(
                (parseFloat(props.form.firstInResultScoure["談玩具"] + "") || 0) +
                (parseFloat(props.form.firstInResultScoure["聽說"] + "") || 0) +
                (parseFloat(props.form.firstInResultScoure["總表現"] + "") || 0) +
                (parseFloat(props.form.firstInResultScoure["表演"] + "") || 0)
            )
            setRemark(props.form.firstInResultRemark)
            setLanguages(props.form.firstInResultLanguage)
            setPositive(props.form.firstInResultPersonalityPositive)
            setNegative(props.form.firstInResultPersonalityNegative)
            f1.setFieldsValue({
                scoreToy: props.form.firstInResultScoure["談玩具"],
                scoreReading: props.form.firstInResultScoure["聽說"],
                scoreOverall: props.form.firstInResultScoure["總表現"],
                scorePerformance: props.form.firstInResultScoure["表演"],
                remark: props.form.firstInResultRemark,
                languages: props.form.firstInResultLanguage,
                positive: props.form.firstInResultPersonalityPositive,
                negative: props.form.firstInResultPersonalityNegative
            });
        } else {
            setScoreToy(0)
            setScoreReading(0)
            setScoreOverall(0)
            setScorePerformance(0)
            setRemark("")
            setLanguages([])
            setPositive([])
            setNegative([])
            f1.setFieldsValue({
                scoreToy: 0,
                scoreReading: 0,
                scoreOverall: 0,
                scorePerformance: 0,
                remark: "",
                languages: [],
                positive: [],
                negative: []
            });
        }

    }
    return <>
        <Content style={{ display: "flex", alignContent: "space-between", marginBottom: 30 }}>
            <p style={{ display: "flex", flex: 1, fontStyle: "italic" }}>{t("*If the form is not loaded correctly, please press the Reset button")}</p>
            <Button.Group>
                {!modify && <Button onClick={() => {
                    setModify(true);
                    f1.submit()
                }} type='primary'>{t("Save & Update")}</Button>}
                {modify && <Button onClick={() => {
                    setModify(false);
                }}>{t("Edit")}</Button>}
                <Button onClick={() => {
                    resetForm();
                    setModify(true);
                }} >{t("Reset")}</Button>
            </Button.Group>
        </Content>
        <Form disabled={modify} name="firstRoundInterview" layout="vertical" form={f1} initialValues={{
            scoreToy,
            scoreReading,
            scoreOverall,
            scorePerformance,
            remark,
            languages,
            positive,
            negative
        }} onFinish={(values: any) => {
            let form = {
                firstInResultScoure: {
                    "談玩具": values.scoreToy,
                    "聽說": values.scoreReading,
                    "總表現": values.scoreOverall,
                    "表演": values.scorePerformance,
                },
                firstInResultRemark: values.remark,
                firstInResultLanguage: values.languages,
                firstInResultPersonalityPositive: values.positive,
                firstInResultPersonalityNegative: values.negative
            }
            props.onUpdate(form)
        }}>
            <Row gutter={16}>
                <Col className="gutter-row" md={24} sm={24} xs={24}>
                    <Statistic title={t("Total Score")} value={totalScore} />
                </Col>
                <Col className="gutter-row" md={24} sm={24} xs={24} style={{ marginTop: 24 }}>
                    <Form.Item label={t("Remark")} name={"remark"}>
                        <TextArea rows={4} />
                    </Form.Item>
                </Col>
            </Row>
            <Divider />
            <Row gutter={16}>
                <Col className="gutter-row" md={12} sm={24} xs={24}>
                    <Form.Item label={t("Talk about toys")} name={"scoreToy"}>
                        <InputNumber
                            min={0}
                            max={100}
                            onChange={(e) => {
                                setTotalScore(
                                    (parseFloat(f1.getFieldValue("scoreToy") + "") || 0) +
                                    (parseFloat(f1.getFieldValue("scoreReading") + "") || 0) +
                                    (parseFloat(f1.getFieldValue("scoreOverall") + "") || 0) +
                                    (parseFloat(f1.getFieldValue("scorePerformance") + "") || 0)
                                )
                            }}
                            style={{ width: '100%' }} />
                    </Form.Item>
                </Col>
                <Col className="gutter-row" md={12} sm={24} xs={24}>
                    <Form.Item label={t("Listening and Speaking")} name={"scoreReading"}>
                        <InputNumber
                            min={0}
                            max={100}
                            onChange={(e) => {
                                setTotalScore(
                                    (parseFloat(f1.getFieldValue("scoreToy") + "") || 0) +
                                    (parseFloat(f1.getFieldValue("scoreReading") + "") || 0) +
                                    (parseFloat(f1.getFieldValue("scoreOverall") + "") || 0) +
                                    (parseFloat(f1.getFieldValue("scorePerformance") + "") || 0)
                                )
                            }}
                            style={{ width: '100%' }} />
                    </Form.Item>
                </Col>
                <Col className="gutter-row" md={12} sm={24} xs={24}>
                    <Form.Item label={t("Overall Score")} name={"scoreOverall"}>
                        <InputNumber
                            min={0}
                            max={100}
                            onChange={(e) => {
                                setTotalScore(
                                    (parseFloat(f1.getFieldValue("scoreToy") + "") || 0) +
                                    (parseFloat(f1.getFieldValue("scoreReading") + "") || 0) +
                                    (parseFloat(f1.getFieldValue("scoreOverall") + "") || 0) +
                                    (parseFloat(f1.getFieldValue("scorePerformance") + "") || 0)
                                )
                            }}
                            style={{ width: '100%' }} />
                    </Form.Item>
                </Col>
                <Col className="gutter-row" md={12} sm={24} xs={24}>
                    <Form.Item label={t("Performance")} name={"scorePerformance"}>
                        <InputNumber
                            min={0}
                            max={100}
                            onChange={(e) => {
                                setTotalScore(
                                    (parseFloat(f1.getFieldValue("scoreToy") + "") || 0) +
                                    (parseFloat(f1.getFieldValue("scoreReading") + "") || 0) +
                                    (parseFloat(f1.getFieldValue("scoreOverall") + "") || 0) +
                                    (parseFloat(f1.getFieldValue("scorePerformance") + "") || 0)
                                )
                            }}
                            style={{ width: '100%' }} />
                    </Form.Item>
                </Col>

                <Col className="gutter-row" md={24} sm={24} xs={24}>
                    <Form.Item label={t("Language")} name={"languages"}>
                        <Select
                            mode="tags"
                            style={{ width: '100%' }}
                            options={languageOptions.map((d: any) => {
                                return { value: d, label: d }
                            })}
                        />
                    </Form.Item>
                </Col>
                <Col className="gutter-row" md={12} sm={24} xs={24}>
                    <Form.Item label={t("Positive Personality")} name={"positive"}>
                        <Select
                            mode="tags"
                            style={{ width: '100%' }}
                            options={positiveOptions.map((d: any) => {
                                return { value: d, label: d }
                            })}
                        />
                    </Form.Item>
                </Col>
                <Col className="gutter-row" md={12} sm={24} xs={24}>
                    <Form.Item label={t("Negative Personality")} name={"negative"}>
                        <Select
                            mode="tags"
                            style={{ width: '100%' }}
                            options={negativeOptions.map((d: any) => {
                                return { value: d, label: d }
                            })}
                        />
                    </Form.Item>
                </Col>
            </Row>
        </Form>

    </>
}