// import getConfig from "next/config"

import axios, { AxiosResponse } from "axios"

import { UserRegistrationForm } from "../models/userRegistration.form"
import apiClient, { handleError } from "./apiClient"
import { Application } from "../models/application"
import { Timeslot } from "../models/timeslot"
import { User } from "../models/user"

var defaultUser: any = undefined
if (typeof window !== "undefined") {
  // Perform localStorage action
  var usr = localStorage.getItem("user")
  if (usr) defaultUser = JSON.parse(usr)
}


export const accountService = {
  getAllUser,
  deleteUser,
  editUser,
  addtUser,
  lockUser,
  unlockUser,
  changeUserPassword
}
async function getAllUser(args: any) {
  try {

    let consolidArgs = {
      offset: String((args.params.current - 1) * (args.params.pageSize)),
      limit: String(args.take),
      where: args.where
        ? [
          args.where.displayName ? `displayName:eq:${args.where.displayName}` : "",
          args.where["role"] ? `role:eq:${args.where["role"]}` : "",
          args.where["email"] ? `email:eq:${args.where["email"]}` : ""
        ]

          .filter((item) => item !== "")
          .join("|")
        : "",
      order: args.sort ? `${args.sortBy}:${args.order}` : "",
    }
    const searchParams = new URLSearchParams(consolidArgs)
    let response = await apiClient
      .get<{
        status: "OK", reference: {
          result: User[],
          recordTotal: number
        }
      } | {
        status: "Error",
        error?: {
          msg: string
          code: string;
        }
      }>(`/admin/account/getUsers?${searchParams.toString()}`);

    if (response.data.status === 'OK') {
      const user = response.data

      return response.data
    } else {
      return response.data
    }
  } catch (e) {
    return handleError(e);
  }
}
async function deleteUser(id: string) {
  try {
    let response = await apiClient
      .post<{
        status: "OK", reference: {
          result: User[],
          recordTotal: number
        }
      } | {
        status: "Error",
        error?: {
          msg: string
          code: string;
        }
      }>(`/admin/account/removeAccount`, { id: id });

    if (response.data.status === 'OK') {
      const user = response.data

      return response.data
    } else {
      return response.data
    }
  } catch (e) {
    return handleError(e);
  }
}
async function editUser(data: {
  "id": string
  "displayName": string,
  "role": string
  "email": string
}) {
  try {
    let response = await apiClient
      .post<{
        status: "OK"
      } | {
        status: "Error",
        error?: {
          msg: string
          code: string;
        }
      }>(`/admin/account/updateAccount`,data);

    if (response.data.status === 'OK',data) {
      const user = response.data

      return response.data
    } else {
      return response.data
    }
  } catch (e) {
    return handleError(e);
  }
}
async function addtUser( data: {
  "id": string
  "displayName": string,
  "role": string
  "email": string
}) {
  try {
    let response = await apiClient
      .post<{
        status: "OK"
      } | {
        status: "Error",
        error?: {
          msg: string
          code: string;
        }
      }>(`/admin/account/registerAccount`,data);

    if (response.data.status === 'OK') {
      const user = response.data

      return response.data
    } else {
      return response.data
    }
  } catch (e) {
    return handleError(e);
  }
}
async function changeUserPassword(data: { id: string, password: string, confirmPassword: string }) {
  try {
    let response = await apiClient
      .post<{
        status: "OK", reference: {
          result: User[],
          recordTotal: number
        }
      } | {
        status: "Error",
        error?: {
          msg: string
          code: string;
        }
      }>(`/admin/account/resetAccountPassword`, data);

    if (response.data.status === 'OK') {
      const user = response.data

      return response.data
    } else {
      return response.data
    }
  } catch (e) {
    return handleError(e);
  }
}
async function lockUser(id: string) {
  try {
    let response = await apiClient
      .post<{
        status: "OK", reference: {
          result: User[],
          recordTotal: number
        }
      } | {
        status: "Error",
        error?: {
          msg: string
          code: string;
        }
      }>(`/admin/account/lockAccount/${id}`);

    if (response.data.status === 'OK') {
      const user = response.data

      return response.data
    } else {
      return response.data
    }
  } catch (e) {
    return handleError(e);
  }
}
async function unlockUser(id: string) {
  try {
    let response = await apiClient
      .post<{
        status: "OK", reference: {
          result: User[],
          recordTotal: number
        }
      } | {
        status: "Error",
        error?: {
          msg: string
          code: string;
        }
      }>(`/admin/account/unlockAccount/${id}`);

    if (response.data.status === 'OK') {
      const user = response.data

      return response.data
    } else {
      return response.data
    }
  } catch (e) {
    return handleError(e);
  }
}