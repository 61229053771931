import axios, { AxiosError } from 'axios';

axios.defaults.baseURL = "/"
const apiClient = axios.create({
    baseURL: process.env.NODE_ENV.toLocaleLowerCase() == "development" ? "http://localhost:8080" : process.env.REACT_APP_STAGING == "jellykite" ? "https://sscpsapi.jellykite.com" : "https://admissionapi.sscps.edu.hk",
    // other settings...
});


apiClient.interceptors.request.use(
    async (config) => {
        const token = await localStorage.getItem('userToken');
        if (token) {
            config.headers.Authorization = `Bearer ${token}`;
        }
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);
export function handleError(e: any): { 
    status: 'Error'; 
    msg?: { msg?: any }; 
    error: { code: number; message: string } 
} {
    if (e && axios.isAxiosError(e)) {
        const axiosError = e as AxiosError;
        if (axiosError.response) {
            if (axiosError.response.status === 400) {
                return axiosError.response.data as { status: 'Error'; msg?: { msg?: any }; error: { code: number; message: string } };
            } else if (axiosError.response.status === 403) {
                localStorage.clear();
                window.location.href = '/';
       
                return axiosError.response.data as { status: 'Error'; msg?: { msg?: any }; error: { code: number; message: string } };
            } else if (axiosError.response.status === 401) {
                localStorage.clear();
                window.location.href = '/';
                return axiosError.response.data as { status: 'Error'; msg?: { msg?: any }; error: { code: number; message: string } };
            } else {
                return { status: 'Error', msg: {msg:""}, error: { code: axiosError.response.status, message: e.message } };
            }
        }
    }
    return { status: 'Error', msg: {msg:""}, error: { code: 0, message: "Unexpected Error" } };

}
export default apiClient;