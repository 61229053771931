import { useEffect, useRef, useState } from "react"
import { useTranslation } from "react-i18next"

import { ActionType, ProColumns } from "@ant-design/pro-components"
import { Button, Switch, message, Image, Breadcrumb, Descriptions, Tabs, Table, Skeleton, Modal, Upload } from "antd"

import { Application, FirstInterviewResult, SecondInterviewResult } from "../../../../models/application"
import { AntDProTable } from "../../../../components/antdProTable"
import axios from "axios"
import { applicationService } from "../../../../services/applicationService"
import { Link, useNavigate, useParams } from "react-router-dom"
import moment from "moment"
import { HomeOutlined, UserOutlined, FolderOpenOutlined, TagOutlined, TagsOutlined, ReloadOutlined, MailOutlined, CloudDownloadOutlined } from '@ant-design/icons';
import { ApplicationFormModifyPage } from "./applicationPreview"
import { applicationStatusToEnum } from "../../../../tools/translate"
import { FirstRoundInterviewResultPage } from "./firstround"
import { SecondRoundInterviewResultPage } from "./secondround"
import ButtonGroup from "antd/es/button/button-group"
import { ApplicationActionsPage } from "./applicationActions"

//Start
export function ApplicationProfilePage() {
    const { t } = useTranslation()
    const { id, app } = useParams();

    const [application, setApplication] = useState<Application>()
    const [emails, setEmails] = useState<any[]>()
    const [loading, setLoading] = useState<boolean>(true)

    function reloadApplication() {
        applicationService.getApplication(id || "", app || "").then((res) => {
            setLoading(false);
            if (res.status === "OK") {
                setApplication(res.reference)
            }
        })
        applicationService.getEmails(id || "", app || "").then((res) => {
            if (res?.status === "OK") {
                setEmails(res.reference)
            }
        })
    }
    useEffect(() => {
        reloadApplication();
    }, [])
    return (
        <>
            <Breadcrumb
                itemRender={(route, params, routes, paths) => {
                    if (route.href) {
                        return <Link to={route.href || "/"}> {route.title} </Link>
                    }
                    return route.title
                }}
                style={{ marginBottom: 30 }}
                items={[
                    {
                        href: '/dashboard/',
                        title: <HomeOutlined />,
                    },
                    {
                        href: `/dashboard/applicationYears/`,
                        title: (
                            <>
                                <TagsOutlined />
                                <span>{t("Application Years")}</span>
                            </>
                        ),
                    },
                    {
                        href: `/dashboard/applicationYears/${id}`,
                        title: (
                            <>
                                <TagOutlined />
                                <span>{t("Applications")}</span>
                            </>
                        ),
                    },
                    {
                        title: t('Application'),
                    },
                ]}
            />

            <Skeleton loading={loading} active avatar>


                <Descriptions title={<>{t("Application Form")}<Button type="link" onClick={() => {
                    reloadApplication();
                }}><ReloadOutlined /></Button></>}>
                    <Descriptions.Item label={t("Application ID")}>{application?.ApplicationId}</Descriptions.Item>
                    <Descriptions.Item label={t("Grade")}>{application?.ApplicationForm?.gradeAppliedFor}</Descriptions.Item>
                    <Descriptions.Item label={t("Status")}>{t(applicationStatusToEnum(application?.Status))}</Descriptions.Item>
                    <Descriptions.Item label={t("Surname (English)")}>{application?.ApplicationForm?.surname}</Descriptions.Item>
                    <Descriptions.Item label={t("Given Name (English)")}>{application?.ApplicationForm?.givenName}</Descriptions.Item>
                    <Descriptions.Item label={t("Name in Chinese")}>{application?.ApplicationForm?.nameInChinese}</Descriptions.Item>
                    <Descriptions.Item label={t("Telephone")}>{application?.ApplicationForm?.telephone}</Descriptions.Item>
                    <Descriptions.Item label={t("E-mail")}>{application?.ApplicationForm?.email}</Descriptions.Item>
                    <Descriptions.Item label={t("Date of Application")}>{moment(application?.ApplyDate || new Date()).format("yyyy-MM-DD HH:mm:ss")}</Descriptions.Item>

                </Descriptions>

                <Tabs defaultActiveKey="1" items={[{
                    key: 'Application Form',
                    label: t("Application Form"),
                    children: <ApplicationFormModifyPage form={application?.ApplicationForm} onUpdate={(application) => {
                        applicationService.updateApplicationForm(id || "", app || "", application).then(res => {
                            if (res.status === "OK") {
                                message.success(t("Update Success"))
                                reloadApplication();
                            } else {
                                message.error(t("Update Failed"))
                            }
                        })
                    }} />,
                },
                {
                    key: 'Attachments',
                    label: t("Attachments"),
                    children: <>
                        <Table dataSource={[{
                            document: t("Applicant's Photo"),
                            documentType:"ApplicationPhoto",
                            preview: application?.ApplicationForm?.applicantPhoto || ""
                        }, {
                            document: t("Birth Certificate"),
                            documentType:"BirthCertificate",
                            preview: application?.ApplicationForm?.birthCertificateImage || application?.ApplicationForm?.birthCertificatePDF || ""
                        }, {
                            document: t("HKID/Passport Copy"),
                            documentType:"HKIDPassportCopy",
                            preview: application?.ApplicationForm?.hkIDPassportCopyImage || application?.ApplicationForm?.hkIDPassportCopyPDF || ""
                        }, {
                            document: t("Bank Receipt Copy"),
                            documentType:"BankReceiptCopy",
                            preview: application?.ApplicationForm?.bankReceiptCopy || application?.ApplicationForm?.bankReceiptCopyPDF || ""
                        }]} columns={[
                            {
                                title: t('Document'),
                                dataIndex: 'document',
                                key: 'document',

                            },
                            {
                                title: t('Preview'),
                                dataIndex: 'preview',
                                key: 'preview',
                                render(value: string, record, index) {

                                    if ((value) && value.endsWith("pdf")) {
                                        return <iframe
                                            src={value}
                                            style={{ width: "100%", height: 500 }}
                                            width="" height=""></iframe>
                                    } else {
                                        return <Image src={value || ""} />
                                    }
                                },
                            }, {
                                title: t('Upload'),
                                dataIndex: 'upload',
                                key: 'upload',
                                render(value: string, record, index) {
                                    return <Upload fileList={[]} maxCount={1} multiple={false} accept=".png, .jpg, .jpeg, .pdf" beforeUpload={(file, fileList) => {
                                        if (file) {
                                            if (file.type == "application/pdf" || file.type == "image/png" || file.type == "image/jpeg") {
                                                file.arrayBuffer().then((buffer) => {
                                                    let bytes = btoa(new Uint8Array(buffer).reduce((data, byte) => {
                                                        return data + String.fromCharCode(byte);
                                                    }, ''));
                                                    applicationService.updateApplicationFormAttachment(id || "", app || "", {attachmentType:record.documentType, file:`data:${file.type};base64,${bytes}`}).then(res => {
                                                        if (res.status === "OK") {
                                                            message.success(t("Update Success"))
                                                            reloadApplication();
                                                        } else {
                                                            message.error(t("Update Failed"))
                                                        }
                                                    })
                                                })
                    
                                            }
                                        }
                                        return false;
                                    }}>
                                        <Button shape="round" size="large" >{t("Upload")}</Button>
                                    </Upload>
                                },
                            }, {
                                title: t('Download'),
                                dataIndex: 'preview',
                                key: 'preview',
                                render(value: string, record, index) {
                                    return <a href={record.preview} download={true} target="_blank">Download</a>
                                },
                            }
                        ]} />

                    </>,
                },
                {
                    key: 'FirstRound',
                    label: t("First Round Interview Result"),
                    children: <><FirstRoundInterviewResultPage form={application?.FirstInterviewComments} onUpdate={(applicationForm: FirstInterviewResult | string) => {
                        setApplication({ ...application!, FirstInterviewComments: applicationForm })
                        applicationService.updateFirstRoundInterviewResult(id || "", app || "", applicationForm).then(res => {
                            if (res.status === "OK") {
                                message.success(t("Update Success"))
                                reloadApplication();
                            } else {
                                message.error(t("Update Failed"))
                            }
                        })
                    }} /></>,
                },
                {
                    key: 'SecondRound',
                    label: t("Second Round Interview Result"),
                    children: <><SecondRoundInterviewResultPage form={application?.SecondInterviewComments} onUpdate={(applicationForm: SecondInterviewResult | string) => {
                        setApplication({ ...application!, SecondInterviewComments: applicationForm })
                        applicationService.updateSecondRoundInterviewResult(id || "", app || "", applicationForm).then(res => {
                            if (res.status === "OK") {
                                message.success(t("Update Success"))
                                reloadApplication();
                            } else {
                                message.error(t("Update Failed"))
                            }
                        })
                    }} /></>,
                },
                {
                    key: 'Actions',
                    label: t("Actions"),
                    children: <ApplicationActionsPage application={application!} />,
                },
                {
                    key: 'Email',
                    label: t("Email"),
                    children: <>
                        <Table dataSource={emails} columns={[
                            {
                                title: t('Send Time'),
                                dataIndex: 'sendTime',
                                key: 'sendTime',
                                render(value, record, index) {
                                    return moment(value || new Date()).format("yyyy-MM-DD HH:mm:ss")
                                }
                            },
                            {
                                title: t('Email Type'),
                                dataIndex: 'emailType',
                                key: 'emailType',
                                render(value, record, index) {
                                    switch (value) {
                                        case 1:
                                            return t("First Round Interview Email")
                                        case 2:
                                            return t("Second Round Interview Email")
                                        case 3:
                                            return t("Third Round Interview Email")
                                        case 4:
                                            return t("Forget Password Email")
                                        case 5:
                                            return t("Archive Applications Email")
                                        case 6:
                                            return t("Archive Document Email")
                                        case 7:
                                            return t("Offer Email")
                                        case 8:
                                            return t("Reject Email")
                                        case 9:
                                            return t("Waiting List Email")
                                    }
                                    return ""
                                },
                            },
                            {
                                title: t('Message'),
                                dataIndex: 'body',
                                key: 'body',
                                render(value, record, index) {
                                    return <MailOutlined title="Click to see Email Message" onClick={() => {
                                        var wnd = window.open("about:blank", "", "_blank");
                                        if (wnd)
                                            wnd.document.write(value);
                                    }} />
                                },
                            },
                            {
                                title: t('Attachment'),
                                dataIndex: 'attachment',
                                key: 'attachment',
                                render(value, record, index) {
                                    if (value.length > 0) {
                                        return <Link title="Click to download the attachment" target="_blank" to={value[0].href}><CloudDownloadOutlined /></Link>
                                    } else {
                                        return ""
                                    }
                                },
                            },
                            {
                                title: t('Reply Message'),
                                dataIndex: 'replyMessage',
                                key: 'replyMessage',
                            },
                        ]} />

                    </>,
                }]} />
            </Skeleton>
           
        </>
    )
}

