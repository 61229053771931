import { RangePickerProps } from "antd/lib/date-picker";
import dayjs from "dayjs";

export const disabledFuture: RangePickerProps['disabledDate'] = (current) => {
    // Can not select days before today and today
    return current > dayjs().endOf('day');
};
export const disabledPast: RangePickerProps['disabledDate'] = (current) => {
    // Can not select days before today and today
    return current < dayjs().endOf('day');
};
export const within20Years: RangePickerProps['disabledDate'] = (current) => {
    // Can not select days before today and today
    return current > dayjs().add(-4, 'year').endOf('day') || current < dayjs().add(-20, 'year').endOf('day');
};
export const convertToChineseDate = (d: Date, dateonly: boolean) => {

    var str_year = String(d.getFullYear());
    var str_month = String(d.getMonth());
    var str_day = String(d.getDate());
    var int_hour = d.getHours();

    let json_chinese_one_to_ten: any = {
        '0': "零",
        '1': "一",
        '2': "二",
        '3': "三",
        '4': "四",
        '5': "五",
        '6': "六",
        '7': "七",
        '8': "八",
        '9': "九",
        '10': "十"
    }

    var str_chinese_year = new String();
    var str_chinese_month = new String();
    var str_chinese_day = new String();
    var str_chinese_hour = new String();

    for (var i = 0; i < str_year.length; i++) {
        str_chinese_year = str_chinese_year + json_chinese_one_to_ten[str_year[i]];
    }

    if (d.getMonth() < 10) {
        str_chinese_month = json_chinese_one_to_ten[(parseInt(str_month) + 1).toString()];
    } else {
        str_chinese_month = '十' + json_chinese_one_to_ten[(parseInt(str_month[1]) + 1).toString()]
    }

    if (str_day.length == 1) {
        str_chinese_day = json_chinese_one_to_ten[str_day];
    } else if (str_day[0] == '1') {
        if (str_day[1] == '0') {
            str_chinese_day = '十'
        } else {
            str_chinese_day = '十' + json_chinese_one_to_ten[str_day[str_day.length - 1]]
        }
    } else if (str_day[0] == '2') {
        if (str_day[1] == '0') {
            str_chinese_day = '二十'
        } else {
            str_chinese_day = '二十' + json_chinese_one_to_ten[str_day[str_day.length - 1]]
        }
    } else if (str_day[0] == '3') {
        if (str_day[1] == '0') {
            str_chinese_day = '三十'
        } else {
            str_chinese_day = '三十' + json_chinese_one_to_ten[str_day[str_day.length - 1]]
        }
    }

    if (int_hour < 12) {
        str_chinese_hour = '上午'
        if (int_hour == 11) {
            str_chinese_hour = str_chinese_hour + '十一'
        } else {
            str_chinese_hour = str_chinese_hour + json_chinese_one_to_ten[String(int_hour)]
        }
    } else if (int_hour <= 18) {
        str_chinese_hour = '下午'
        if (int_hour == 12) {
            str_chinese_hour = str_chinese_hour + '十二'
        } else {
            str_chinese_hour = str_chinese_hour + json_chinese_one_to_ten[String(int_hour - 12)]
        }
    } else {
        str_chinese_hour = '晚上'
        if (int_hour == 23) {
            str_chinese_hour = str_chinese_hour + '十一'
        } else if (int_hour == 24) {
            str_chinese_hour = str_chinese_hour + '十二'
        } else {
            str_chinese_hour = str_chinese_hour + json_chinese_one_to_ten[String(int_hour - 12)]
        }
    }
    if (dateonly) {
        return str_chinese_year + '年' + str_chinese_month + '月' + str_chinese_day + '日'
    } else {
        return str_chinese_year + '年' + str_chinese_month + '月' + str_chinese_day + '日' + str_chinese_hour + '時正'
    }
}
