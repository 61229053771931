import {
    ProCard
} from '@ant-design/pro-components';
import { Button, Checkbox, Col, DatePicker, Divider, Form, Input, Row, Select, Switch, Upload } from 'antd';

import { Layout } from 'antd';
import FormItem from 'antd/es/form/FormItem';
import Title from 'antd/es/typography/Title';
import moment from 'moment';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { applyWithSiblingsOption, genderOption, homeAddressRegionOptions, hongkongDistrictOptions, isSSCAAMembershipOption, kowloonDistrictOptions, motherTongueOfTheApplicantOption, nationalityOption, newterritoriesOptions, religionOption, siblingCollegeGradeOption, siblingPreparatoryGradeOption, siblingYearOption, yearOptions } from '../../../../options/options';
import { within20Years } from '../../../../tools/datafn';
import { ApplicationForm } from '../../../../models/application';
import { useForm } from 'antd/es/form/Form';
import dayjs from 'dayjs';
import photoRequirement from "../../../../assests/images/photo-requirement.png";

const { Header, Content, Footer, Sider } = Layout;

const { Option } = Select;


export function ApplicationFormModifyPage(props: { form?: ApplicationForm, withDocumentUpload?: boolean, defaultModify?: boolean, onUpdate: (applicationForm: ApplicationForm) => void }) {
    const { t } = useTranslation();


    const [f1] = useForm();
    React.useEffect(() => {
        if (props.form) {
            resetForm();
        }
    }, [props.form])
    React.useEffect(() => {
        if (props.defaultModify) {
            setModify(true);
        }
    }, [props.defaultModify])
    function resetForm() {
        if (props.form) {
            setApplicantPositionAmongSiblings(props.form.applicantPositionAmongSiblings)
            setApplyWithSiblings(props.form.applyWithSiblings)
            setDateOfBirth(dayjs(props.form.dateOfBirth))
            setEmail(props.form.email)
            setFatherCampusCollege(props.form.fatherCampusCollege)
            setFatherCampusPreparatorySchool(props.form.fatherCampusPreparatorySchool)
            setFatherChineseName(props.form.fatherChineseName)
            setFatherEnglishName(props.form.fatherEnglishName)
            setFatherIDCardPassport(props.form.fatherIDCardPassport)
            setFatherInformation(props.form.fatherInformation)
            setFatherIsSSCAAMembership(props.form.fatherIsSSCAAMembership)
            setFatherOccupation(props.form.fatherOccupation)
            setFatherOfficeAddress(props.form.fatherOfficeAddress)
            setFatherSSCAAMembershipNumber(props.form.fatherSSCAAMembershipNumber)
            setFatherTelephoneMobile(props.form.fatherTelephoneMobile)
            setFatherTelephoneOffice(props.form.fatherTelephoneOffice)
            setFatherTitle(props.form.fatherTitle)
            setFatherYearOfAttendanceA(props.form.fatherYearOfAttendanceA)
            setFatherYearOfAttendanceB(props.form.fatherYearOfAttendanceB)
            setGender(props.form.gender)
            setGivenName(props.form.givenName)
            setGrade(props.form.grade)
            setGradeAppliedFor(props.form.gradeAppliedFor)
            setHKBirthCertificatePassport(props.form.hkBirthCertificatePassport)
            setHomeAddress(props.form.homeAddress)
            setHomeAddressDistrict(props.form.homeAddressDistrict)
            setHomeAddressRegion(props.form.homeAddressRegion)
            setMotherCampusCollege(props.form.motherCampusCollege)
            setMotherCampusPreparatorySchool(props.form.motherCampusPreparatorySchool)
            setMotherChineseName(props.form.motherChineseName)
            setMotherEnglishName(props.form.motherEnglishName)
            setMotherIDCardPassport(props.form.motherIDCardPassport)
            setMotherInformation(props.form.motherInformation)
            setMotherIsSSCAAMembership(props.form.motherIsSSCAAMembership)
            setMotherOccupation(props.form.motherOccupation)
            setMotherOfficeAddress(props.form.motherOfficeAddress)
            setMotherSSCAAMembershipNumber(props.form.motherSSCAAMembershipNumber)
            setMotherTelephoneMobile(props.form.motherTelephoneMobile)
            setMotherTelephoneOffice(props.form.motherTelephoneOffice)
            setMotherTitle(props.form.motherTitle)
            setMotherTongueOfTheApplicant(props.form.motherTongueOfTheApplicant)
            setMotherTongueOther(props.form.motherTongueOther)
            setMotherYearOfAttendanceA(props.form.motherYearOfAttendanceA)
            setMotherYearOfAttendanceB(props.form.motherYearOfAttendanceB)
            setNameInChinese(props.form.nameInChinese)
            setNationality(props.form.nationality)
            setNationalityOther(props.form.nationalityOther)
            setNoOfSiblings(props.form.noOfSiblings)
            setPlaceOfBirth(props.form.placeOfBirth)
            setPresentSchool(props.form.presentSchool)
            setReligion(props.form.religion)
            setSiblingCampus(props.form.siblingCampus)
            setSiblingCampusCollege(props.form.siblingCampusCollege)
            setSiblingCampusPreparatory(props.form.siblingCampusPreparatory)
            setSiblingYearOfAttendA(props.form.siblingYearOfAttendA)
            setSiblingYearOfAttendB(props.form.siblingYearOfAttendB)
            setSurname(props.form.surname)
            setTelephone(props.form.telephone)
            f1.setFieldsValue({ ...props.form, dateOfBirth: dayjs(props.form.dateOfBirth) });
        }
    }
    const [gradeOption, setGradeOption] = React.useState<{ value: string, label: string }[]>([
        { value: "G1", label: t("G1") },
        { value: "G2", label: t("G2") },
        { value: "G3", label: t("G3") },
        { value: "G4", label: t("G4") },
        { value: "G5", label: t("G5") },
        { value: "G6", label: t("G6") }
    ]);

    // 1. Documentation of Studnet
    const [birthCertificate, setBirthCerticate] = React.useState("");
    const [birthCertificateFileType, setBirthCerticateFileType] = React.useState("");
    const [hkIDPassportCopy, setHKIDPassportCopy] = React.useState("");
    const [hkIDPassportCopyFileType, setHKIDPassportCopyFileType] = React.useState("");
    const [applicantPhoto, setApplicantPhoto] = React.useState("");
    const [applicantPhotoFileType, setApplicantPhotoFileType] = React.useState("");
    const [bankReceiptCopy, setBankReceiptCopy] = React.useState("");
    const [bankReceiptCopyFileType, setBankReceiptCopyFileType] = React.useState("");

    const [bankReferenceNumber, setBankReferenceNumber] = React.useState("");
    let today = new Date();
    const dd = String(today.getDate()).padStart(2, '0');
    const mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
    const yyyy = today.getFullYear();
    const todayDDMMYYY = dd + '/' + mm + '/' + yyyy;
    const [dateOfApplication, setDateOfApplication] = React.useState(todayDDMMYYY);

    // 2. Student's Information
    const [modify, setModify] = React.useState(false);
    const [surname, setSurname] = React.useState("");
    const [givenName, setGivenName] = React.useState("");
    const [nameInChinese, setNameInChinese] = React.useState("");
    const [nationality, setNationality] = React.useState("");
    const [nationalityOther, setNationalityOther] = React.useState("");
    const [gender, setGender] = React.useState("");
    const [dateOfBirth, setDateOfBirth] = React.useState(dayjs());
    const [placeOfBirth, setPlaceOfBirth] = React.useState("");
    const [hkBirthCertificatePassport, setHKBirthCertificatePassport] = React.useState("");

    const [homeAddressRegion, setHomeAddressRegion] = React.useState("");
    const [homeAddressDistrict, setHomeAddressDistrict] = React.useState("");
    const [homeAddress, setHomeAddress] = React.useState("");
    const [telephone, setTelephone] = React.useState("");
    const [email, setEmail] = React.useState("");

    const [religion, setReligion] = React.useState("");
    const [noOfSiblings, setNoOfSiblings] = React.useState(1);
    const [applicantPositionAmongSiblings, setApplicantPositionAmongSiblings] = React.useState<number>(1);
    const [applyWithSiblings, setApplyWithSiblings] = React.useState(false);
    const [gradeAppliedFor, setGradeAppliedFor] = React.useState("");
    const [motherTongueOfTheApplicant, setMotherTongueOfTheApplicant] = React.useState("");
    const [motherTongueOther, setMotherTongueOther] = React.useState("");
    const [presentSchool, setPresentSchool] = React.useState("");
    const [yearsAttendedA, setYearsAttendedA] = React.useState("");
    const [yearsAttendedB, setYearsAttendedB] = React.useState("");

    const [siblingNameOfAttend, setSiblingNameOfAttend] = React.useState("");
    const [siblingYearOfAttendA, setSiblingYearOfAttendA] = React.useState("");
    const [siblingYearOfAttendB, setSiblingYearOfAttendB] = React.useState("");
    const [siblingCampus, setSiblingCampus] = React.useState("");
    const [siblingCampusPreparatory, setSiblingCampusPreparatory] = React.useState(false);
    const [siblingCampusCollege, setSiblingCampusCollege] = React.useState(false);
    const [grade, setGrade] = React.useState("");

    // 3. Parents' Information
    const [fatherInformation, setFatherInformation] = React.useState(true);
    const [fatherEnglishName, setFatherEnglishName] = React.useState("");
    const [fatherChineseName, setFatherChineseName] = React.useState("");
    const [fatherIDCardPassport, setFatherIDCardPassport] = React.useState("");
    const [fatherOccupation, setFatherOccupation] = React.useState("");
    const [fatherTitle, setFatherTitle] = React.useState("");
    const [fatherTelephoneOffice, setFatherTelephoneOffice] = React.useState("");
    const [fatherTelephoneMobile, setFatherTelephoneMobile] = React.useState("");
    const [fatherOfficeAddress, setFatherOfficeAddress] = React.useState("");
    const [fatherYearOfAttendanceA, setFatherYearOfAttendanceA] = React.useState("");
    const [fatherYearOfAttendanceB, setFatherYearOfAttendanceB] = React.useState("");
    const [fatherYearOfAttendance, setFatherYearOfAttendance] = React.useState("");
    const [fatherCampusPreparatorySchool, setFatherCampusPreparatorySchool] = React.useState(false);
    const [fatherCampusCollege, setFatherCampusCollege] = React.useState(false);
    const [fatherIsSSCAAMembership, setFatherIsSSCAAMembership] = React.useState(false);
    const [fatherSSCAAMembershipNumber, setFatherSSCAAMembershipNumber] = React.useState("");

    const [motherInformation, setMotherInformation] = React.useState(true);
    const [motherEnglishName, setMotherEnglishName] = React.useState("");
    const [motherChineseName, setMotherChineseName] = React.useState("");
    const [motherIDCardPassport, setMotherIDCardPassport] = React.useState("");
    const [motherOccupation, setMotherOccupation] = React.useState("");
    const [motherTitle, setMotherTitle] = React.useState("");
    const [motherTelephoneOffice, setMotherTelephoneOffice] = React.useState("");
    const [motherTelephoneMobile, setMotherTelephoneMobile] = React.useState("");
    const [motherOfficeAddress, setMotherOfficeAddress] = React.useState("");
    const [motherYearOfAttendanceA, setMotherYearOfAttendanceA] = React.useState("");
    const [motherYearOfAttendanceB, setMotherYearOfAttendanceB] = React.useState("");
    const [motherYearOfAttendance, setMotherYearOfAttendance] = React.useState("");
    const [motherCampusPreparatorySchool, setMotherCampusPreparatorySchool] = React.useState(false);
    const [motherCampusCollege, setMotherCampusCollege] = React.useState(false);
    const [motherIsSSCAAMembership, setMotherIsSSCAAMembership] = React.useState(false);
    const [motherSSCAAMembershipNumber, setMotherSSCAAMembershipNumber] = React.useState("");

    return (
        <>
            <Content style={{ display: "flex", alignContent: "space-between", marginBottom: 30 }}>
                <p style={{ display: "flex", flex: 1, fontStyle: "italic" }}>{t("*If the form is not loaded correctly, please press the Reset button")}</p>
                <Button.Group>
                    {modify && <Button onClick={() => {
                        setModify(false);
                        f1.submit();
                    }} type='primary'>{t("Save & Update")}</Button>}
                    {!modify && <Button onClick={() => {
                        setModify(true);
                    }}>{t("Edit")}</Button>}
                    <Button onClick={() => {
                        resetForm();
                        setModify(false);
                    }} >{t("Reset")}</Button>
                </Button.Group>
            </Content>
            <Content >
                <Form disabled={!modify} layout="vertical" form={f1}
                    onFinish={(values) => {
                        values.dateOfBirth = values.dateOfBirth.format("YYYY-MM-DD")
                        props.onUpdate(values);
                    }}
                    initialValues={{
                        surname,
                        givenName,
                        nameInChinese,
                        gender,
                        nationality,
                        nationalityOther,
                        dateOfBirth,
                        placeOfBirth,
                        hkBirthCertificatePassport,
                        homeAddressRegion,
                        homeAddressDistrict,
                        homeAddress,
                        telephone,
                        email,
                        religion,
                        gradeAppliedFor,
                        motherTongueOfTheApplicant,
                        motherTongueOther,
                        presentSchool,
                        yearsAttendedA,
                        yearsAttendedB,
                        noOfSiblings,
                        applicantPositionAmongSiblings,
                        applyWithSiblings,
                        siblingNameOfAttend,
                        siblingYearOfAttendA,
                        siblingYearOfAttendB,
                        grade,
                        fatherInformation,
                        fatherEnglishName,
                        fatherChineseName,
                        fatherIDCardPassport,
                        fatherOccupation,
                        fatherTitle,
                        fatherTelephoneOffice,
                        fatherTelephoneMobile,
                        fatherOfficeAddress,
                        fatherYearOfAttendanceA,
                        fatherYearOfAttendanceB,
                        fatherYearOfAttendance,
                        fatherCampusPreparatorySchool,
                        fatherCampusCollege,
                        fatherIsSSCAAMembership,
                        fatherSSCAAMembershipNumber,
                        motherInformation,
                        motherEnglishName,
                        motherChineseName,
                        motherIDCardPassport,
                        motherOccupation,
                        motherTitle,
                        motherTelephoneOffice,
                        motherTelephoneMobile,
                        motherOfficeAddress,
                        motherYearOfAttendanceA,
                        motherYearOfAttendanceB,
                        motherYearOfAttendance,
                        motherCampusPreparatorySchool,
                        motherCampusCollege,
                        motherIsSSCAAMembership,
                        motherSSCAAMembershipNumber
                    }}>
                    {props.withDocumentUpload && <Row gutter={16}>
                        <Col span={24}>
                            <ol>
                                <li style={{ marginBottom: "1em", borderBottom: "1px solid #eee", paddingBottom: "1em" }}>
                                    <Form.Item
                                        name="birthCertificate"
                                        label={t("Birth Certificate")}
                                        valuePropName="fileList"
                                        rules={[
                                            { required: true, message: t("Please upload Birth Certificate") },
                                        ]}
                                    >
                                        <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", flexWrap: "wrap" }}>
                                            <div style={{ flex: 1 }}>
                                                <Title level={5}>{t("Birth Certificate")}</Title>
                                                {(birthCertificate) && birthCertificateFileType.startsWith("application") && <iframe
                                                    id="birthCertificateFrame"
                                                    src={`data:${birthCertificateFileType};base64,${birthCertificate}`}
                                                    style={{ width: "100%", height: 500 }}
                                                    width="" height=""></iframe>}
                                                {(birthCertificate) && birthCertificateFileType.startsWith("image") && <img style={{ width: "100%", maxWidth: 500 }} src={`data:${birthCertificateFileType};base64,${birthCertificate}`} />}
                                            </div>
                                            <div style={{ display: "flex", flexDirection: "row", alignItems: "center", textAlign: "center", justifyContent: "center" }}>
                                                <Upload fileList={[]} maxCount={1} multiple={false} accept=".png, .jpg, .jpeg, .pdf" beforeUpload={(file, fileList) => {
                                                    if (file) {
                                                        if (file.type == "application/pdf" || file.type == "image/png" || file.type == "image/jpeg") {
                                                            file.arrayBuffer().then((buffer) => {
                                                                let bytes = btoa(new Uint8Array(buffer).reduce((data, byte) => {
                                                                    return data + String.fromCharCode(byte);
                                                                }, ''));
                                                                //let base64 = window.btoa( bytes )
                                                                setBirthCerticate(bytes);
                                                                setBirthCerticateFileType(file.type)
                                                            })
                                                        }
                                                    }
                                                    return false;
                                                }}>
                                                    <Button shape="round" size="large" >{t("Upload")}</Button>
                                                </Upload>
                                            </div>
                                        </div>
                                    </Form.Item>
                                </li>
                                <li style={{ marginBottom: "1em", borderBottom: "1px solid #eee", paddingBottom: "1em" }}>
                                    <Form.Item
                                        name="hkIDPassportCopy"
                                        label={t("HKID/Passport Copy")}
                                        valuePropName="fileList"
                                        rules={[
                                            { required: true, message: t("Please upload HKID/Passport Copy") },
                                        ]}
                                    >
                                        <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", flexWrap: "wrap" }}>
                                            <div style={{ flex: 1 }}>
                                                <Title level={5}>{t("HKID/Passport Copy")}</Title>
                                                <p>{t("For applicants born overseas, please submit passport or travel document with the page containing 'Permitted date' chop information.")}</p>
                                                {(hkIDPassportCopy) && hkIDPassportCopyFileType.startsWith("application") && <iframe
                                                    id="hkIDPassportCopyFrame"
                                                    src={`data:${hkIDPassportCopyFileType};base64,${hkIDPassportCopy}`}
                                                    style={{ width: "100%", height: 500 }}
                                                    width="" height=""></iframe>}
                                                {(hkIDPassportCopy) && hkIDPassportCopyFileType.startsWith("image") && <img style={{ width: "100%", maxWidth: 500 }} src={`data:${hkIDPassportCopyFileType};base64,${hkIDPassportCopy}`} />}
                                            </div>
                                            <div style={{ display: "flex", flexDirection: "row", alignItems: "center", textAlign: "center", justifyContent: "center" }}>
                                                <Upload fileList={[]} maxCount={1} multiple={false} accept=".png, .jpg, .jpeg, .pdf" beforeUpload={(file, fileList) => {
                                                    if (file) {
                                                        if (file.type == "application/pdf" || file.type == "image/png" || file.type == "image/jpeg") {
                                                            file.arrayBuffer().then((buffer) => {

                                                                let bytes = btoa(new Uint8Array(buffer).reduce((data, byte) => {
                                                                    return data + String.fromCharCode(byte);
                                                                }, ''));
                                                                //let base64 = window.btoa( bytes )
                                                                setHKIDPassportCopy(bytes);
                                                                setHKIDPassportCopyFileType(file.type)
                                                            })
                                                        }
                                                    }
                                                    return false;
                                                }}>
                                                    <Button shape="round" size="large" >{t("Upload")}</Button>
                                                </Upload>
                                            </div>
                                        </div>
                                    </Form.Item>
                                </li>
                                <li style={{ marginBottom: "1em", borderBottom: "1px solid #eee", paddingBottom: "1em" }}>
                                    <Form.Item
                                        name="applicantPhoto"
                                        label={t("Applicant's Photo")}
                                        valuePropName="fileList"
                                        rules={[
                                            { required: true, message: t("Please upload Applicant's Photo") },
                                        ]}
                                    >
                                        <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", flexWrap: "wrap" }}>
                                            <div style={{ flex: 1 }}>
                                                <Title level={5}>{t("Applicant's Photo")}</Title>
                                                <p>{t("Photo Requirements")}</p>
                                                <div>
                                                    <ul>
                                                        <li>{t("Color")}</li>
                                                        <li>{t("Photo Format: JPEG")}</li>
                                                        <li>{t("Photo Size: 40mm (width) * 50 mm (height)")}</li>
                                                        <li>{t("The photograh should show the full frontal face of the student with clear facial features.")}</li>
                                                    </ul>
                                                    <img style={{ maxWidth: 300, width: "100%", padding: "20px 0" }} src={photoRequirement} />

                                                </div>
                                                {(applicantPhoto) && <img
                                                    id="applicantPhotoFrame"
                                                    src={`data:${applicantPhotoFileType};base64,${applicantPhoto}`}
                                                    style={{ width: "100%", maxWidth: 500 }} />}

                                            </div>
                                            <div style={{ display: "flex", flexDirection: "row", alignItems: "center", textAlign: "center", justifyContent: "center" }}>
                                                <Upload fileList={[]} maxCount={1} multiple={false} accept=".png, .jpg, .jpeg" beforeUpload={(file, fileList) => {
                                                    if (file) {
                                                        if (file.type == "image/png" || file.type == "image/jpeg") {
                                                            file.arrayBuffer().then((buffer) => {
                                                                let bytes = btoa(new Uint8Array(buffer).reduce((data, byte) => {
                                                                    return data + String.fromCharCode(byte);
                                                                }, ''));
                                                                setApplicantPhoto(bytes);
                                                                setApplicantPhotoFileType(file.type)
                                                            })
                                                        }
                                                    }
                                                    return false;
                                                }}>
                                                    <Button shape="round" size="large" >{t("Upload")}</Button>
                                                </Upload>
                                            </div>
                                        </div>
                                    </Form.Item>
                                </li>
                                <li style={{ marginBottom: "1em", borderBottom: "1px solid #eee", paddingBottom: "1em" }}>
                                    <Form.Item
                                        name="bankReceiptCopy"
                                        label={t("Bank Receipt Copy")}
                                        valuePropName="fileList"
                                        rules={[
                                            { required: true, message: t("Please upload Bank Receipt Copy") },
                                        ]}
                                    >
                                        <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", flexWrap: "wrap" }}>
                                            <div style={{ flex: 1 }}>
                                                <Title level={5}>{t("Bank Receipt Copy")}</Title>
                                                <p>{t("Application Fee")}: $200</p>
                                                <p>{t("Please deposit the application fee into the following bank account")}:</p>
                                                <p>{t("HSBC Account")}: 002-245678-004</p>
                                                <p>{t("Account Name")}: ST. STEPHEN'S COLLEGE</p>
                                                <p>{t("Remarks: Acceptable payment methods")}</p>
                                                <ol>
                                                    <li>{t("ATM Transfer")}</li>
                                                    <li>{t("Bank Deposit")}</li>
                                                    <li>{t("Online-transfer")}</li>
                                                </ol>
                                                {(bankReceiptCopy) && bankReceiptCopyFileType.startsWith("application") && <iframe
                                                    id="bankReceiptCopyFrame"
                                                    src={`data:${bankReceiptCopyFileType};base64,${bankReceiptCopy}`}
                                                    style={{ width: "100%", height: 500 }}
                                                    width="" height=""></iframe>}
                                                {(bankReceiptCopy) && bankReceiptCopyFileType.startsWith("image") && <img style={{ width: "100%", maxWidth: 500 }} src={`data:${bankReceiptCopyFileType};base64,${bankReceiptCopy}`} />}
                                                <p style={{ color: "red" }}>* {t("Application fee is irredeemable.")}</p>
                                            </div>
                                            <div style={{ display: "flex", flexDirection: "row", alignItems: "center", textAlign: "center", justifyContent: "center" }}>
                                                <Upload fileList={[]} maxCount={1} multiple={false} accept=".png, .jpg, .jpeg, .pdf" beforeUpload={(file, fileList) => {
                                                    if (file) {
                                                        if (file.type == "application/pdf" || file.type == "image/png" || file.type == "image/jpeg") {
                                                            file.arrayBuffer().then((buffer) => {
                                                                let bytes = btoa(new Uint8Array(buffer).reduce((data, byte) => {
                                                                    return data + String.fromCharCode(byte);
                                                                }, ''));
                                                                setBankReceiptCopy(bytes);
                                                                setBankReceiptCopyFileType(file.type)
                                                                setTimeout(() => {

                                                                }, 1000)
                                                            })
                                                        }
                                                    }
                                                    return false;
                                                }}>
                                                    <Button shape="round" size="large" >{t("Upload")}</Button>
                                                </Upload>
                                            </div>
                                        </div>
                                    </Form.Item>
                                </li>
                            </ol>
                        </Col>


                    </Row>}
                    <Row gutter={16}>
                        <Col className="gutter-row" span={24}>
                            <Form.Item name={"dateOfApplication"} label={t("Date of Application")}>
                                <Input readOnly={true} value={dateOfApplication} />
                            </Form.Item>
                        </Col>
                        <Col className="gutter-row" md={8} sm={24} xs={24}>
                            <Form.Item name={"surname"} label={t("Surname (English)")} rules={[
                                { required: true, message: t("Please enter Surname (English)") },
                                { pattern: /^[a-zA-Z ]+$/, message: t("Please enter Surname (English)") }
                            ]}>
                                <Input />
                            </Form.Item>
                        </Col>
                        <Col className="gutter-row" md={8} sm={24} xs={24}>
                            <Form.Item name={"givenName"} label={t("Given Name (English)")} rules={[
                                { required: true, message: t("Please enter Given Name (English)") },
                                { pattern: /^[a-zA-Z ]+$/, message: t("Please enter Given Name (English)") }
                            ]}>
                                <Input />
                            </Form.Item>
                        </Col>
                        <Col className="gutter-row" md={8} sm={24} xs={24}>
                            <Form.Item name={"nameInChinese"} label={t("Name in Chinese")} rules={[
                                { pattern: /^[\u4E00-\u9FFF]{0,}$/, message: t("Please enter Name in Chinese") }
                            ]}>
                                <Input />
                            </Form.Item>
                        </Col>
                        <Col className="gutter-row" span={24}>
                            <Form.Item name="gender" label={t("Gender")} rules={[{ required: true, message: t("Please enter Gender") }]}>
                                <Select
                                    onChange={(o) => (setGender(o))}
                                    allowClear>
                                    {genderOption.map(option => (
                                        <Option key={`geneder.${option.value}`} value={option.value}>{t(option.label)}</Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col className="gutter-row" span={24}>
                            <Form.Item name="nationality" label={t("Nationality")} rules={[{ required: true, message: t("Please enter Nationality") }]}>
                                <Select
                                    onChange={(o) => (setNationality(o))}
                                    allowClear>
                                    {nationalityOption.map(option => (
                                        <Option key={`nationalityOption.${option.value}`} value={option.value}>{t(option.label)}</Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                        {nationality === 'Other 其他' && (
                            <Col className="gutter-row" span={24}>
                                <Form.Item name="nationalityOther" label={t("Other Nationality")} rules={[{ required: true, message: t("Please enter Other Nationality") }]}>
                                    <Input />
                                </Form.Item>
                            </Col>
                        )}
                        <Col className="gutter-row" span={24}>
                            <Form.Item name="dateOfBirth" label={t("Date of Birth")} rules={[{ required: true, message: t("Please enter Date of Birth") }]}>
                                <DatePicker disabledDate={within20Years} style={{ width: "100%" }} placeholder='' />
                            </Form.Item>
                        </Col>
                        <Col className="gutter-row" span={24}>
                            <Form.Item name={"placeOfBirth"} label={t("Place of Birth")} rules={[{ required: true, message: t("Please enter Place of Birth") }]}>
                                <Input />
                            </Form.Item>
                        </Col>
                        <Col className="gutter-row" span={24}>
                            <Form.Item name={"hkBirthCertificatePassport"} label={t("HK Birth Certificate/Passport No.")} rules={[{ required: true, message: t("Please enter HK Birth Certificate/Passport No.") }]}>
                                <Input />
                            </Form.Item>
                        </Col>
                        <Col className="gutter-row" span={12} >
                            <Form.Item name={"homeAddressRegion"} label={t("Home Address Region")} rules={[{ required: true, message: t("Please enter Home Address Region") }]}>
                                <Select
                                    value={homeAddressRegion}
                                    onChange={o => {

                                        setHomeAddressRegion(o);
                                        f1.setFieldValue("homeAddressDistrict", "")
                                    }}
                                    allowClear>
                                    {homeAddressRegionOptions.map(homeAddressRegion => (
                                        <Option key={`homeAddressRegion.${homeAddressRegion.value}`} value={homeAddressRegion.value}>{t(homeAddressRegion.label)}</Option>
                                    ))}
                                </Select>

                            </Form.Item>
                        </Col>
                        <Col className="gutter-row" span={12} >
                            <Form.Item name={"homeAddressDistrict"} label={t("Home Address District")} rules={[{ required: true, message: t("Please enter Home Address District") }]}>
                                <Select
                                    value={homeAddressDistrict}
                                    allowClear>
                                    {homeAddressRegion == "Hong Kong 香港" && hongkongDistrictOptions.map(homeAddressRegion => (
                                        <Option key={`homeAddressDistrict.${homeAddressRegion.value}`} value={homeAddressRegion.value}>{t(homeAddressRegion.label)}</Option>
                                    ))}
                                    {homeAddressRegion == "Kowloon 九龍" && kowloonDistrictOptions.map(homeAddressRegion => (
                                        <Option key={`homeAddressDistrict.${homeAddressRegion.value}`} value={homeAddressRegion.value}>{t(homeAddressRegion.label)}</Option>
                                    ))}
                                    {homeAddressRegion == "New Territories 新界" && newterritoriesOptions.map(homeAddressRegion => (
                                        <Option key={`homeAddressDistrict.${homeAddressRegion.value}`} value={homeAddressRegion.value}>{t(homeAddressRegion.label)}</Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col className="gutter-row" span={24} >
                            <Form.Item name={"homeAddress"} label={t("Home Address")} rules={[{ required: true, message: t("Please enter Home Address") }]}>
                                <Input />
                            </Form.Item>
                        </Col>
                        <Col className="gutter-row" span={12} >
                            <Form.Item name={"telephone"} label={t("Telephone")} rules={[{ required: true, message: t("Please enter Telephone") }, { pattern: /[0-9 () \- + ]$/, message: t("Please enter Telephone") }]}>
                                <Input type='tel' />
                            </Form.Item>
                        </Col>
                        <Col className="gutter-row" span={12} >
                            <Form.Item name={"email"} label={t("E-mail")} rules={[{ required: true, message: t("Please enter E-mail") }, { pattern: /^[a-zA-Z0-9.!#$%&'*+\/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/, message: t("Please enter E-mail") }]}>
                                <Input type='email' />
                            </Form.Item>
                        </Col>
                        <Col className="gutter-row" span={24} >
                            <Form.Item name={"religion"} label={t("Religion")} rules={[{ required: true, message: t("Please enter Religion") }]}>
                                <Select
                                    allowClear>
                                    {religionOption.map((option) => (
                                        <Option key={`religionOption.${option.value}`} value={option.value}>{t(option.label)}</Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col className="gutter-row" span={24} >
                            <Form.Item name={"gradeAppliedFor"} label={t("Grade Applied For")} rules={[{ required: true, message: t("Please enter Grade Applied For") }]}>
                                <Select
                                    allowClear>
                                    {gradeOption.map((option) => (
                                        <Option key={`gradeOption.${option.value}`} value={option.value}>{t(option.label)}</Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col className="gutter-row" span={24} >
                            <Form.Item name={"motherTongueOfTheApplicant"} label={t("Mother Tongue of the Applicant")} rules={[{ required: true, message: t("Please enter Mother Tongue of the Applicant") }]}>
                                <Select
                                    value={motherTongueOfTheApplicant}
                                    onChange={o => {
                                        setMotherTongueOfTheApplicant(o);
                                    }}
                                    allowClear>
                                    {motherTongueOfTheApplicantOption.map((option) => (
                                        <Option key={`motherTongueOfTheApplicantOption.${option.value}`} value={option.value}>{t(option.label)}</Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                        {motherTongueOfTheApplicant === 'Other 其他' && (
                            <Col className="gutter-row" span={24}>
                                <Form.Item name="motherTongueOther" label={t("Other Mother Tongue")} rules={[{ required: true, message: t("Please enter Other Mother Tongue") }]}>
                                    <Input />
                                </Form.Item>
                            </Col>
                        )}
                        <Col className="gutter-row" span={24}>
                            <Form.Item name="presentSchool" label={t("Present School")} rules={[{ required: true, message: t("Please enter Present School") }]}>
                                <Input />
                            </Form.Item>
                        </Col>
                        <Col className="gutter-row" span={12} >
                            <Form.Item name={"yearsAttendedA"} label={t("Years Attended From")} rules={[
                                { required: true, message: t("Please enter Years Attended From") },
                                ({ getFieldValue }) => ({
                                    validator(rule, value) {
                                        if (parseInt(value) > parseInt(getFieldValue("yearsAttendedB"))) {
                                            return Promise.reject(t("Please enter Years Attended From"));
                                        } else {
                                            return Promise.resolve();
                                        }
                                    }
                                })
                            ]}>
                                <Select
                                    value={yearsAttendedA}
                                    onChange={o => {
                                        setYearsAttendedA(o);
                                    }}
                                    allowClear>
                                    {yearOptions.map((option) => (
                                        <Option key={`yearOptionsA.${option.value}`} value={option.value}>{t(option.label)}</Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col className="gutter-row" span={12} >
                            <Form.Item name={"yearsAttendedB"} label={t("Years Attended To")} rules={[
                                { required: true, message: t("Please enter Years Attended To") },
                                ({ getFieldValue }) => ({
                                    validator(rule, value) {
                                        if (parseInt(value) < parseInt(getFieldValue("yearsAttendedA"))) {
                                            return Promise.reject(t("Please enter Years Attended To"));
                                        } else {
                                            return Promise.resolve();
                                        }
                                    }
                                })
                            ]}>
                                <Select
                                    value={yearsAttendedB}
                                    onChange={o => {
                                        setYearsAttendedB(o);
                                    }}
                                    allowClear>
                                    {yearOptions.map((option) => (
                                        <Option key={`yearOptionsB.${option.value}`} value={option.value}>{t(option.label)}</Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col className="gutter-row" span={12} >
                            <Form.Item name={"noOfSiblings"} label={t("Number of Siblings (Including the Applicant)")} rules={[
                                { required: true, message: t("Please enter Number of Siblings") },
                                { type: 'integer', transform: v => parseInt(v), message: t("Please enter an integer") },
                                ({ getFieldValue }) => ({
                                    validator(rule, value) {
                                        if (parseInt(value) < 1) {
                                            return Promise.reject(t("The minimun number is") + " " + 1);
                                        } else {
                                            return Promise.resolve();
                                        }
                                    }
                                })
                            ]}>
                                <Input />
                            </Form.Item>
                        </Col>
                        <Col className="gutter-row" span={12} >
                            <Form.Item name={"applicantPositionAmongSiblings"} label={t("Applicant's position among Siblings")} rules={[
                                { required: true, message: t("Please enter Applicant's position among Siblings") },
                                { type: 'integer', transform: v => parseInt(v), message: t("Please enter an integer") },
                                ({ getFieldValue }) => ({
                                    validator(rule, value) {
                                        if (parseInt(value) > parseInt(getFieldValue("noOfSiblings"))) {
                                            return Promise.reject(t("The maximun number is") + " " + (parseInt(getFieldValue("noOfSiblings") || 0)));
                                        } else {
                                            return Promise.resolve();
                                        }
                                    }
                                }),
                                ({ getFieldValue }) => ({
                                    validator(rule, value) {
                                        if (parseInt(value) < 1) {
                                            return Promise.reject(t("The minimun number is") + " " + 1);
                                        } else {
                                            return Promise.resolve();
                                        }
                                    }
                                })
                            ]}>
                                <Input />
                            </Form.Item>
                        </Col>
                        <Col className="gutter-row" span={24} >
                            <Form.Item name={"applyWithSiblings"} label={t("Apply with Siblings")} rules={[{ required: true, message: t("Please enter Apply with Siblings") }]}>
                                <Select
                                    value={applyWithSiblings}
                                    onChange={o => {
                                        setApplyWithSiblings(o);
                                    }}
                                    allowClear>
                                    {applyWithSiblingsOption.map((option) => (
                                        <Option key={`applyWithSiblingsOption.${option.value}`} value={option.value}>{t(option.label)}</Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col className="gutter-row" span={24} >
                            <Form.Item name={"siblingNameOfAttend"} label={t("Sibling's Name of Attend (Optional)")}>
                                <Input />
                            </Form.Item>
                        </Col>
                        <Col className="gutter-row" span={12} >
                            <Form.Item name={"siblingYearOfAttendA"}
                                label={t("SiblingYears Attended From (Optional)")} rules={[
                                    ({ getFieldValue }) => ({
                                        validator(rule, value) {
                                            if (parseInt(value) > parseInt(getFieldValue("siblingYearOfAttendB"))) {
                                                return Promise.reject(t("Please enter SiblingYears Attended From"));
                                            } else {
                                                return Promise.resolve();
                                            }
                                        }
                                    })
                                ]}>
                                <Select
                                    value={siblingYearOfAttendA}
                                    onChange={o => {
                                        setSiblingYearOfAttendA(o);
                                    }}
                                    allowClear>
                                    {siblingYearOption.map((option) => (
                                        <Option key={`yearOptionsA.${option.value}`} value={option.value}>{t(option.label)}</Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col className="gutter-row" span={12} >
                            <Form.Item name={"siblingYearOfAttendB"} label={t("Sibling Years Attended To (Optional)")} rules={[
                                ({ getFieldValue }) => ({
                                    validator(rule, value) {
                                        if (parseInt(value) < parseInt(getFieldValue("siblingYearOfAttendA"))) {
                                            return Promise.reject(t("Please enter Sibling Years Attended To"));
                                        } else {
                                            return Promise.resolve();
                                        }
                                    }
                                })
                            ]}>
                                <Select
                                    value={siblingYearOfAttendB}
                                    onChange={o => {
                                        setSiblingYearOfAttendB(o);
                                    }}
                                    allowClear>
                                    {siblingYearOption.map((option) => (
                                        <Option key={`yearOptionsB.${option.value}`} value={option.value}>{t(option.label)}</Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col className='gutter-row' span={24}>
                            <Form.Item label={t("Siblings' Campus (Optional)")}>
                                <Row>
                                    <Col span={8}>
                                        <Checkbox name='siblingCampusPreparatory' onClick={() => {
                                            setSiblingCampusPreparatory(!siblingCampusPreparatory);
                                        }} checked={siblingCampusPreparatory}>
                                            {t("Preparatory School")}
                                        </Checkbox>
                                    </Col>
                                    <Col span={8}>
                                        <Checkbox name='siblingCampusCollege' onClick={() => {
                                            setSiblingCampusCollege(!siblingCampusCollege);
                                        }} checked={siblingCampusCollege} >
                                            {t("College")}
                                        </Checkbox>
                                    </Col>
                                </Row>
                            </Form.Item>
                        </Col>
                        <Col className='gutter-row' span={24}>
                            <Form.Item name={"siblingGrade"} label={t("Siblings' Grade (Optional)")}>
                                <Select
                                    allowClear>
                                    {siblingCampusPreparatory && (
                                        siblingPreparatoryGradeOption.map((option) => (
                                            <Option key={`siblingPreparatoryGradeOption.${option.value}`} value={option.value}>{t(option.label)}</Option>
                                        ))
                                    )}
                                    {siblingCampusCollege && (
                                        siblingCollegeGradeOption.map((option) => (
                                            <Option key={`siblingCollegeGradeOption.${option.value}`} value={option.value}>{t(option.label)}</Option>
                                        ))
                                    )}
                                </Select>
                            </Form.Item>
                        </Col>
                    </Row>

                    {!fatherInformation && !motherInformation && <p style={{ color: "red" }}><b>{t("Please provide at least one parent Information")}</b></p>}
                    <ProCard
                        title={<Title level={5} type={!fatherInformation && !motherInformation ? "danger" : "secondary"}>{t("Father's Information")}</Title>}

                        collapsible
                        collapsibleIconRender={({
                            collapsed: buildInCollapsed,
                        }: {
                            collapsed: boolean;
                        }) => (buildInCollapsed ? <span></span> : <span></span>)}
                        collapsed={!fatherInformation}
                        extra={
                            <FormItem>
                                <Switch
                                    checkedChildren={t("Enable")} unCheckedChildren={t("N/A")}
                                    checked={fatherInformation}
                                    onChange={o => {
                                        setFatherInformation(o);
                                    }}
                                />
                            </FormItem>
                        }
                    >
                        {fatherInformation && <Row gutter={16}>
                            <Col className="gutter-row" md={12} sm={24} xs={24}>
                                <Form.Item name={"fatherEnglishName"} label={t("Father's Name (English)")} rules={[
                                    { required: true, message: t("Please enter Father's Name (English)") },
                                    { pattern: /^[a-zA-Z ]+$/, message: t("Please enter Father's Name (English)") }
                                ]}>
                                    <Input />
                                </Form.Item>
                            </Col>
                            <Col className="gutter-row" md={12} sm={24} xs={24}>
                                <Form.Item name={"fatherChineseName"} label={t("Father's Name (Chinese)")} rules={[
                                    { pattern: /^[\u4E00-\u9FFF]{0,}$/, message: t("Please enter Father's Name (Chinese)") }
                                ]}>
                                    <Input />
                                </Form.Item>
                            </Col>
                            <Col className="gutter-row" span={24}>
                                <Form.Item name={"fatherIDCardPassport"} label={t("Father's ID Card/Passport No.")} rules={[
                                    { required: true, message: t("Please enter Father's ID Card/Passport No.") },
                                ]}>
                                    <Input />
                                </Form.Item>
                            </Col>
                            <Col className="gutter-row" md={12} sm={24} xs={24}>
                                <Form.Item name={"fatherOccupation"} label={t("Father's Occupation")} rules={[
                                    { required: true, message: t("Please enter Father's Occupation") },
                                ]}>
                                    <Input />
                                </Form.Item>
                            </Col>
                            <Col className="gutter-row" md={12} sm={24} xs={24}>
                                <Form.Item name={"fatherTitle"} label={t("Father's Title")} rules={[
                                    { required: true, message: t("Please enter Father's Title") },
                                ]}>
                                    <Input />
                                </Form.Item>
                            </Col>
                            <Col className="gutter-row" md={12} sm={24} xs={24}>
                                <Form.Item name={"fatherTelephoneOffice"} label={t("Father's Telephone (Office)")}
                                    rules={[{ pattern: /[0-9 () \- + ]$/, message: t("Please enter Father's Telephone (Office)") }
                                    ]}>
                                    <Input type='tel' />
                                </Form.Item>
                            </Col>
                            <Col className="gutter-row" md={12} sm={24} xs={24}>
                                <Form.Item name={"fatherTelephoneMobile"} label={t("Father's Telephone (Mobile)")}
                                    rules={[{ required: true, message: t("Please enter Father's Telephone (Mobile)") },
                                    { pattern: /[0-9 () \- + ]$/, message: t("Please enter Father's Telephone (Mobile)") }]}>
                                    <Input type='tel' />
                                </Form.Item>
                            </Col>
                            <Col className="gutter-row" md={24} sm={24} xs={24}>
                                <Form.Item name={"fatherOfficeAddress"} label={t("Father's Office Address")}
                                    rules={[{ required: true, message: t("Please enter Father's Office Address") }
                                    ]}>
                                    <Input />
                                </Form.Item>
                            </Col>
                            <Col className="gutter-row" md={12} sm={24} xs={24}>
                                <Form.Item name={"fatherYearOfAttendanceA"} label={t("Father's Year of Attendance From")}
                                    rules={[
                                        ({ getFieldValue }) => ({
                                            validator(rule, value) {
                                                if (value != "") {
                                                    let num = parseInt(value);
                                                    if (isNaN(num) || num < 1800 || num > new Date().getFullYear()) {
                                                        return Promise.reject(t("Please enter a valid year"));
                                                    } else if (parseInt(value) > parseInt(getFieldValue("fatherYearOfAttendanceB"))) {
                                                        return Promise.reject(t("Please enter Father's Year of Attendance From"));
                                                    }
                                                }
                                                return Promise.resolve();
                                            }
                                        })
                                    ]}>
                                    <Input maxLength={4} />
                                </Form.Item>
                            </Col>
                            <Col className="gutter-row" md={12} sm={24} xs={24}>
                                <Form.Item name={"fatherYearOfAttendanceB"} label={t("Father's Year of Attendance To")}
                                    rules={[
                                        ({ getFieldValue }) => ({
                                            validator(rule, value) {
                                                if (value != "") {
                                                    let num = parseInt(value);
                                                    if (isNaN(num) || num < 1800 || num > new Date().getFullYear()) {
                                                        return Promise.reject(t("Please enter a valid year"));
                                                    } else if (parseInt(value) < parseInt(getFieldValue("fatherYearOfAttendanceA"))) {
                                                        return Promise.reject(t("Please enter Father's Year of Attendance To"));
                                                    }
                                                }
                                                return Promise.resolve();
                                            }
                                        })
                                    ]}
                                >
                                    <Input maxLength={4} />
                                </Form.Item>
                            </Col>
                            <Col className='gutter-row' span={24}>
                                <Form.Item label={t("Father Campus (Optional)")}>
                                    <Row>
                                        <Col span={8}>
                                            <Checkbox name='fatherCampusPreparatorySchool' onClick={() => {
                                                setFatherCampusPreparatorySchool(!fatherCampusPreparatorySchool);
                                            }} checked={fatherCampusPreparatorySchool}>
                                                {t("Preparatory School")}
                                            </Checkbox>
                                        </Col>
                                        <Col span={8}>
                                            <Checkbox name='fatherCampusCollege' onClick={() => {
                                                setFatherCampusCollege(!fatherCampusCollege);
                                            }} checked={fatherCampusCollege} >
                                                {t("College")}
                                            </Checkbox>
                                        </Col>
                                    </Row>
                                </Form.Item>
                            </Col>
                            <Col className="gutter-row" md={12} sm={24} xs={24}>
                                <Form.Item name={"fatherIsSSCAAMembership"} label={t("SSCAA Membership")}>
                                    <Select
                                        value={fatherIsSSCAAMembership}
                                        onChange={o => {
                                            setFatherIsSSCAAMembership(o);
                                        }}
                                        allowClear>
                                        {isSSCAAMembershipOption.map((option) => (
                                            <Option key={`fatherIsSSCAAMembershipOption.${option.value}`} value={option.value}>{t(option.label)}</Option>
                                        ))}
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col className="gutter-row" md={12} sm={24} xs={24}>
                                <Form.Item required={fatherIsSSCAAMembership} name={"fatherSSCAAMembershipNumber"} label={t("SSCAA Membership Number")}
                                    rules={[{ required: fatherIsSSCAAMembership, message: t("Please enter SSCAA Membership Number") }
                                    ]}>
                                    <Input disabled={!fatherIsSSCAAMembership} />
                                </Form.Item>
                            </Col>
                        </Row>}
                    </ProCard>
                    <Divider />
                    <ProCard
                        title={<Title level={5} type={!fatherInformation && !motherInformation ? "danger" : "secondary"}>{t("Mother's Information")}</Title>}
                        collapsible
                        collapsibleIconRender={({
                            collapsed: buildInCollapsed,
                        }: {
                            collapsed: boolean;
                        }) => (buildInCollapsed ? <span></span> : <span></span>)}
                        collapsed={!motherInformation}
                        extra={
                            <FormItem>
                                <Switch
                                    checkedChildren={t("Enable")} unCheckedChildren={t("N/A")}
                                    checked={motherInformation}
                                    onChange={o => {
                                        setMotherInformation(o);
                                    }}
                                />
                            </FormItem>
                        }
                    >
                        {motherInformation && <Row gutter={16}>
                            <Col className="gutter-row" md={12} sm={24} xs={24}>
                                <Form.Item name={"motherEnglishName"} label={t("Mother's Name (English)")} rules={[
                                    { required: true, message: t("Please enter Mother's Name (English)") },
                                    { pattern: /^[a-zA-Z ]+$/, message: t("Please enter Mother's Name (English)") }
                                ]}>
                                    <Input />
                                </Form.Item>
                            </Col>
                            <Col className="gutter-row" md={12} sm={24} xs={24}>
                                <Form.Item name={"motherChineseName"} label={t("Mother's Name (Chinese)")} rules={[
                                    { pattern: /^[\u4E00-\u9FFF]{0,}$/, message: t("Please enter Mother's Name (Chinese)") }
                                ]}>
                                    <Input />
                                </Form.Item>
                            </Col>
                            <Col className="gutter-row" span={24}>
                                <Form.Item name={"motherIDCardPassport"} label={t("Mother's ID Card/Passport No.")} rules={[
                                    { required: true, message: t("Please enter Mother's ID Card/Passport No.") },
                                ]}>
                                    <Input />
                                </Form.Item>
                            </Col>
                            <Col className="gutter-row" md={12} sm={24} xs={24}>
                                <Form.Item name={"motherOccupation"} label={t("Mother's Occupation")} rules={[
                                    { required: true, message: t("Please enter Mother's Occupation") },
                                ]}>
                                    <Input />
                                </Form.Item>
                            </Col>
                            <Col className="gutter-row" md={12} sm={24} xs={24}>
                                <Form.Item name={"motherTitle"} label={t("Mother's Title")} rules={[
                                    { required: true, message: t("Please enter Mother's Title") },
                                ]}>
                                    <Input />
                                </Form.Item>
                            </Col>
                            <Col className="gutter-row" md={12} sm={24} xs={24}>
                                <Form.Item name={"motherTelephoneOffice"} label={t("Mother's Telephone (Office)")}
                                    rules={[{ pattern: /[0-9 () \- + ]$/, message: t("Please enter Mother's Telephone (Office)") }
                                    ]}>
                                    <Input type='tel' />
                                </Form.Item>
                            </Col>
                            <Col className="gutter-row" md={12} sm={24} xs={24}>
                                <Form.Item name={"motherTelephoneMobile"} label={t("Mother's Telephone (Mobile)")}
                                    rules={[{ required: true, message: t("Please enter Mother's Telephone (Mobile)") },
                                    { pattern: /[0-9 () \- + ]$/, message: t("Please enter Mother's Telephone (Mobile)") }]}>
                                    <Input type='tel' />
                                </Form.Item>
                            </Col>
                            <Col className="gutter-row" md={24} sm={24} xs={24}>
                                <Form.Item name={"motherOfficeAddress"} label={t("Mother's Office Address")}
                                    rules={[{ required: true, message: t("Please enter Mother's Office Address") }
                                    ]}>
                                    <Input />
                                </Form.Item>
                            </Col>
                            <Col className="gutter-row" md={12} sm={24} xs={24}>
                                <Form.Item name={"motherYearOfAttendanceA"} label={t("Mother's Year of Attendance From")}
                                    rules={[
                                        ({ getFieldValue }) => ({
                                            validator(rule, value) {
                                                if (value != "") {
                                                    let num = parseInt(value);
                                                    if (isNaN(num) || num < 1800 || num > new Date().getFullYear()) {
                                                        return Promise.reject(t("Please enter a valid year"));
                                                    } else if (parseInt(value) > parseInt(getFieldValue("motherYearOfAttendanceB"))) {
                                                        return Promise.reject(t("Please enter a valid year"));
                                                    }
                                                }
                                                return Promise.resolve();
                                            }
                                        })
                                    ]}>
                                    <Input maxLength={4} />
                                </Form.Item>
                            </Col>
                            <Col className="gutter-row" md={12} sm={24} xs={24}>
                                <Form.Item name={"motherYearOfAttendanceB"} label={t("Mother's Year of Attendance To")}
                                    rules={[
                                        ({ getFieldValue }) => ({
                                            validator(rule, value) {
                                                if (value != "") {
                                                    let num = parseInt(value);
                                                    if (isNaN(num) || num < 1800 || num > new Date().getFullYear()) {
                                                        return Promise.reject(t("Please enter a valid year"));
                                                    } else if (parseInt(value) < parseInt(getFieldValue("motherYearOfAttendanceA"))) {
                                                        return Promise.reject(t("Please enter a valid year"));
                                                    }
                                                }
                                                return Promise.resolve();
                                            }
                                        })
                                    ]}
                                >
                                    <Input maxLength={4} />
                                </Form.Item>
                            </Col>
                            <Col className='gutter-row' span={24}>
                                <Form.Item label={t("Mother Campus (Optional)")}>
                                    <Row>
                                        <Col span={8}>
                                            <Checkbox name='motherCampusPreparatorySchool' onClick={() => {
                                                setMotherCampusPreparatorySchool(!motherCampusPreparatorySchool);
                                            }} checked={motherCampusPreparatorySchool}>
                                                {t("Preparatory School")}
                                            </Checkbox>
                                        </Col>
                                        <Col span={8}>
                                            <Checkbox name='motherCampusCollege' onClick={() => {
                                                setMotherCampusCollege(!motherCampusCollege);
                                            }} checked={motherCampusCollege} >
                                                {t("College")}
                                            </Checkbox>
                                        </Col>
                                    </Row>
                                </Form.Item>
                            </Col>
                            <Col className="gutter-row" md={12} sm={24} xs={24}>
                                <Form.Item name={"motherIsSSCAAMembership"} label={t("SSCAA Membership")}>
                                    <Select
                                        value={motherIsSSCAAMembership}
                                        onChange={o => {
                                            setMotherIsSSCAAMembership(o);
                                        }}
                                        allowClear>
                                        {isSSCAAMembershipOption.map((option) => (
                                            <Option key={`motherIsSSCAAMembershipOption.${option.value}`} value={option.value}>{t(option.label)}</Option>
                                        ))}
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col className="gutter-row" md={12} sm={24} xs={24}>
                                <Form.Item required={motherIsSSCAAMembership} name={"motherSSCAAMembershipNumber"} label={t("SSCAA Membership Number")}
                                    rules={[{ required: motherIsSSCAAMembership, message: t("Please enter SSCAA Membership Number") }
                                    ]}>
                                    <Input disabled={!motherIsSSCAAMembership} />
                                </Form.Item>
                            </Col>
                        </Row>}
                    </ProCard>
                </Form>
            </Content>
        </>
    )
}

