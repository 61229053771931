// import getConfig from "next/config"

import axios, { AxiosResponse } from "axios"

import { UserRegistrationForm } from "../models/userRegistration.form"
import apiClient, { handleError } from "./apiClient"
import { Application } from "../models/application"
import { Timeslot } from "../models/timeslot"
import { ApplicationYears } from "../models/applicationYears"

var defaultUser: any = undefined
if (typeof window !== "undefined") {
  // Perform localStorage action
  var usr = localStorage.getItem("user")
  if (usr) defaultUser = JSON.parse(usr)
}


export const applicationService = {
  cloneApplicationGroup,
  getApplicationYearGroupList,
  getFirstRounNonCompleteApplicationsFromYearGroup,
  getSecondRounNonCompleteApplicationsFromYearGroup,
  getApplication,
  getApplicationsFromYearGroup,
  getTimeslotsFromYearGroup,
  getTimeslots,
  getAllTimeslots,
  addTimeslot,
  addTimeslots,
  updateTimeslot,
  deleteTimeslot,
  downloadTimeslotTemplate,
  getEmailTemplatesFromYearGroup,
  getAttachmentTemplateFromYearGroup,
  updateAttachmentTemplate,
  deleteAttachment,
  updateEmailTemplate,
  getSetting,
  migrate,
  updateSetting,
  updateFirstRoundInterviewResult,
  updateSecondRoundInterviewResult,
  updateApplicationForm,
  updateApplicationFormAttachment,
  updateApplicationStatus,
  updateApplicationFirstInterviewDate,
  updateApplicationSecondInterviewDate,
  updateApplicationThirdInterviewDate,
  updateApplicationOfferDate,
  downloadReport,
  downloadTemplate,
  downloadTimeslotApplications,
  importTemplateData,
  sendEmail,
  getEmails,
  applyApplication,
  adminApplyApplication,
  verifyFace,
  getAllAttachements,
  getApplicationOverview,
  newTask,
  test
}
async function applyApplication(groupId: string, form: any) {
  try {
    let response = await apiClient
      .post<{
        status: "OK"
        reference: string
      } | {
        status: "Error",
        msg?: {
          msg: any
        }
        error?: {
          msg: string
          code: string;
        }
      }>(`/application/applyApplication/${groupId}`, form);

    if (response.data.status === 'OK') {
      const user = response.data
      return response.data
    } else {
      return response.data
    }
  } catch (e) {
    return handleError(e);
  }
}
async function adminApplyApplication(groupId: string, form: any) {
  try {
    let response = await apiClient
      .post<{
        status: "OK"
        reference: string
      } | {
        status: "Error",
        msg?: {
          msg: any
        }
        error?: {
          msg: string
          code: string;
        }
      }>(`/admin/applicationYear/applyApplication/${groupId}`, form);

    if (response.data.status === 'OK') {
      const user = response.data
      return response.data
    } else {
      return response.data
    }
  } catch (e) {
    return handleError(e);
  }
}
async function getApplicationOverview() {
  try {
    let response = await apiClient
      .get<{
        status: "OK"
        reference: string
      } | {
        status: "Error",
        msg?: {
          msg: any
        }
        error?: {
          msg: string
          code: string;
        }
      }>(`/admin/getApplicationOverview`);

    if (response.data.status === 'OK') {
      const user = response.data
      return response.data
    } else {
      return response.data
    }
  } catch (e) {
    return handleError(e);
  }
}
async function verifyFace(img: string) {
  try {
    let response = await apiClient
      .post<{
        status: "OK",
        result: {
          status: boolean,
          msg: string
        }
      } | {
        status: "Error",
        error?: {
          msg: string
          code: string;
        }
      }>(`/application/verifyFace`, { img });

    if (response.data.status === 'OK') {
      const user = response.data
      return response.data
    } else {
      return response.data
    }
  } catch (e) {
    return handleError(e);
  }
}
async function cloneApplicationGroup(groupId: string, data: {
  applicationFor: string
  applicationMode: string
  applicationStartDate: number
  applicationEndDate: number
  applicationVisibleStartDate: number
  applicationVisibleEndDate: number
  schoolYear: number
}) {
  try {


    let response = await apiClient
      .post<{
        status: "OK"
      } | {
        status: "Error",
        error?: {
          msg: string
          code: string;
        }
      }>(`/admin/applicationYear/cloneApplicationYearGroup/${groupId}`, data);

    if (response.data.status === 'OK') {
      const user = response.data
      return response.data
    } else {
      return response.data
    }
  } catch (e) {
    return handleError(e);
  }
}
async function getApplicationYearGroupList(args: any) {
  try {

    let consolidArgs = {
      offset: String((args.params.current - 1) * (args.params.pageSize)),
      limit: String(args.take),
      where: args.where
        ? [
          args.where.schoolYear ? `schoolYear:eq:${args.where.schoolYear}` : "",
          args.where.applicationFor
            ? `applicationFor:eq:${args.where.applicationFor}`
            : ""
        ]

          .filter((item) => item !== "")
          .join("|")
        : "",
      order: args.sort ? `${args.sortBy}:${args.order}` : "",
    }
    const searchParams = new URLSearchParams(consolidArgs)
    let response = await apiClient
      .get<{
        status: "OK", reference: {
          result: {
            "schoolYear": number,
            "applicationFor": string,
            "applicationMode": string
            "applicationStartDate": number,
            "applicationEndDate": number,
            "applicationVisibleStartDate": number,
            "applicationVisibleEndDate": number,

          }[],
          recordTotal: number
        }
      } | {
        status: "Error",
        error?: {
          msg: string
          code: string;
        }
      }>(`/admin/applicationYear/getAllYearGroups?${searchParams.toString()}`);

    if (response.data.status === 'OK') {
      const user = response.data

      return response.data
    } else {
      return response.data
    }
  } catch (e) {
    return handleError(e);
  }
}
async function getApplicationsFromYearGroup(groupId: string, args: any) {
  try {

    let consolidArgs = {
      offset: String((args.params.current - 1) * (args.params.pageSize)),
      limit: String(args.take),
      where: args.where
        ? [
          args.where.ApplicationId ? `ApplicationId:eq:${args.where.ApplicationId}` : "",
          args.where["ApplicationForm.email"] ? `ApplicationForm.email:like:${args.where["ApplicationForm.email"]}` : "",
          args.where["ApplicationForm.surname"] ? `ApplicationForm.surname:like:${args.where["ApplicationForm.surname"]}` : "",
          args.where["ApplicationForm.givenName"] ? `ApplicationForm.givenName:like:${args.where["ApplicationForm.givenName"]}` : "",
          args.where["ApplicationForm.gradeAppliedFor"] ? `ApplicationForm.gradeAppliedFor:eq:${args.where["ApplicationForm.gradeAppliedFor"]}` : "",
          args.where["ApplicationForm.nameInChinese"] ? `ApplicationForm.nameInChinese:like:${args.where["ApplicationForm.nameInChinese"]}` : "",
          args.where.Status ? `Status:in:${args.where.Status.join(",")}` : "",
        ]

          .filter((item) => item !== "")
          .join("|")
        : "",
      order: args.sort ? `${args.sortBy}:${args.order}` : "",
    }
    const searchParams = new URLSearchParams(consolidArgs)
    let response = await apiClient
      .get<{
        status: "OK", reference: {
          result: {
            "ApplicationId": string,
            "ApplicationForm.applicantPhoto": string,
            "ApplicationForm.email": string,
            "ApplicationForm.gender": string,
            "ApplicationForm.givenName": string,
            "ApplicationForm.gradeAppliedFor": string,
            "ApplicationForm.telephone": string,
            "ApplicationForm.nameInChinese": string,
            "ApplicationForm.surname": String
            "Status": number
            "ApplyDate": number
          }[],
          recordTotal: number
        }
      } | {
        status: "Error",
        error?: {
          msg: string
          code: string;
        }
      }>(`/admin/applicationYear/getApplicationsInYearGroup/${groupId}?${searchParams.toString()}`);

    if (response.data.status === 'OK') {
      const user = response.data

      return response.data
    } else {
      return response.data
    }
  } catch (e) {
    return handleError(e);
  }
}
async function getFirstRounNonCompleteApplicationsFromYearGroup(groupId: string, args: any) {
  try {

    let consolidArgs = {
      offset: String((args.params.current - 1) * (args.params.pageSize)),
      limit: String(args.take),
      where: args.where
        ? [
          args.where.ApplicationId ? `ApplicationId:eq:${args.where.ApplicationId}` : "",
          args.where["ApplicationForm.email"] ? `ApplicationForm.email:arrayContains:${args.where["ApplicationForm.email"]}` : "",
          args.where["ApplicationForm.surname"] ? `ApplicationForm.surname:eq:${args.where["ApplicationForm.surname"]}` : "",
          args.where["ApplicationForm.givenName"] ? `ApplicationForm.givenName:eq:${args.where["ApplicationForm.givenName"]}` : "",
          args.where["ApplicationForm.gradeAppliedFor"] ? `ApplicationForm.gradeAppliedFor:eq:${args.where["ApplicationForm.gradeAppliedFor"]}` : "",
          args.where["ApplicationForm.nameInChinese"] ? `ApplicationForm.nameInChinese:eq:${args.where["ApplicationForm.nameInChinese"]}` : "",
          args.where.Status ? `Status:in:${args.where.Status.join(",")}` : "",
        ]

          .filter((item) => item !== "")
          .join("|")
        : "",
      order: args.sort ? `${args.sortBy}:${args.order}` : "",
    }
    const searchParams = new URLSearchParams(consolidArgs)
    let response = await apiClient
      .get<{
        status: "OK", reference: {
          result: {
            "ApplicationId": string,
            "ApplicationForm.applicantPhoto": string,
            "ApplicationForm.email": string,
            "ApplicationForm.gender": string,
            "ApplicationForm.givenName": string,
            "ApplicationForm.gradeAppliedFor": string,
            "ApplicationForm.telephone": string,
            "ApplicationForm.nameInChinese": string,
            "ApplicationForm.surname": String
            "Status": number
            "ApplyDate": number
          }[],
          recordTotal: number
        }
      } | {
        status: "Error",
        error?: {
          msg: string
          code: string;
        }
      }>(`/admin/applicationYear/getFirstRoundNonCompleteApplication/${groupId}?${searchParams.toString()}`);

    if (response.data.status === 'OK') {
      const user = response.data

      return response.data
    } else {
      return response.data
    }
  } catch (e) {
    return handleError(e);
  }
}
async function getSecondRounNonCompleteApplicationsFromYearGroup(groupId: string, args: any) {
  try {

    let consolidArgs = {
      offset: String((args.params.current - 1) * (args.params.pageSize)),
      limit: String(args.take),
      where: args.where
        ? [
          args.where.ApplicationId ? `ApplicationId:eq:${args.where.ApplicationId}` : "",
          args.where["ApplicationForm.email"] ? `ApplicationForm.email:arrayContains:${args.where["ApplicationForm.email"]}` : "",
          args.where["ApplicationForm.surname"] ? `ApplicationForm.surname:eq:${args.where["ApplicationForm.surname"]}` : "",
          args.where["ApplicationForm.givenName"] ? `ApplicationForm.givenName:eq:${args.where["ApplicationForm.givenName"]}` : "",
          args.where["ApplicationForm.gradeAppliedFor"] ? `ApplicationForm.gradeAppliedFor:eq:${args.where["ApplicationForm.gradeAppliedFor"]}` : "",
          args.where["ApplicationForm.nameInChinese"] ? `ApplicationForm.nameInChinese:eq:${args.where["ApplicationForm.nameInChinese"]}` : "",
          args.where.Status ? `Status:in:${args.where.Status.join(",")}` : "",
        ]

          .filter((item) => item !== "")
          .join("|")
        : "",
      order: args.sort ? `${args.sortBy}:${args.order}` : "",
    }
    const searchParams = new URLSearchParams(consolidArgs)
    let response = await apiClient
      .get<{
        status: "OK", reference: {
          result: {
            "ApplicationId": string,
            "ApplicationForm.applicantPhoto": string,
            "ApplicationForm.email": string,
            "ApplicationForm.gender": string,
            "ApplicationForm.givenName": string,
            "ApplicationForm.gradeAppliedFor": string,
            "ApplicationForm.telephone": string,
            "ApplicationForm.nameInChinese": string,
            "ApplicationForm.surname": String
            "Status": number
            "ApplyDate": number
          }[],
          recordTotal: number
        }
      } | {
        status: "Error",
        error?: {
          msg: string
          code: string;
        }
      }>(`/admin/applicationYear/getSecondRoundNonCompleteApplication/${groupId}?${searchParams.toString()}`);

    if (response.data.status === 'OK') {
      const user = response.data

      return response.data
    } else {
      return response.data
    }
  } catch (e) {
    return handleError(e);
  }
}
async function getApplication(groupId: string, appId: string) {
  try {

    let response = await apiClient
      .get<{
        status: "OK", reference: Application
      } | {
        status: "Error",
        error?: {
          msg: string
          code: string;
        }
      }>(`/admin/applicationYear/getApplication/${groupId}/${appId}`);

    if (response.data.status === 'OK') {
      const user = response.data
      return response.data
    } else {
      return response.data
    }
  } catch (e) {
    return handleError(e);
  }
}
async function migrate(groupId: string, numberOfRecords: number) {
  try {

    let response = await apiClient
      .post<{
        status: "OK", reference: Application
      } | {
        status: "Error",
        error?: {
          msg: string
          code: string;
        }
      }>(`/admin/applicationYear/migration/${groupId}/`, { numberOfRecords: numberOfRecords });

    if (response.data.status === 'OK') {
      const user = response.data
      return response.data
    } else {
      return response.data
    }
  } catch (e) {
    return handleError(e);
  }
}
async function updateApplicationForm(groupId: string, appId: string, form: any) {
  try {

    let response = await apiClient
      .post<{
        status: "OK", reference: Application
      } | {
        status: "Error",
        error?: {
          msg: string
          code: string;
        }
      }>(`/admin/applicationYear/updateApplicationForm/${groupId}/${appId}`, { ApplicationForm: form });

    if (response.data.status === 'OK') {
      const user = response.data
      return response.data
    } else {
      return response.data
    }
  } catch (e) {
    return handleError(e);
  }
}
async function updateApplicationFormAttachment(groupId: string, appId: string, form: {attachmentType:string, file:string}) {
  try {

    let response = await apiClient
      .post<{
        status: "OK", reference: Application
      } | {
        status: "Error",
        error?: {
          msg: string
          code: string;
        }
      }>(`/admin/applicationYear/updateApplicationFormAttachment/${groupId}/${appId}`, form);

    if (response.data.status === 'OK') {
      const user = response.data
      return response.data
    } else {
      return response.data
    }
  } catch (e) {
    return handleError(e);
  }
}
async function updateApplicationStatus(groupId: string, appId: string, status: any) {
  try {

    let response = await apiClient
      .post<{
        status: "OK", reference: Application
      } | {
        status: "Error",
        error?: {
          msg: string
          code: string;
        }
      }>(`/admin/applicationYear/updateApplicationStatus/${groupId}/${appId}`, { Status: status });

    if (response.data.status === 'OK') {
      const user = response.data
      return response.data
    } else {
      return response.data
    }
  } catch (e) {
    return handleError(e);
  }
}
async function updateApplicationFirstInterviewDate(groupId: string, appId: string, FirstInterviewDate: any) {
  try {

    let response = await apiClient
      .post<{
        status: "OK", reference: Application
      } | {
        status: "Error",
        error?: {
          msg: string
          code: string;
        }
      }>(`/admin/applicationYear/updateApplicationFirstInterviewDate/${groupId}/${appId}`, { FirstInterviewDate: FirstInterviewDate });

    if (response.data.status === 'OK') {
      const user = response.data
      return response.data
    } else {
      return response.data
    }
  } catch (e) {
    return handleError(e);
  }
}
async function updateApplicationSecondInterviewDate(groupId: string, appId: string, SecondInterviewDate: any) {
  try {

    let response = await apiClient
      .post<{
        status: "OK", reference: Application
      } | {
        status: "Error",
        error?: {
          msg: string
          code: string;
        }
      }>(`/admin/applicationYear/updateApplicationSecondInterviewDate/${groupId}/${appId}`, { SecondInterviewDate: SecondInterviewDate });

    if (response.data.status === 'OK') {
      const user = response.data
      return response.data
    } else {
      return response.data
    }
  } catch (e) {
    return handleError(e);
  }
}
async function updateApplicationThirdInterviewDate(groupId: string, appId: string, ThirdInterviewDate: any) {
  try {

    let response = await apiClient
      .post<{
        status: "OK", reference: Application
      } | {
        status: "Error",
        error?: {
          msg: string
          code: string;
        }
      }>(`/admin/applicationYear/updateApplicationThirdInterviewDate/${groupId}/${appId}`, { ThirdInterviewDate: ThirdInterviewDate });

    if (response.data.status === 'OK') {
      const user = response.data
      return response.data
    } else {
      return response.data
    }
  } catch (e) {
    return handleError(e);
  }
}
async function updateApplicationOfferDate(groupId: string, appId: string, OfferDate: any) {
  try {

    let response = await apiClient
      .post<{
        status: "OK", reference: Application
      } | {
        status: "Error",
        error?: {
          msg: string
          code: string;
        }
      }>(`/admin/applicationYear/updateApplicationOfferDate/${groupId}/${appId}`, { OfferDate: OfferDate });

    if (response.data.status === 'OK') {
      const user = response.data
      return response.data
    } else {
      return response.data
    }
  } catch (e) {
    return handleError(e);
  }
}
async function getTimeslotsFromYearGroup(groupId: string, args: any) {
  try {

    let consolidArgs = {
      offset: String((args.params.current - 1) * (args.params.pageSize)),
      limit: String(args.take),
      where: args.where
        ? [
          args.where.capacity ? `capacity:eq:${args.where.capacity}` : "",
          args.where.extraCapacity ? `extraCapacity:eq:${args.where.extraCapacity}` : "",
          args.where.timeslot ? `timeslot:eq:${args.where.timeslot}` : "",
          args.where.timeslotType ? `timeslotType:eq:${args.where.timeslotType}` : ""
        ]

          .filter((item) => item !== "")
          .join("|")
        : "",
      order: args.sort ? `${args.sortBy}:${args.order}` : "",
    }
    const searchParams = new URLSearchParams(consolidArgs)
    let response = await apiClient
      .get<{
        status: "OK", reference: {
          result: {
            "id": string,
            "capacity": number,
            "extraCapacity": number,
            "timeslot": number,
            "timeslotType": number,
            "toTimeslot"?: number,
            "used": number
          }[],
          recordTotal: number
        }
      } | {
        status: "Error",
        error?: {
          msg: string
          code: string;
        }
      }>(`/admin/applicationYear/getTimeslots/${groupId}?${searchParams.toString()}`);

    if (response.data.status === 'OK') {
      const user = response.data

      return response.data
    } else {
      return response.data
    }
  } catch (e) {
    return handleError(e);
  }
}
async function getTimeslots(groupId: string, appId: string) {
  try {

    let response = await apiClient
      .get<{
        status: "OK", reference: Timeslot
      } | {
        status: "Error",
        error?: {
          msg: string
          code: string;
        }
      }>(`/admin/applicationYear/getTimeslot/${groupId}/${appId}`);

    if (response.data.status === 'OK') {
      const user = response.data
      return response.data
    } else {
      return response.data
    }
  } catch (e) {
    return handleError(e);
  }
}
async function getAllTimeslots(groupId: string) {
  try {

    let response = await apiClient
      .get<{
        status: "OK", reference: Timeslot[]
      } | {
        status: "Error",
        error?: {
          msg: string
          code: string;
        }
      }>(`/admin/applicationYear/getAllTimeslots/${groupId}`);

    if (response.data.status === 'OK') {
      const user = response.data
      return response.data
    } else {
      return response.data
    }
  } catch (e) {
    return handleError(e);
  }
}
async function downloadTimeslotTemplate() {
  let response = await apiClient({
    url: '/admin/timeslot/getTimeslotsInputDump', //your url
    method: 'GET',
    responseType: 'blob', // important
  })

  const href = URL.createObjectURL(response.data);

  // create "a" HTML element with href to file & click
  const link = document.createElement('a');
  link.href = href;
  link.setAttribute('download', 'timeslotTemplates.xlsx');
  document.body.appendChild(link);
  link.click();

  // clean up "a" element & remove ObjectURL
  document.body.removeChild(link);
  URL.revokeObjectURL(href);
  return true;
}
async function  downloadTimeslotApplications(groupId: string, timeslotId:string) {
  let response = await apiClient({
    url: `/admin/applicationYear/getTimeslotApplications/${groupId}/${timeslotId}`, //your url
    method: 'GET',
    responseType: 'blob', // important
  })
  const href = URL.createObjectURL(response.data);
  // create "a" HTML element with href to file & click
  const link = document.createElement('a');
  link.href = href;
  link.setAttribute('download', 'applications.xlsx');
  document.body.appendChild(link);
  link.click();
  // clean up "a" element & remove ObjectURL
  document.body.removeChild(link);
  URL.revokeObjectURL(href);
  return true;
}
async function addTimeslot(groupId: string, data: {
  capacity: number
  extraCapacity: number
  timeslot: number
  timeslotType: number
}) {
  try {

    let response = await apiClient
      .post<{
        status: "OK", reference: Timeslot
      } | {
        status: "Error",
        error?: {
          msg: string
          code: string;
        }
      }>(`/admin/applicationYear/addTimeslot/${groupId}`, data);

    if (response.data.status === 'OK') {
      const user = response.data
      return response.data
    } else {
      return response.data
    }
  } catch (e) {
    return handleError(e);
  }
}
async function addTimeslots(groupId: string, data: {
  capacity: number
  extraCapacity: number
  timeslot: number
  timeslotType: number
}[]) {
  try {

    let response = await apiClient
      .post<{
        status: "OK"
      } | {
        status: "Error",
        error?: {
          msg: string
          code: string;
        }
      }>(`/admin/applicationYear/addTimeslots/${groupId}`, data);

    if (response.data.status === 'OK') {
      const user = response.data
      return response.data
    } else {
      return response.data
    }
  } catch (e) {
    return handleError(e);
  }
}
async function updateTimeslot(groupId: string, appId: string, data: {
  capacity: number
  extraCapacity: number
  timeslot: number
  timeslotType: number
}) {
  try {

    let response = await apiClient
      .post<{
        status: "OK", reference: Timeslot
      } | {
        status: "Error",
        error?: {
          msg: string
          code: string;
        }
      }>(`/admin/applicationYear/updateTimeslot/${groupId}/${appId}`);

    if (response.data.status === 'OK') {
      const user = response.data
      return response.data
    } else {
      return response.data
    }
  } catch (e) {
    return handleError(e);
  }
}
async function deleteTimeslot(groupId: string, appId: string) {
  try {

    let response = await apiClient
      .get<{
        status: "OK", reference: Timeslot
      } | {
        status: "Error",
        error?: {
          msg: string
          code: string;
        }
      }>(`/admin/applicationYear/deleteTimeslot/${groupId}/${appId}`);

    if (response.data.status === 'OK') {
      const user = response.data
      return response.data
    } else {
      return response.data
    }
  } catch (e) {
    return handleError(e);
  }
}

async function getEmailTemplatesFromYearGroup(groupId: string) {
  try {


    let response = await apiClient
      .get<{
        status: "OK", reference: {
          result: {
            "id": string,
            "subject": string,
            "body": string,
            attachment: string
          }[],
          recordTotal: number
        }
      } | {
        status: "Error",
        error?: {
          msg: string
          code: string;
        }
      }>(`/admin/applicationYear/getEmailTemplates/${groupId}`);

    if (response.data.status === 'OK') {
      const user = response.data

      return response.data
    } else {
      return response.data
    }
  } catch (e) {
    return handleError(e);
  }
}
async function getAllAttachements(groupId: string) {
  try {


    let response = await apiClient
      .get<{
        status: "OK", reference: {
          "id": string,
          "body": string
        }[]

      } | {
        status: "Error",
        error?: {
          msg: string
          code: string;
        }
      }>(`/admin/applicationYear/getAllAttachmentTemplates/${groupId}`);

    if (response.data.status === 'OK') {
      const user = response.data

      return response.data
    } else {
      return response.data
    }
  } catch (e) {
    return handleError(e);
  }
}
async function getAttachmentTemplateFromYearGroup(groupId: string, args: any) {
  try {

    let consolidArgs = {
      offset: String((args.params.current - 1) * (args.params.pageSize)),
      limit: String(args.take),
      where: args.where
        ? [
          args.where.capacity ? `capacity:eq:${args.where.capacity}` : "",
          args.where.extraCapacity ? `extraCapacity:eq:${args.where.extraCapacity}` : "",
          args.where.timeslot ? `timeslot:eq:${args.where.timeslot}` : "",
          args.where.timeslotType ? `timeslotType:eq:${args.where.timeslotType}` : ""
        ]

          .filter((item) => item !== "")
          .join("|")
        : "",
      order: args.sort ? `${args.sortBy}:${args.order}` : "",
    }
    const searchParams = new URLSearchParams(consolidArgs)
    let response = await apiClient
      .get<{
        status: "OK", reference: {
          result: {
            "id": string,
            "capacity": number,
            "extraCapacity": number,
            "timeslot": number,
            "timeslotType": number,
            "used": number
          }[],
          recordTotal: number
        }
      } | {
        status: "Error",
        error?: {
          msg: string
          code: string;
        }
      }>(`/admin/applicationYear/getAttachmentTemplates/${groupId}?${searchParams.toString()}`);

    if (response.data.status === 'OK') {
      const user = response.data

      return response.data
    } else {
      return response.data
    }
  } catch (e) {
    return handleError(e);
  }
}

async function updateAttachmentTemplate(groupId: string, data: {
  id: string
  body: string
}) {
  try {
    let response = await apiClient
      .post<{
        status: "OK", reference: Timeslot
      } | {
        status: "Error",
        error?: {
          msg: string
          code: string;
        }
      }>(`/admin/applicationYear/updateAttachmentTemplate/${groupId}/${data.id}`, { body: data.body });

    if (response.data.status === 'OK') {
      const user = response.data
      return response.data
    } else {
      return response.data
    }
  } catch (e) {
    return handleError(e);
  }
}
async function deleteAttachment(groupId: string,
  id: string
) {
  try {
    let response = await apiClient
      .get<{
        status: "OK", reference: Timeslot
      } | {
        status: "Error",
        error?: {
          msg: string
          code: string;
        }
      }>(`/admin/applicationYear/deleteAttachmentTemplate/${groupId}/${id}`);

    if (response.data.status === 'OK') {
      const user = response.data
      return response.data
    } else {
      return response.data
    }
  } catch (e) {
    return handleError(e);
  }
}

async function updateEmailTemplate(groupId: string, data: {
  id: string
  attachment: string
  body: string
  subject: string
}) {
  try {
    let response = await apiClient
      .post<{
        status: "OK", reference: Timeslot
      } | {
        status: "Error",
        error?: {
          msg: string
          code: string;
        }
      }>(`/admin/applicationYear/updateEmailTemplate/${groupId}/${data.id}`, {
        attachment: data.attachment,
        body: data.body,
        subject: data.subject
      });

    if (response.data.status === 'OK') {
      const user = response.data
      return response.data
    } else {
      return response.data
    }
  } catch (e) {
    return handleError(e);
  }
}
async function getSetting(groupId: string) {
  try {
    let response = await apiClient
      .get<{
        status: "OK", reference: ApplicationYears
      } | {
        status: "Error",
        error?: {
          msg: string
          code: string;
        }
      }>(`/admin/applicationYear/getSetting/${groupId}`);

    if (response.data.status === 'OK') {
      const user = response.data
      return response.data
    } else {
      return response.data
    }
  } catch (e) {
    return handleError(e);
  }
}
async function updateSetting(groupId: string, data: {
  applicationFor: string
  applicationMode: string
  applicationStartDate: number
  applicationEndDate: number
  applicationVisibleStartDate: number
  applicationVisibleEndDate: number
  schoolYear: number
  placeHolders: any
}) {
  try {


    let response = await apiClient
      .post<{
        status: "OK"
      } | {
        status: "Error",
        error?: {
          msg: string
          code: string;
        }
      }>(`/admin/applicationYear/updateSetting/${groupId}`, data);

    if (response.data.status === 'OK') {
      const user = response.data
      return response.data
    } else {
      return response.data
    }
  } catch (e) {
    return handleError(e);
  }
}

async function updateFirstRoundInterviewResult(groupId: string, appId: string, data: {
}) {
  try {

    let response = await apiClient
      .post<{
        status: "OK"
      } | {
        status: "Error",
        error?: {
          msg: string
          code: string;
        }
      }>(`/admin/applicationYear/updateApplicationFirstInterviewResult/${groupId}/${appId}`, data);

    if (response.data.status === 'OK') {
      const user = response.data
      return response.data
    } else {
      return response.data
    }
  } catch (e) {
    return handleError(e);
  }
}
async function updateSecondRoundInterviewResult(groupId: string, appId: string, data: {
}) {
  try {

    let response = await apiClient
      .post<{
        status: "OK"
      } | {
        status: "Error",
        error?: {
          msg: string
          code: string;
        }
      }>(`/admin/applicationYear/updateApplicationSecondInterviewResult/${groupId}/${appId}`, data);

    if (response.data.status === 'OK') {
      const user = response.data
      return response.data
    } else {
      return response.data
    }
  } catch (e) {
    return handleError(e);
  }
}
async function downloadReport(groupId: string, reportType: string, status?: string) {
  let consolidArgs: any = {
    reportType
  }
  if (status) {
    consolidArgs["status"] = status;
  }
  const searchParams = new URLSearchParams(consolidArgs)
  let response = await apiClient({
    url: '/admin/applicationYear/exportAllApplications/' + groupId + '?' + searchParams.toString(),
    method: 'GET',
    responseType: 'blob', // important
  })

  const href = URL.createObjectURL(response.data);

  // create "a" HTML element with href to file & click
  const link = document.createElement('a');
  link.href = href;
  if (reportType == "formatApplicationListandInterviewListExcel") {
    link.setAttribute('download', `allApplications-${new Date().getTime()}.xlsx`);
  } else if (reportType == "firstInterview") {
    link.setAttribute('download', `firstInterviews-${new Date().getTime()}.xlsx`);
  } else if (reportType == "secondInterview") {
    link.setAttribute('download', `secondInterviews-${new Date().getTime()}.xlsx`);
  } else if (reportType == "thirdInterviewExcel") {
    link.setAttribute('download', `thirdInterviews-${new Date().getTime()}.xlsx`);
  } else {
    link.setAttribute('download', 'report.xlsx');
  }
  document.body.appendChild(link);
  link.click();

  // clean up "a" element & remove ObjectURL
  document.body.removeChild(link);
  URL.revokeObjectURL(href);
  return true;
}
async function downloadTemplate(groupId: string, templateType: string) {
  let url = ""
  let filename = ""

  if (templateType == "getSecondRoundCandidatesInputDump") {
    url = '/admin/applicationYear/getSecondRoundCandidatesInputDump/' + groupId;
    filename = `secondRoundCandidates-${new Date().getTime()}.xlsx`;
  } else if (templateType == "getThirdRoundCandidatesInputDump") {
    url = '/admin/applicationYear/getThirdRoundCandidatesInputDump/' + groupId;
    filename = `thirdRoundCandidates-${new Date().getTime()}.xlsx`;
  } else if (templateType == "getWaitingListCandidatesInputDump") {
    url = '/admin/applicationYear/getWaitingListCandidatesInputDump/' + groupId;
    filename = `waitingListCandidates-${new Date().getTime()}.xlsx`;
  } else if (templateType == "getFinalOfferCandidatesInputDump") {
    url = '/admin/applicationYear/getFinalOfferCandidatesInputDump/' + groupId;
    filename = `finalOfferCandidates-${new Date().getTime()}.xlsx`;
  } else if (templateType == "getOfferSentCandidatesInputDump") {
    url = '/admin/applicationYear/getOfferSentCandidatesInputDump/' + groupId;
    filename = `offerSentCandidates-${new Date().getTime()}.xlsx`;
  } else if (templateType == "getFinalApplicationsCandidatesInputDump") {
    url = '/admin/applicationYear/getFinalApplicationsCandidatesInputDump/' + groupId;
    filename = `finalApplicationsCandidates-${new Date().getTime()}.xlsx`;
  } else if (templateType == "getSecondRoundResultInputDump") {
    url = '/admin/applicationYear/getSecondRoundResultInputDump/' + groupId;
    filename = `secondRoundResult-${new Date().getTime()}.xlsx`;
  }
  let response = await apiClient({
    url: url,
    method: 'GET',
    responseType: 'blob', // important
  })

  const href = URL.createObjectURL(response.data);

  // create "a" HTML element with href to file & click
  const link = document.createElement('a');
  link.href = href;
  link.setAttribute('download', filename);

  document.body.appendChild(link);
  link.click();

  // clean up "a" element & remove ObjectURL
  document.body.removeChild(link);
  URL.revokeObjectURL(href);
  return true;
}
async function importTemplateData(groupId: string, templateType: string, data: any[]) {
  let url = ""
  if (templateType == "getSecondRoundCandidatesInputDump") {
    url = '/admin/applicationYear/importSecondRoundCandidate/' + groupId;
  } else if (templateType == "getThirdRoundCandidatesInputDump") {
    url = '/admin/applicationYear/importThirdRoundCandidate/' + groupId;
  } else if (templateType == "getWaitingListCandidatesInputDump") {
    url = '/admin/applicationYear/importWaitingListCandidates/' + groupId;
  } else if (templateType == "getFinalOfferCandidatesInputDump") {
    url = '/admin/applicationYear/importFinalOfferCandidate/' + groupId;
  } else if (templateType == "getOfferSentCandidatesInputDump") {
    url = '/admin/applicationYear/importOfferSentCandidates/' + groupId;
  } else if (templateType == "getFinalApplicationsCandidatesInputDump") {
    url = '/admin/applicationYear/importFinalApplicationsCandidates/' + groupId;
  } else if (templateType == "getSecondRoundResultInputDump") {
    url = '/admin/applicationYear/importApplicationSecondInterviewResult/' + groupId;
  }
  let response = await apiClient
    .post<{
      status: "OK"
    } | {
      status: "Error",
      error?: {
        msg: string
        code: string;
      }
    }>(url, { data });

  if (response.data.status === 'OK') {
    const user = response.data
    return response.data
  } else {
    return response.data
  }
}
async function newTask(groupId: string, taskName: string) {
  let url = ""
  let body:any = {}
  if (taskName == "autoAssignFirstInterviewTimeslot") {
    url = '/admin/applicationYear/autoAssignApplicationTimeslot/' + groupId;
    body = {timeslotType: 1}
  } else if (taskName == "autoAssignSecondInterviewTimeslot") {
    url = '/admin/applicationYear/autoAssignApplicationTimeslot/' + groupId;
    body = {timeslotType: 2}
  } else if (taskName == "autoAssignThirdInterviewTimeslot") {
    url = '/admin/applicationYear/autoAssignApplicationTimeslot/' + groupId;
    body = {timeslotType: 3}
  } else if (taskName == "autoAssignOfferTimeslot") {
    url = '/admin/applicationYear/autoAssignApplicationTimeslot/' + groupId;
    body = {timeslotType: 10}
  } else if (taskName == "generateFirstRoundInterviewEmail") {
    url = '/admin/maintenance/newJob';
    body = {name: "generateFirstRoundInterviewEmail", groupId: groupId}
  } else if (taskName == "generateSecondRoundInterviewEmail") {
    url = '/admin/maintenance/newJob';
    body = {name: "generateSecondRoundInterviewEmail", groupId: groupId}
  } else if (taskName == "generateThirdRoundInterviewEmail") {
    url = '/admin/maintenance/newJob';
    body = {name: "generateThirdRoundInterviewEmail", groupId: groupId}
  }else if (taskName == "generateOfferEmail") {
    url = '/admin/maintenance/newJob';
    body = {name: "generateOfferEmail", groupId: groupId}
  }else if (taskName == "generateRejectEmail") {
    url = '/admin/maintenance/newJob';
    body = {name: "generateRejectEmail", groupId: groupId}
  }else if (taskName == "generateWaitingListEmail") {
    url = '/admin/maintenance/newJob';
    body = {name: "generateWaitingListEmail", groupId: groupId}
  }else if (taskName == "archiveApplications") {
    url = '/admin/maintenance/newJob';
    body = {name: "archiveApplications", groupId: groupId}
  }else if (taskName == "archiveUploadDocuments") {
    url = '/admin/maintenance/newJob';
    body = {name: "archiveDocuments", groupId: groupId}
  }
  let response = await apiClient
    .post<{
      status: "OK"
    } | {
      status: "Error",
      error?: {
        msg: string
        code: string;
      }
      msg?:{
        msg: string
        code: string;
      }
    }>(url, body);

  if (response.data.status === 'OK') {
    const user = response.data
    return response.data
  } else {
    return response.data
  }
}
async function sendEmail(groupId: string, applicationId: string, emailType: number) {
  try {
    let response = await apiClient.post<{
      status: "OK"
    } | {
      status: "Error",
      error?: {
        msg: string
        code: string;
      }
    }>("/admin/applicationYear/sendEmail/" + groupId + "/" + applicationId, {
      emailType: emailType,
      applicationId,
      groupId
    })
    if (response.data.status === 'OK') {
      const user = response.data
      return response.data
    } else {
      return response.data
    }
  } catch (e) {

  }
}

async function getEmails(groupId: string, applicationId: string) {
  try {
    let response = await apiClient.get<{
      status: "OK"
      reference: any
    } | {
      status: "Error",
      error?: {
        msg: string
        code: string;
      }
    }>("/admin/applicationYear/getEmails/" + groupId + "/" + applicationId)
    if (response.data.status === 'OK') {
      const user = response.data
      return response.data
    } else {
      return response.data
    }
  } catch (e) {

  }
}
async function test(groupId: string,) {
  try {

    let response = await apiClient
      .get<{
        status: "OK", reference: Application
      } | {
        status: "Error",
        error?: {
          msg: string
          code: string;
        }
      }>(`/admin/applicationYear/test/${groupId}`);

    if (response.data.status === 'OK') {
      const user = response.data
      return response.data
    } else {
      return response.data
    }
  } catch (e) {
    return handleError(e);
  }
}