
export function ApplicationStatusCodeToName(code: number) {
    switch (code) {
        case 1:
            return "Applied"
        case 2:
            return "Content Error"
        case 3:
            return "Content Updated"
        case 4:
            return "Content Verified"
        case 5:
            return "First Round Candidate"
        case 6:
            return "First Round Arranged"
        case 7:
            return "First Round Accepted"
        case 8:
            return "Second Round Candidate"
        case 9:
            return "Second Round Arranged"
        case 10:
            return "Second Round Accepted"
        case 11:
            return "Third Round Candidate"
        case 12:
            return "Third Round Arranged"
        case 13:
            return "Third Round Accepted"
        case 14:
            return "Canceled"
        case 15:
            return "Suceed"
        case 16:
            return "Rejected"
        case 17:
            return "Offer Sent"
        case 18:
            return "Offer Accepted"
        case 19:
            return "Offer Rejected"
        case 20:
            return "Waiting List"
        default:
            return "Unkown";
    }
}
export enum ApplicationStatus {
    Applied = 1, //Applicant apply
    ContentError = 2, //Admin verified if content error
    ContentUpdated = 3, //User update application when status == Content Error
    ContentVerified = 4, //Admin verified the applicant update
    FirstRoundCandidate = 5, //If no change by admin/applicant, application auto change to this status after 24 hours
    FirstRoundArranged = 6, //When timeslot assigned => Action trigger by the admin
    FirstRoundAccepted = 7, //Auto set after first interview Date 
    SecondRoundCandidate = 8,//Manual set by the admin 
    SecondRoundArranged = 9,//When timeslot assigned => Action trigger by the admin
    SecondRoundAccepted = 10,//Auto set after second interview Date 
    ThirdRoundCandidate = 11,//Manual set by the admin
    ThirdRoundArranged = 12,//When timeslot assigned => Action trigger by the admin
    ThirdRoundAccepted = 13,//Auto set after third interview Date 
    Canceled = 14,//status set by admin
    Successed = 15,//status set  by admin
    Rejected = 16, //status set  by admin
    OfferSent = 17,
    OfferAccepted = 18,
    OfferRejected = 19,
    WaitingList = 20
}
export interface ApplicationForm {
    applicantPhoto: string
    applicantPositionAmongSiblings: number
    applyWithSiblings: boolean
    bankReceiptCopy: string
    bankReceiptCopyPDF: string
    bankReferenceNumber: string
    birthCertificateImage: string
    birthCertificatePDF: string
    dateOfApplication: string
    dateOfBirth: string
    email: string
    fatherCampusCollege: boolean
    fatherCampusPreparatorySchool: boolean
    fatherChineseName: string
    fatherEnglishName: string
    fatherIDCardPassport: string
    fatherInformation: boolean
    fatherIsSSCAAMembership: boolean
    fatherOccupation: string
    fatherOfficeAddress: string
    fatherSSCAAMembershipNumber: string
    fatherTelephoneMobile: string
    fatherTelephoneOffice: string
    fatherTitle: string
    fatherYearOfAttendance: string
    fatherYearOfAttendanceA: string
    fatherYearOfAttendanceB: string
    gender: "M" | "F"
    givenName: string
    grade: string
    gradeAppliedFor: "G1" | "G2" | "G3" | "G4" | "G5" | "G6"
    hkBirthCertificatePassport: string
    hkIDPassportCopyImage: string
    hkIDPassportCopyPDF: string
    homeAddress: string
    homeAddressDistrict: string
    homeAddressRegion: string
    isPhotoValid: boolean
    motherCampusCollege: boolean
    motherCampusPreparatorySchool: boolean
    motherChineseName: string
    motherEnglishName: string
    motherIDCardPassport: string
    motherInformation: boolean
    motherIsSSCAAMembership: boolean
    motherOccupation: string
    motherOfficeAddress: string
    motherSSCAAMembershipNumber: string
    motherTelephoneMobile: string
    motherTelephoneOffice: string
    motherTitle: string
    motherTongueOfTheApplicant: string
    motherTongueOther: string
    motherYearOfAttendance: string
    motherYearOfAttendanceA: string
    motherYearOfAttendanceB: string
    nameInChinese: string
    nationality: string
    nationalityOther: string
    noOfSiblings: number
    placeOfBirth: string
    presentSchool: string
    religion: string
    siblingCampus: string
    siblingCampusCollege: boolean
    siblingCampusPreparatory: boolean
    siblingNameOfAttend: string
    siblingYearOfAttend: string
    siblingYearOfAttendA: string
    siblingYearOfAttendB: string
    surname: string
    telephone: string
    yearsAttended: string
}
export interface FirstInterviewResult{
    firstInResultScoure:{
        "談玩具":number,
        "聽說":number,
        "總表現":number,
        "表演":number
    },
    firstInResultRemark:string,
    firstInResultLanguage: string[]
    firstInResultPersonalityPositive: string[]
    firstInResultPersonalityNegative:string[]   
}
export interface SecondInterviewResult {

    SecondInResultPartAPersonalityAbilitiesNegative: string[]
    SecondInResultPartAPersonalityAbilitiesPositive: string[]
    SecondInResultPartAPersonalityAppearancesNegative: string[]
    SecondInResultPartAPersonalityAppearancesPositive: string[]
    SecondInResultPartARemark: string
    SecondInResultPartAScoure: {
        "口語表達": number
        "態度舉止": number
        "普通話能力": number
        "生活體驗": number
        "英語能力": number
        "講故事": number
    }
    SecondInResultPartASkill: string[]
    SecondInResultPartASkillOther: string
    SecondInResultPartBLanguageNegative: string[]
    SecondInResultPartBLanguagePositive: string[]
    SecondInResultPartBRemark: string
    SecondInResultPartBScoure: {
        "Eng": number
        "中": number
        "Q1": {
            "English": number
            "中文": number
        },
        "Q2": {
            "English": number
            "中文": number
        },
        "Q3": {
            "English": number
            "中文": number
        }
    }

}
export interface Application {

    ApplicationForm: ApplicationForm
    ApplicationId: string
    ApplyDate: number
    ConfirmDate: number
    ConfirmedPerson: string
    FirstInterviewComments: FirstInterviewResult|string
    FirstInterviewDate: string
    FirstInterviwer: string
    LastModifiedDate: number
    OTP: string
    OTPExpiredDate: string
    SecondInterviewComments: SecondInterviewResult | string
    SecondInterviewDate: string
    SecondInterviwer: "Manual" | "Auto"
    Status: ApplicationStatus
    ThirdInterviewComments: string
    ThirdInterviewDate: string
    ThirdInterviwer: string
    OfferDate: string
    id: string
}
