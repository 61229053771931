import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";

import { ActionType, ProColumns } from "@ant-design/pro-components";
import {
  Button,
  Switch,
  message,
  Image,
  Breadcrumb,
  Skeleton,
  Modal,
  Upload,
  Popconfirm,
  PopconfirmProps,
} from "antd";

import { Application } from "../../../../models/application";
import { AntDProTable } from "../../../../components/antdProTable";
import axios from "axios";
import { applicationService } from "../../../../services/applicationService";
import { useNavigate, useParams } from "react-router-dom";
import moment from "moment";
import { HomeOutlined, InboxOutlined, TagsOutlined } from "@ant-design/icons";
import * as XLSX from "xlsx";
import Swal from "sweetalert2";
//Start
interface TaskInterface {
  taskName: string;
  description: string;
}

export function TaskListPage() {
  const { t } = useTranslation();
  const { id } = useParams();
  const navigate = useNavigate();

  let tableRef = useRef<ActionType>();

 
  const columns: ProColumns<TaskInterface>[] = [
    {
      title: t("Task Name"),
      dataIndex: "taskName",
      hideInSearch: true,
      renderText(text, record, index, action) {
        return t("task." + text);
      },
    },
    {
      title: t("Description"),
      dataIndex: "description",
      hideInSearch: true,
    },
    {
      title: t("Actions"),
      dataIndex: "",
      search: false,
      fixed: "right",
      width: 300,

      render: (text, record) => {
        return (
          <>
            <Popconfirm
              title={t("Execute the task")}
              description={t("Are you sure to execute this task?")}
              onConfirm={(e) => {
                applicationService.newTask(id??"", record.taskName).then(p=>{
                    if(p.status=="Error"){
                        Swal.fire({
                            icon: 'error',
                            title: '',
                            text: p.msg?.msg??"Error"
                        })
                    }else{
                        Swal.fire({
                            icon: 'success',
                            title: '',
                            text: "Task is being queued, it will be executed in the background"
                        })
                    }
                }).catch(p=>{console.error(e)})
              //  record.taskMission();
              }}
              onCancel={(e) => {
                console.log(e);
              }}
              okText={t("Yes")}
              cancelText={t("No")}
            >
              <Button type="link">{t("Execute")}</Button>
            </Popconfirm>
          </>
        );
      },
    },
  ];

  return (
    <>
      <AntDProTable<TaskInterface>
        filterType={"light"}
        size={"small"}
        onRefCallback={(ref: any) => {
          tableRef.current = ref.current;
        }}
        columns={columns}
        rowKey="taskName"
        scroll={{ x: "max-content" }}
        pageSize={50}
        dataSource={[
          {
            taskName: "autoAssignFirstInterviewTimeslot",
            description: `${t("Switch Status: ")}${t("FirstRoundCandidate")} -> ${t(
              "FirstRoundArranged"
            )}`
          },
          {
            taskName: "autoAssignSecondInterviewTimeslot",
            description: `${t("Switch Status: ")}${t("SecondRoundCandidate")} -> ${t(
              "SecondRoundArranged"
            )}`
          },
          {
            taskName: "autoAssignThirdInterviewTimeslot",
            description: `${t("Switch Status: ")}${t("ThirdRoundCandidate")} -> ${t(
              "ThirdRoundArranged"
            )}`
          },
          {
            taskName: "autoAssignOfferTimeslot",
            description: `${t("Switch Status: ")}${t("Successed")} -> ${t(
              "OfferSent"
            )}`
          },
          {
            taskName: "generateFirstRoundInterviewEmail",
            description: `${t("Send Email to: ")} ${t(
              "FirstRoundAccepted"
            )}(Mode B),${t("FirstRoundArranged")},${t("Applied")}`
          },
          {
            taskName: "generateSecondRoundInterviewEmail",
            description: `${t("Send Email to: ")} ${t("SecondRoundArranged")}`
          },
          {
            taskName: "generateThirdRoundInterviewEmail",
            description: `${t("Send Email to: ")} ${t("ThirdRoundArranged")}`
          },
          {
            taskName: "generateOfferEmail",
            description: `${t("Send Email to: ")} ${t("OfferSent")}`
          },
          {
            taskName: "generateRejectEmail",
            description: `${t("Send Email to: ")} ${t("Rejected")}`
          },
          {
            taskName: "generateWaitingListEmail",
            description: `${t("Send Email to: ")} ${t("WaitingList")}`,
          },
          {
            taskName: "archiveApplications",
            description: ``
          },

          {
            taskName: "archiveUploadDocuments",
            description: ``
          },
        ]}
      />
    </>
  );
}
