import { useEffect, useRef, useState } from "react"
import { useTranslation } from "react-i18next"

import { ActionType, ProColumns } from "@ant-design/pro-components"
import { Button, Switch, message, Image, Breadcrumb, Skeleton } from "antd"

import { Application } from "../../../../models/application"
import { AntDProTable } from "../../../../components/antdProTable"
import axios from "axios"
import { applicationService } from "../../../../services/applicationService"
import { useNavigate, useParams } from "react-router-dom"
import moment from "moment"
import { HomeOutlined, TagsOutlined } from "@ant-design/icons"
//Start
export function DownloadApplicationListPage() {
    const { t } = useTranslation()
    const { id, app } = useParams();

    const navigate = useNavigate();
    const [filterByStatus, setFilterByStatus] = useState<number[]>([])
    const [isLoading, setIsLoading] = useState<boolean>(false)
    let tableRef = useRef<ActionType>()

    const reload = () => {
        tableRef.current?.reload()
    }
    useEffect(() => {
        reload();
    }, [filterByStatus])

    

    const columns: ProColumns<{ reportName: string, status?:string }>[] = [
        {
            title: t("Report Name"),
            dataIndex: "reportName",
            hideInSearch:true,
            renderText(text, record, index, action) {
                return t("report."+text)
            }
        },
        {
            title: t("Actions"),
            dataIndex: "",
            search: false,
            fixed: 'right',
            width: 300,

            render: (text, record) => {
                return (
                    <><Button
                        onClick={() => {
                            setIsLoading(true);
                            applicationService.downloadReport(id||"", record.reportName, record.status).then((res) => {
                                setIsLoading(false);
                            })
                        }}
                        type="link"
                    >
                        {t("Download")}
                    </Button>
                    </>
                )
            }
        }
    ]

    return (
        <>

            <AntDProTable<{ reportName: string, status?:string }>
                filterType={"light"}
                size={"small"}

                onRefCallback={(ref: any) => {
                    tableRef.current = ref.current
                }}
                columns={columns}
                rowKey="reportName"
                scroll={{ x: "max-content" }}
                dataSource={[
                    { reportName: "formatApplicationListandInterviewListExcel" },
                    { reportName: "firstInterview" },
                    { reportName: "secondInterview", status:"8,9,10,11,12,13,14,15,16" },
                    { reportName: "thirdInterviewExcel" }
                ]}
            />

        </>
    )
}

