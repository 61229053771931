import { useEffect, useRef, useState } from "react"
import { useTranslation } from "react-i18next"

import { ActionType, ProColumns } from "@ant-design/pro-components"
import { Button, Switch, message, Image, Breadcrumb, Skeleton, Modal, Upload } from "antd"

import { Application } from "../../../../models/application"
import { AntDProTable } from "../../../../components/antdProTable"
import axios from "axios"
import { applicationService } from "../../../../services/applicationService"
import { useNavigate, useParams } from "react-router-dom"
import moment from "moment"
import { HomeOutlined, InboxOutlined, TagsOutlined } from "@ant-design/icons"
import * as XLSX from 'xlsx';
//Start
interface UploadInterface {
    uploadType: string,
    uploadModal: string
}

export function UploadApplicationListPage() {
    const { t } = useTranslation()
    const { id } = useParams();
    const navigate = useNavigate();
    const [filterByStatus, setFilterByStatus] = useState<number[]>([])
    const [isLoading, setIsLoading] = useState<boolean>(false)
    const [batchConfirmLoading, setBatchConfirmLoading] = useState<boolean>(false)
    const [toUpload, setToUpload] = useState<UploadInterface>()
    const [modalData, setModalData] = useState<any[]>([])
    let tableRef = useRef<ActionType>()

    const reload = () => {
        tableRef.current?.reload()
    }
    useEffect(() => {
        reload();
    }, [filterByStatus])



    const columns: ProColumns<UploadInterface>[] = [
        {
            title: t("Upload Type"),
            dataIndex: "uploadType",
            hideInSearch: true,
            renderText(text, record, index, action) {
                return t("upload." + text)
            }
        },
        {
            title: t("Actions"),
            dataIndex: "",
            search: false,
            fixed: 'right',
            width: 300,

            render: (text, record) => {
                return (
                    <><Button
                        onClick={() => {
                            setToUpload(record)
                        }}
                        type="link"
                    >
                        {t("Upload")}
                    </Button>
                        <Button
                            onClick={() => {
                                applicationService.downloadTemplate(id || "", record.uploadType).then((res) => {
                                })
                            }}
                            type="link"
                        >
                            {t("Download Template")}
                        </Button>
                    </>
                )
            }
        }
    ]

    return (
        <>

            <AntDProTable<UploadInterface>
                filterType={"light"}
                size={"small"}

                onRefCallback={(ref: any) => {
                    tableRef.current = ref.current
                }}
                columns={columns}
                rowKey="reportName"
                scroll={{ x: "max-content" }}
                dataSource={[
                    { uploadType: "getSecondRoundCandidatesInputDump", uploadModal: "passModal" },
                    { uploadType: "getSecondRoundResultInputDump", uploadModal: "secondInResultModal" },
                    { uploadType: "getThirdRoundCandidatesInputDump", uploadModal: "passModal" },
                    { uploadType: "getFinalOfferCandidatesInputDump", uploadModal: "passModal" },
                    { uploadType: "getWaitingListCandidatesInputDump", uploadModal: "offerModal" },
                    { uploadType: "getOfferSentCandidatesInputDump", uploadModal: "acceptModal" },
                    { uploadType: "getFinalApplicationsCandidatesInputDump", uploadModal: "finalModal" }
                ]}
            />

            {toUpload && toUpload.uploadModal == "passModal" && <Modal
                title={t("upload." + toUpload.uploadType)}
                centered
                open={toUpload && toUpload.uploadModal == "passModal"}
                confirmLoading={batchConfirmLoading}
                onOk={async () => {
                    if (modalData.length > 0) {
                        setBatchConfirmLoading(true)
                        applicationService.importTemplateData(id || "", toUpload!.uploadType, modalData).then(response => {

                            setBatchConfirmLoading(false)
                            if (response.status === "OK") {
                                setToUpload(undefined)
                                setModalData([])
                                tableRef.current?.reload()
                            }
                        }).catch(e => {

                            setBatchConfirmLoading(false)
                        })
                    } else {
                        setModalData([])
                        setToUpload(undefined)
                    }
                }}
                onCancel={() => {
                    setModalData([])
                    setToUpload(undefined)
                }}
                width={1000}
            >

                <Upload.Dragger
                    maxCount={1}
                    showUploadList={false}
                    beforeUpload={(file, FileList) => {
                        const reader = new FileReader();
                        reader.onload = (e: ProgressEvent<FileReader>) => {
                            const data = new Uint8Array(e.target?.result as ArrayBuffer);
                            const workbook = XLSX.read(data, { type: 'buffer' });
                            const worksheet = workbook.Sheets[workbook.SheetNames[0]];
                            const jsonData = XLSX.utils.sheet_to_json(worksheet);
                            setModalData(jsonData.map((v: any) => {
                                return {
                                    "ApplicationId": v["ApplicationId"] || null,
                                    "FirstName": v["FirstName"] || null,
                                    "LastName": v["LastName"] || null,
                                    "HkBirthCertificatePassport": v["HkBirthCertificatePassport"] || null,
                                    "Pass(T/F)": v["Pass(T/F)"] || null
                                }
                            }).filter(v => v.ApplicationId != null && v.FirstName != null && v.LastName != null && v.HkBirthCertificatePassport != null && v["Pass(T/F)"] != null))
                        };
                        reader.readAsArrayBuffer(file);
                        return false;
                    }}
                    name="files"
                    accept=".csv, text/csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel">
                    <p className="ant-upload-drag-icon">
                        <InboxOutlined />
                    </p>
                    <p className="ant-upload-text">{t("Click or drag file to this area to upload")}</p>
                </Upload.Dragger>
                <Button type="link" onClick={() => { applicationService.downloadTemplate(id || "", toUpload.uploadType).then((res) => { }) }} >{t("Template Download")}</Button>
                <AntDProTable<any>
                    filterType={"light"}
                    size={"small"}
                    onRefCallback={(ref: any) => {
                    }}
                    pageSizeOptions={[100, 200]}
                    columns={[
                        {
                            title: t("Application ID"),
                            dataIndex: "ApplicationId",
                            hideInSearch: true,
                        },
                        {
                            title: t("Given Name (English)"),
                            hideInSearch: true,
                            dataIndex: "FirstName"
                        },
                        {
                            title: t("Surname (English)"),
                            hideInSearch: true,
                            dataIndex: "LastName"
                        },
                        {
                            title: t("HK Birth Certificate/Passport No."),
                            hideInSearch: true,
                            dataIndex: "HkBirthCertificatePassport"
                        },
                        {
                            title: t("Pass(T/F)"),
                            hideInSearch: true,
                            dataIndex: "Pass(T/F)"
                        }
                    ]}
                    rowKey="timeslot"
                    scroll={{ x: 'max-content' }}

                    dataSource={modalData}
                />
            </Modal>}
            {toUpload && toUpload.uploadModal == "offerModal" && <Modal
                title={t("upload." + toUpload.uploadType)}
                centered
                open={toUpload && toUpload.uploadModal == "offerModal"}
                confirmLoading={batchConfirmLoading}
                onOk={async () => {
                    if (modalData.length > 0) {
                        setBatchConfirmLoading(true)
                        applicationService.importTemplateData(id || "", toUpload!.uploadType, modalData).then(response => {

                            setBatchConfirmLoading(false)
                            if (response.status === "OK") {
                                setToUpload(undefined)
                                setModalData([])
                                tableRef.current?.reload()
                            }
                        }).catch(e => {

                            setBatchConfirmLoading(false)
                        })
                    } else {
                        setModalData([])
                        setToUpload(undefined)
                    }
                }}
                onCancel={() => {
                    setModalData([])
                    setToUpload(undefined)
                }}
                width={1000}
            >

                <Upload.Dragger
                    maxCount={1}
                    showUploadList={false}
                    beforeUpload={(file, FileList) => {
                        const reader = new FileReader();
                        reader.onload = (e: ProgressEvent<FileReader>) => {
                            const data = new Uint8Array(e.target?.result as ArrayBuffer);
                            const workbook = XLSX.read(data, { type: 'buffer' });
                            const worksheet = workbook.Sheets[workbook.SheetNames[0]];
                            const jsonData = XLSX.utils.sheet_to_json(worksheet);
                            setModalData(jsonData.map((v: any) => {
                                return {
                                    "ApplicationId": v["ApplicationId"] || null,
                                    "FirstName": v["FirstName"] || null,
                                    "LastName": v["LastName"] || null,
                                    "HkBirthCertificatePassport": v["HkBirthCertificatePassport"] || null,
                                    "Offer(T/F)": v["Offer(T/F)"] || null,
                                    "Reject(T/F)": v["Reject(T/F)"] || null
                                }
                            }).filter(v => v.ApplicationId != null
                                && v.FirstName != null
                                && v.LastName != null
                                && v.HkBirthCertificatePassport != null
                                && v["Offer(T/F)"] != null
                                && v["Reject(T/F)"] != null))
                        };
                        reader.readAsArrayBuffer(file);
                        return false;
                    }}
                    name="files"
                    accept=".csv, text/csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel">
                    <p className="ant-upload-drag-icon">
                        <InboxOutlined />
                    </p>
                    <p className="ant-upload-text">{t("Click or drag file to this area to upload")}</p>
                </Upload.Dragger>
                <Button type="link" onClick={() => { applicationService.downloadTemplate(id || "", toUpload.uploadType).then((res) => { }) }} >{t("Template Download")}</Button>
                <AntDProTable<any>
                    filterType={"light"}
                    size={"small"}

                    onRefCallback={(ref: any) => {
                    }}
                    pageSizeOptions={[100, 200]}
                    columns={[
                        {
                            title: t("Application ID"),
                            dataIndex: "ApplicationId",
                            hideInSearch: true,
                        },
                        {
                            title: t("Given Name (English)"),
                            hideInSearch: true,
                            dataIndex: "FirstName"
                        },
                        {
                            title: t("Surname (English)"),
                            hideInSearch: true,
                            dataIndex: "LastName"
                        },
                        {
                            title: t("HK Birth Certificate/Passport No."),
                            hideInSearch: true,
                            dataIndex: "HkBirthCertificatePassport"
                        },
                        {
                            title: t("Offer(T/F)"),
                            hideInSearch: true,
                            dataIndex: "Offer(T/F)"
                        },
                        {
                            title: t("Reject(T/F)"),
                            hideInSearch: true,
                            dataIndex: "Reject(T/F)"
                        }
                    ]}
                    rowKey="timeslot"
                    scroll={{ x: 'max-content' }}

                    dataSource={modalData}
                />
            </Modal>}
            {toUpload && toUpload.uploadModal == "acceptModal" && <Modal
                title={t("upload." + toUpload.uploadType)}
                centered
                open={toUpload && toUpload.uploadModal == "acceptModal"}
                confirmLoading={batchConfirmLoading}
                onOk={async () => {
                    if (modalData.length > 0) {
                        setBatchConfirmLoading(true)
                        applicationService.importTemplateData(id || "", toUpload!.uploadType, modalData).then(response => {

                            setBatchConfirmLoading(false)
                            if (response.status === "OK") {
                                setToUpload(undefined)
                                setModalData([])
                                tableRef.current?.reload()
                            }
                        }).catch(e => {

                            setBatchConfirmLoading(false)
                        })
                    } else {
                        setModalData([])
                        setToUpload(undefined)
                    }
                }}
                onCancel={() => {
                    setModalData([])
                    setToUpload(undefined)
                }}
                width={1000}
            >

                <Upload.Dragger
                    maxCount={1}
                    showUploadList={false}
                    beforeUpload={(file, FileList) => {
                        const reader = new FileReader();
                        reader.onload = (e: ProgressEvent<FileReader>) => {
                            const data = new Uint8Array(e.target?.result as ArrayBuffer);
                            const workbook = XLSX.read(data, { type: 'buffer' });
                            const worksheet = workbook.Sheets[workbook.SheetNames[0]];
                            const jsonData = XLSX.utils.sheet_to_json(worksheet);
                            setModalData(jsonData.map((v: any) => {
                                return {
                                    "ApplicationId": v["ApplicationId"] || null,
                                    "FirstName": v["FirstName"] || null,
                                    "LastName": v["LastName"] || null,
                                    "HkBirthCertificatePassport": v["HkBirthCertificatePassport"] || null,
                                    "Accepted(T/F)": v["Accepted(T/F)"] || null,
                                }
                            }).filter(v => v.ApplicationId != null
                                && v.FirstName != null
                                && v.LastName != null
                                && v.HkBirthCertificatePassport != null
                                && v["Accepted(T/F)"] != null))
                        };
                        reader.readAsArrayBuffer(file);
                        return false;
                    }}
                    name="files"
                    accept=".csv, text/csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel">
                    <p className="ant-upload-drag-icon">
                        <InboxOutlined />
                    </p>
                    <p className="ant-upload-text">{t("Click or drag file to this area to upload")}</p>
                </Upload.Dragger>
                <Button type="link" onClick={() => { applicationService.downloadTemplate(id || "", toUpload.uploadType).then((res) => { }) }} >{t("Template Download")}</Button>
                <AntDProTable<any>
                    filterType={"light"}
                    size={"small"}

                    onRefCallback={(ref: any) => {
                    }}
                    pageSizeOptions={[100, 200]}
                    columns={[
                        {
                            title: t("Application ID"),
                            dataIndex: "ApplicationId",
                            hideInSearch: true,
                        },
                        {
                            title: t("Given Name (English)"),
                            hideInSearch: true,
                            dataIndex: "FirstName"
                        },
                        {
                            title: t("Surname (English)"),
                            hideInSearch: true,
                            dataIndex: "LastName"
                        },
                        {
                            title: t("HK Birth Certificate/Passport No."),
                            hideInSearch: true,
                            dataIndex: "HkBirthCertificatePassport"
                        },
                        {
                            title: t("Accepted(T/F)"),
                            hideInSearch: true,
                            dataIndex: "Accepted(T/F)"
                        }
                    ]}
                    rowKey="timeslot"
                    scroll={{ x: 'max-content' }}

                    dataSource={modalData}
                />
            </Modal>}
            {toUpload && toUpload.uploadModal == "finalModal" && <Modal
                title={t("upload." + toUpload.uploadType)}
                centered
                open={toUpload && toUpload.uploadModal == "finalModal"}
                confirmLoading={batchConfirmLoading}
                onOk={async () => {
                    if (modalData.length > 0) {
                        setBatchConfirmLoading(true)
                        applicationService.importTemplateData(id || "", toUpload!.uploadType, modalData).then(response => {

                            setBatchConfirmLoading(false)
                            if (response.status === "OK") {
                                setToUpload(undefined)
                                setModalData([])
                                tableRef.current?.reload()
                            }
                        }).catch(e => {

                            setBatchConfirmLoading(false)
                        })
                    } else {
                        setModalData([])
                        setToUpload(undefined)
                    }
                }}
                onCancel={() => {
                    setModalData([])
                    setToUpload(undefined)
                }}
                width={1000}
            >

                <Upload.Dragger
                    maxCount={1}
                    showUploadList={false}
                    beforeUpload={(file, FileList) => {
                        const reader = new FileReader();
                        reader.onload = (e: ProgressEvent<FileReader>) => {
                            const data = new Uint8Array(e.target?.result as ArrayBuffer);
                            const workbook = XLSX.read(data, { type: 'buffer' });
                            const worksheet = workbook.Sheets[workbook.SheetNames[0]];
                            const jsonData = XLSX.utils.sheet_to_json(worksheet);
                            setModalData(jsonData.map((v: any) => {
                                return {
                                    "ApplicationId": v["ApplicationId"] || null,
                                    "FirstName": v["FirstName"] || null,
                                    "LastName": v["LastName"] || null,
                                    "HkBirthCertificatePassport": v["HkBirthCertificatePassport"] || null,
                                    "Original Status": v["Original Status"] || null,
                                    "New Status (OfferSent, WaitingList, Reject, Unchange)": v["New Status (OfferSent, WaitingList, Reject, Unchange)"] || null
                                }
                            }).filter(v => v.ApplicationId != null
                                && v.FirstName != null
                                && v.LastName != null
                                && v.HkBirthCertificatePassport != null
                                && v["Original Status"] != null
                                && v["New Status (OfferSent, WaitingList, Reject, Unchange)"] != null))
                        };
                        reader.readAsArrayBuffer(file);
                        return false;
                    }}
                    name="files"
                    accept=".csv, text/csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel">
                    <p className="ant-upload-drag-icon">
                        <InboxOutlined />
                    </p>
                    <p className="ant-upload-text">{t("Click or drag file to this area to upload")}</p>
                </Upload.Dragger>
                <Button type="link" onClick={() => { applicationService.downloadTemplate(id || "", toUpload.uploadType).then((res) => { }) }} >{t("Template Download")}</Button>
                <AntDProTable<any>
                    filterType={"light"}
                    size={"small"}

                    onRefCallback={(ref: any) => {
                    }}
                    pageSizeOptions={[100, 200]}
                    columns={[
                        {
                            title: t("Application ID"),
                            dataIndex: "ApplicationId",
                            hideInSearch: true,
                        },
                        {
                            title: t("Given Name (English)"),
                            hideInSearch: true,
                            dataIndex: "FirstName"
                        },
                        {
                            title: t("Surname (English)"),
                            hideInSearch: true,
                            dataIndex: "LastName"
                        },
                        {
                            title: t("HK Birth Certificate/Passport No."),
                            hideInSearch: true,
                            dataIndex: "HkBirthCertificatePassport"
                        },
                        {
                            title: t("Original Status"),
                            hideInSearch: true,
                            dataIndex: "Original Status"
                        },
                        {
                            title: t("New Status (OfferSent, WaitingList, Reject, Unchange)"),
                            hideInSearch: true,
                            dataIndex: "New Status (OfferSent, WaitingList, Reject, Unchange)"
                        }
                    ]}
                    rowKey="timeslot"
                    scroll={{ x: 'max-content' }}

                    dataSource={modalData}
                />
            </Modal>}
            {toUpload && toUpload.uploadModal == "secondInResultModal" && <Modal
                title={t("upload." + toUpload.uploadType)}
                centered
                open={toUpload && toUpload.uploadModal == "secondInResultModal"}
                confirmLoading={batchConfirmLoading}
                onOk={async () => {
                    if (modalData.length > 0) {
                        setBatchConfirmLoading(true)
                        applicationService.importTemplateData(id || "", toUpload!.uploadType, modalData).then(response => {

                            setBatchConfirmLoading(false)
                            if (response.status === "OK") {
                                setToUpload(undefined)
                                setModalData([])
                                tableRef.current?.reload()
                            }
                        }).catch(e => {

                            setBatchConfirmLoading(false)
                        })
                    } else {
                        setModalData([])
                        setToUpload(undefined)
                    }
                }}
                onCancel={() => {
                    setModalData([])
                    setToUpload(undefined)
                }}
                width={1000}
            >

                <Upload.Dragger
                    maxCount={1}
                    showUploadList={false}
                    beforeUpload={(file, FileList) => {
                        const reader = new FileReader();
                        reader.onload = (e: ProgressEvent<FileReader>) => {
                            const data = new Uint8Array(e.target?.result as ArrayBuffer);
                            const workbook = XLSX.read(data, { type: 'buffer' });
                            const worksheet = workbook.Sheets[workbook.SheetNames[0]];
                            const jsonData = XLSX.utils.sheet_to_json(worksheet);
                            setModalData(jsonData.map((v: any) => {
                                return {
                                    "ApplicationId": v["ApplicationId"] || null,
                                    "FirstName": v["FirstName"] || null,
                                    "LastName": v["LastName"] || null,
                                    "態度舉止": v["態度舉止"] || 0,
                                    "口語表達": v["口語表達"] || 0,
                                    "英語能力": v["英語能力"] || 0,
                                    "普通話能力": v["普通話能力"] || 0,
                                    "生活體驗": v["生活體驗"] || 0,
                                    "講故事": v["講故事"] || 0,
                                    "AppearancesPositive": v["AppearancesPositive"] || "",
                                    "other": v["other"] || null,
                                    "AppearancesNegative": v["AppearancesNegative"] || "",
                                    "other_1": v["other_1"] || null,
                                    "AbilitiesPositive": v["AbilitiesPositive"] || "",
                                    "other_2": v["other_2"] || null,
                                    "AbilitiesNegative": v["AbilitiesNegative"] || null,
                                    "other_3": v["other_3"] || null,
                                    "Skill": v["Skill"] || "",
                                    "other_4": v["other_4"] || null,
                                    "Comment": v["Comment"] || "",
                                    "Q1 Chinese": v["Q1 Chinese"] || 0,
                                    "Q2 Chinese": v["Q2 Chinese"] || 0,
                                    "Q3 Chinese": v["Q3 Chinese"] || 0,
                                    "Q1 English": v["Q1 English"] || 0,
                                    "Q2 English": v["Q2 English"] || 0,
                                    "Q3 English": v["Q3 English"] || 0,
                                    "Lang Positive": v["Lang Positive"] || "",
                                    "other_5": v["other_5"] || null,
                                    "Lang Negative": v["Lang Negative"] || "",
                                    "other_6": v["other_6"] || null,
                                    "Comment_1": v["Comment_1"] || "",
                                }
                            }).filter(v => v.ApplicationId != null
                                && v.FirstName != null
                                && v.LastName != null


                            ))
                        };
                        reader.readAsArrayBuffer(file);
                        return false;
                    }}
                    name="files"
                    accept=".csv, text/csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel">
                    <p className="ant-upload-drag-icon">
                        <InboxOutlined />
                    </p>
                    <p className="ant-upload-text">{t("Click or drag file to this area to upload")}</p>
                </Upload.Dragger>
                <Button type="link" onClick={() => { applicationService.downloadTemplate(id || "", toUpload.uploadType).then((res) => { }) }} >{t("Template Download")}</Button>
                <AntDProTable<any>
                    filterType={"light"}
                    size={"small"}

                    onRefCallback={(ref: any) => {
                    }}
                    pageSizeOptions={[100, 200]}
                    columns={[
                        {
                            title: t("Application ID"),
                            dataIndex: "ApplicationId",
                            hideInSearch: true,
                        },
                        {
                            title: t("Given Name (English)"),
                            hideInSearch: true,
                            dataIndex: "FirstName"
                        },
                        {
                            title: t("Surname (English)"),
                            hideInSearch: true,
                            dataIndex: "LastName"
                        },
                        {
                            title: t("Attitude and Demeanor"),
                            hideInSearch: true,
                            dataIndex: "態度舉止"
                        },
                        {
                            title: t("Oral Expression"),
                            hideInSearch: true,
                            dataIndex: "口語表達"
                        },
                        {
                            title: t("English Proficiency"),
                            hideInSearch: true,
                            dataIndex: "英語能力"
                        },
                        {
                            title: t("Mandarin Proficiency"),
                            hideInSearch: true,
                            dataIndex: "普通話能力"
                        },
                        {
                            title: t("Life Experience"),
                            hideInSearch: true,
                            dataIndex: "生活體驗"
                        },
                        {
                            title: t("Story Telling"),
                            hideInSearch: true,
                            dataIndex: "講故事"
                        },
                        {
                            title: t("Appearances Positive"),
                            hideInSearch: true,
                            dataIndex: "AppearancesPositive"
                        },
                        {
                            title: t("Appearances Positive"),
                            hideInSearch: true,
                            dataIndex: "other"
                        },
                        {
                            title: t("Apperances Negative"),
                            hideInSearch: true,
                            dataIndex: "AppearancesNegative"
                        },
                        {
                            title: t("Apperances Negative"),
                            hideInSearch: true,
                            dataIndex: "other_1"
                        },
                        {
                            title: t("Abilities Positive"),
                            hideInSearch: true,
                            dataIndex: "AbilitiesPositive"
                        },
                        {
                            title: t("Abilities Positive"),
                            hideInSearch: true,
                            dataIndex: "other_2"
                        },
                        {
                            title: t("Abilities Negative"),
                            hideInSearch: true,
                            dataIndex: "AbilitiesNegative"
                        },
                        {
                            title: t("Abilities Negative"),
                            hideInSearch: true,
                            dataIndex: "other_3"
                        },
                        {
                            title: t("Skill"),
                            hideInSearch: true,
                            dataIndex: "Skill"
                        },
                        {
                            title: t("Skill"),
                            hideInSearch: true,
                            dataIndex: "other_4"
                        },
                        {
                            title: t("Remark"),
                            hideInSearch: true,
                            dataIndex: "Comment"
                        },
                        {
                            title: t("Q1 Chinese"),
                            hideInSearch: true,
                            dataIndex: "Q1 Chinese"
                        },
                        {
                            title: t("Q2 Chinese"),
                            hideInSearch: true,
                            dataIndex: "Q2 Chinese"
                        },
                        {
                            title: t("Q3 Chinese"),
                            hideInSearch: true,
                            dataIndex: "Q3 Chinese"
                        },
                        {
                            title: t("Q1 English"),
                            hideInSearch: true,
                            dataIndex: "Q1 English"
                        },
                        {
                            title: t("Q2 English"),
                            hideInSearch: true,
                            dataIndex: "Q2 English"
                        },
                        {
                            title: t("Q3 English"),
                            hideInSearch: true,
                            dataIndex: "Q3 English"
                        },
                        {
                            title: t("Language Positive"),
                            hideInSearch: true,
                            dataIndex: "Lang Positive"
                        },
                        {
                            title: t("Language Positive"),
                            hideInSearch: true,
                            dataIndex: "other_5"
                        },
                        {
                            title: t("Language Negative"),
                            hideInSearch: true,
                            dataIndex: "Lang Negative"
                        },
                        {
                            title: t("Language Negative"),
                            hideInSearch: true,
                            dataIndex: "other_6"
                        },
                        {
                            title: t("Remark"),
                            hideInSearch: true,
                            dataIndex: "Comment_1"
                        }
                    ]}
                    rowKey="timeslot"
                    scroll={{ x: 'max-content' }}

                    dataSource={modalData}
                />
            </Modal>}
        </>
    )
}

