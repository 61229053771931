import { useEffect, useRef, useState } from "react"
import { useTranslation } from "react-i18next"

import { ActionType, ProColumns } from "@ant-design/pro-components"
import { Button, Col, DatePicker, Form, InputNumber, Modal, Row, Select, Upload } from "antd"

import { useNavigate, useParams } from "react-router-dom"
import { AntDProTable } from "../../../../components/antdProTable"
import { Timeslot } from "../../../../models/timeslot"
import { applicationService } from "../../../../services/applicationService"
import moment from "moment"
import React from "react"
import { useForm } from "antd/es/form/Form"
import dayjs, { Dayjs } from "dayjs"
import { InboxOutlined } from "@ant-design/icons"
import * as XLSX from 'xlsx';

//Start
export function AllTimeslotListPage() {
    const { t } = useTranslation()
    const { id } = useParams();
    const navigate = useNavigate();
    const [loading, setLoading] = useState<boolean>(false)
    const [menuActivekey, setMenuActiveKey] = useState<string>("application-all")
    const [filterByStatus, setFilterByStatus] = useState<number[]>([])
    const [timeslot, setTimeslot] = useState<Timeslot>()
    const [addTimeslot, setAddTimeslot] = useState<boolean>(false)
    const [addTimeslotBatch, setAddTimeslotBatch] = useState<boolean>(false)
    const [batchUploadTimeslot, setBatchUploadTimeslot] = useState<Timeslot[]>([])
    const [batchConfirmLoading, setBatchConfirmLoading] = useState(false);
    const [formTimeslotType, setFormTimeslotType] = useState<number>(1)
    let tableRef = useRef<ActionType>()

    const [f1] = useForm();//edit
    const [f2] = useForm();//createOne


    const reload = () => {
        tableRef.current?.reload()
    }
    useEffect(() => {
        reload();
    }, [filterByStatus])
    const columns: ProColumns<Timeslot>[] = [
        {
            title: t("Timeslot Type"),
            dataIndex: "timeslotType",
            sorter: true,
            valueEnum: {
                1: { text: t("1st Interview") },
                2: { text: t("2nd Interview") },
                3: { text: t("3rd Interview") },
                10: { text: t("Offer Letter Collection Time") }
            }
        },

        {
            title: t("Timeslots"),
            hideInSearch: true,
            copyable: true,
            defaultSortOrder: "ascend",
            sorter: true,
            dataIndex: "timeslot",
            renderText(text, record, index, action) {
                if (record.timeslotType == 10) {
                    return text.format("YYYY-MM-DD HH:mm") + " - " + (record.toTimeslot as dayjs.Dayjs).format("YYYY-MM-DD HH:mm")
                }
                return text.format("YYYY-MM-DD HH:mm")
            },
        },
        {
            title: t("Max No. of applicants"),
            sorter: true,
            hideInSearch: true,
            dataIndex: "capacity"
        },
        {
            title: t("Extra/Space quota"),
            sorter: true,
            hideInSearch: true,
            dataIndex: "extraCapacity"
        },
        {
            title: t("No. of applicants"),
            hideInSearch: true,
            dataIndex: "used"
        },
        {
            title: t("Actions"),
            dataIndex: "",
            search: false,
            fixed: 'right',
            width: 300,

            render: (text, record) => {
                return (
                    <><Button
                        onClick={() => {
                            applicationService.downloadTimeslotApplications(id || "", record.id)
                        }}
                        type="link"
                    >
                        {t("Download")}
                    </Button>

                    </>
                )
            }
        }
    ]

    return (
        <>

            <AntDProTable<Timeslot>
                filterType={"light"}
                size={"small"}

                onRefCallback={(ref: any) => {
                    tableRef.current = ref.current
                }}
                pageSizeOptions={[10, 20, 50, 100, 200]}
                columns={columns}
                // columns={columns.filter((c) => c.key !== "remove")}
                rowKey="id"
                scroll={{ x: '80vw' }}
                // scroll={{ x: "max-content" }}
                toolbar={{
                    actions: [
                        <Button key="addSingleTimeslot" type="primary" onClick={() => setAddTimeslot(true)}>
                            {t("New Timeslot")}
                        </Button>,
                        <Button key="addBatchTimeslot" type="primary" onClick={() => setAddTimeslotBatch(true)}>
                            {t("Batch Upload")}
                        </Button>,
                    ],
                }}
                request={(
                    params,
                    sort,
                    filter,
                    skip,
                    take,
                    where,
                    sortBy,
                    order,
                    cb
                ) => {
                    if (filterByStatus.length > 0) {
                        where.Status = filterByStatus
                    }
                    setLoading(true);
                    applicationService.getTimeslotsFromYearGroup(id || "", {
                        params,
                        sort,
                        filter,
                        skip,
                        take,
                        where,
                        sortBy: sortBy ? sortBy : "timeslot",
                        order,
                    }).then(res => {
                        setLoading(false);
                        if (res.status === "OK") {
                            cb({
                                data: res.reference.result.map(d => {
                                    let p: Timeslot = {
                                        ...d,
                                        timeslot: dayjs(d.timeslot),
                                        toTimeslot: d.toTimeslot ? dayjs(d.toTimeslot) : dayjs(),
                                    }
                                  
                                    return p;
                                }),
                                total: res.reference.recordTotal,
                                success: true,
                                page: params.current,
                            })
                        } else {
                            cb({
                                data: [],
                                total: 0,
                                success: false,
                                page: params.current,
                            })
                        }
                    }).catch(err => {
                        setLoading(false);
                        cb({
                            data: [],
                            total: 0,
                            success: false,
                            page: params.current,
                        })
                    })
                }}
            />
            <Modal
                title={t("")}
                centered
                open={addTimeslotBatch}
                confirmLoading={batchConfirmLoading}
                onOk={async () => {
                    if (batchUploadTimeslot.length > 0) {
                        setBatchConfirmLoading(true)
                        applicationService.addTimeslots(id || "", batchUploadTimeslot.map(d => {
                            return {
                                capacity: d.capacity,
                                extraCapacity: d.extraCapacity,
                                timeslotType: d.timeslotType,
                                timeslot: (d.timeslot as Dayjs).toDate().getTime()
                            }
                        })).then(response => {

                            setBatchConfirmLoading(false)
                            if (response.status === "OK") {
                                setAddTimeslotBatch(false)
                                setBatchUploadTimeslot([])
                                tableRef.current?.reload()
                            }
                        }).catch(e => {

                            setBatchConfirmLoading(false)
                        })
                    } else {
                        setBatchUploadTimeslot([])
                        setAddTimeslotBatch(false)
                    }
                }}
                onCancel={() => {
                    setBatchUploadTimeslot([])
                    setAddTimeslotBatch(false)
                }}
                width={1000}
            >

                <Upload.Dragger
                    maxCount={1}
                    showUploadList={false}
                    beforeUpload={(file, FileList) => {
                        console.log('file', file, FileList);
                        const reader = new FileReader();
                        reader.onload = (e: ProgressEvent<FileReader>) => {
                            const data = new Uint8Array(e.target?.result as ArrayBuffer);
                            const workbook = XLSX.read(data, { type: 'buffer' });
                            const worksheet = workbook.Sheets[workbook.SheetNames[0]];
                            const jsonData = XLSX.utils.sheet_to_json(worksheet);
                            setBatchUploadTimeslot(jsonData.map((p: any) => {
                                return {
                                    capacity: parseInt(p.capacity) || 0,
                                    extraCapacity: parseInt(p.extraCapacity) || 0,
                                    timeslotType: parseInt(p.timeslotType) || 1,
                                    id: Date.now() + "-" + p.timeslotType + "-" + p.timeslot,
                                    used: 0,
                                    timeslot: dayjs(p.timeslot || new Date()),
                                    toTimeslot: dayjs(p.toTimeslot || new Date())
                                }
                            }))
                            console.log(jsonData); // Do whatever you want with the JSON data
                        };
                        reader.readAsArrayBuffer(file);
                        return false;
                    }}
                    name="files"
                    accept=".csv, text/csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel">
                    <p className="ant-upload-drag-icon">
                        <InboxOutlined />
                    </p>
                    <p className="ant-upload-text">{t("Click or drag file to this area to upload")}</p>
                </Upload.Dragger>
                <Button type="link" onClick={() => { applicationService.downloadTimeslotTemplate() }} >{t("Template Download")}</Button>
                <AntDProTable<Timeslot>
                    filterType={"light"}
                    size={"small"}

                    onRefCallback={(ref: any) => {
                    }}
                    pageSizeOptions={[100, 200]}
                    columns={[
                        {
                            title: t("Timeslot Type"),
                            dataIndex: "timeslotType",
                            hideInSearch: true,
                            valueEnum: {
                                1: { text: t("1st Interview") },
                                2: { text: t("2nd Interview") },
                                3: { text: t("3rd Interview") },
                                10: { text: t("Offer Letter Collection Time") }
                            }
                        },
                        {
                            title: t("Timeslots"),
                            hideInSearch: true,
                            copyable: true,
                            dataIndex: "timeslot",
                            renderText(text, record, index, action) {
                                const similarRecord = batchUploadTimeslot.findIndex((p) => (p.timeslot as dayjs.Dayjs).format("YYYY-MM-DD HH:mm") == text.format("YYYY-MM-DD HH:mm") && p.timeslotType == record.timeslotType)
                                if (similarRecord === index) {
                                    return text.format("YYYY-MM-DD HH:mm")
                                } else {
                                    return <span style={{ color: "red" }}>{text.format("YYYY-MM-DD HH:mm")}</span>
                                }
                            },
                        },
                        {
                            title: t("ToTimeslots"),
                            hideInSearch: true,
                            copyable: true,
                            dataIndex: "toTimeslot",
                            renderText(text, record, index, action) {
                                return text.format("YYYY-MM-DD HH:mm")
                            },
                        },
                        {
                            title: t("Max No. of applicants"),
                            hideInSearch: true,
                            dataIndex: "capacity",
                            renderText(text, record, index, action) {
                                if (text == 0) {
                                    return <span style={{ color: "red" }}>{text}</span>
                                } else {
                                    return text
                                }
                            },
                        },
                        {
                            title: t("Extra/Space quota"),
                            hideInSearch: true,
                            dataIndex: "extraCapacity"
                        }
                    ]}
                    rowKey="timeslot"
                    scroll={{ x: '80vw' }}

                    dataSource={batchUploadTimeslot}
                />
            </Modal>
            <Modal
                title={t("")}
                centered
                open={addTimeslot}
                onOk={() => {
                    f2.submit();
                    //
                }}
                onCancel={() => setAddTimeslot(false)}
                width={1000}
            >
                <Form name="timeslotForm" layout="vertical" form={f2} onFinish={(props: { timeslotType: number, timeslot: dayjs.Dayjs, toTimeslot: dayjs.Dayjs, extraCapacity: number, capacity: number }) => {
                    let form = {
                        capacity: props.capacity,
                        extraCapacity: props.extraCapacity,
                        timeslotType: props.timeslotType,
                        timeslot: (props.timeslot as Dayjs).toDate().getTime(),
                        toTimeslot: props.toTimeslot ? (props.toTimeslot as Dayjs).toDate().getTime() : undefined
                    }
                    applicationService.addTimeslot(id || "", form).then(d => {
                        if (d.status === "OK") {
                            setAddTimeslot(false)
                            tableRef.current?.reload()
                        }
                    }).catch(p => {

                    })
                }}>
                    <Row gutter={16}>
                        <Col className="gutter-row" md={24} sm={24} xs={24}>
                            <Form.Item label={t("Timeslot Type")} required={true} name={"timeslotType"}>
                                <Select
                                    value={formTimeslotType}
                                    onChange={(e) => {
                                        setFormTimeslotType(e)
                                    }}
                                    style={{ width: '100%' }}
                                    options={[{
                                        label: t("1st Interview"),
                                        value: 1,
                                    }, {
                                        label: t("2nd Interview"),
                                        value: 2,
                                    }, {
                                        label: t("3rd Interview"),
                                        value: 3,
                                    }, {
                                        label: t("3rd Interview"),
                                        value: 3,
                                    }, {
                                        label: t("Offer Letter Collection Time"),
                                        value: 10,
                                    }]}
                                />
                            </Form.Item>
                        </Col>
                        <Col className="gutter-row" md={24} sm={24} xs={24}>
                            <Form.Item label={t("Timeslots")} required={true} name={"timeslot"}>
                                <DatePicker
                                    showTime
                                    style={{ width: '100%' }} />
                            </Form.Item>
                            {formTimeslotType == 10 && <Form.Item label={t("To Timeslots")} required={true} name={"toTimeslot"}>
                                <DatePicker
                                    showTime
                                    style={{ width: '100%' }} />
                            </Form.Item>}
                        </Col>
                        <Col className="gutter-row" md={12} sm={24} xs={24}>
                            <Form.Item label={t("No. of applicants")} required={true} name={"capacity"}>
                                <InputNumber
                                    style={{ width: '100%' }} />
                            </Form.Item>
                        </Col>
                        <Col className="gutter-row" md={12} sm={24} xs={24}>
                            <Form.Item label={t("Extra/Space quota")} required={true} name={"extraCapacity"}>
                                <InputNumber
                                    style={{ width: '100%' }} />
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </Modal>

        </>
    )
}

