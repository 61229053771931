import { useTranslation } from "react-i18next"
import { EllipsisOutlined, PlusOutlined } from "@ant-design/icons"
import type { ActionType, ListToolBarProps, ProColumns } from "@ant-design/pro-components"
import { ProTable, TableDropdown } from "@ant-design/pro-components"
import en from "antd/lib/locale/en_US"
import tc from "antd/lib/locale/zh_TW"
import cn from "antd/lib/locale/zh_CN"

import { Button, ConfigProvider, Dropdown, Space, Tag } from "antd"
import {
  CSSProperties,
  ForwardedRef,
  ReactNode,
  useEffect,
  useRef,
  useState,
} from "react"
import { SortOrder } from "antd/lib/table/interface"
import { SizeType } from "antd/lib/config-provider/SizeContext"
import ProTableNewButton from "./antdProTableNewButton"
import ProTableShowDeletedButton from "./antdProTableDeleteButton"

interface TableProps<T> {
  search?: false | undefined
  columns: ProColumns<T>[]
  cardBordered?:
  | boolean
  | {
    search?: boolean
    table?: boolean
  }
  | undefined
  rowKey: string
  scroll?:
  | ({
    x?: string | number | true | undefined
    y?: string | number | undefined
  } & {
    scrollToFirstRowOnChange?: boolean | undefined
  })
  | undefined
  headerTitle?: string
  pageSize?: number | undefined
  pageSizeOptions?:  number[] 
  showQuickJumper?:
  | boolean
  | {
    goButton?: ReactNode
  }
  | undefined
  toolbar?: ListToolBarProps | undefined
  toolBarRender?:
  | false
  | ((
    action: ActionType | undefined,
    rows: {
      selectedRowKeys?: (string | number)[] | undefined
      selectedRows?: T[] | undefined
    }
  ) => ReactNode[])
  | undefined

  filterType?: "query" | "light" | undefined

  size?: SizeType

  style?: CSSProperties | undefined

  showDeletedButton?: {
    showDeleted: boolean
    setShowDeleted: (_showDeleted: boolean) => void
    // onClick: () => void
  }

  newButton?: {
    onClick: () => void
  }

  dataSource?: readonly T[] | undefined

  request?: (
    params: any,
    sort: any,
    filter: any,
    skip: any,
    take: any,
    where: any,
    sortBy: any,
    order: any,
    cb: any
  ) => void

  onRefCallback: (ref: any) => void
}

export function AntDProTable<T extends Record<string, any>>(
  props: TableProps<T>
) {
  const { t, i18n } = useTranslation()

  const intlMap = {
    en: en,
    cn: cn,
    tc: tc,
  }

  const [pageSize, setPageSize] = useState(props.pageSizeOptions?props.pageSizeOptions[0]:10)
  const [currentPage, setCurrentPage] = useState(1)

  useEffect(() => {
    if (props.pageSize !== undefined) {
      setPageSize(props.pageSize)
    }
  }, [props.pageSize])

  const [lang, setLang] = useState<keyof typeof intlMap>("en")

  useEffect(() => {
    switch (i18n.language) {
      case "en":
        setLang("en")
        break
      case "cn":
        setLang("cn")
        break
      case "tc":
        setLang("tc")
    }
  }, [i18n.language])

  const actionRef = useRef<ActionType>()
  useEffect(() => {
    props.onRefCallback(actionRef)
  }, [actionRef])

  function reload() {
    actionRef.current?.reload()
  }

  const [size, setSize] = useState<SizeType>("middle")

  useEffect(() => {
    setSize(props.size)
  }, [props.size])

  return (
    <ConfigProvider locale={intlMap[lang]}>
      <ProTable<T>
        
        search={
          props.search === false
            ? false
            : {
              labelWidth: "auto",
              filterType: props.filterType ? props.filterType : "query",
            }
        }
        // search={{
        //   labelWidth: "auto",
        //   filterType: props.filterType ? props.filterType : "query",
        // }}
        columns={props.columns}
        actionRef={actionRef}
        cardBordered={props.cardBordered}
        request={
          props.request
            ? async (
              params: {
                pageSize?: number
                current?: number
                keyword?: string
              },
              sort: Record<string, SortOrder>,
              filter: Record<string, (string | number)[] | null>
            ) => {
              let where = { ...params }
              // console.log("where", where)
              delete where.current
              delete where.pageSize
              let sortBy = ""
              let orderBy = "asc"
              if (sort) {
                orderBy = Object.values(sort)
                  .map((d) => (d == "ascend" ? "asc" : "desc"))
                  .join("")
                sortBy = Object.keys(sort).join("")
              }

              const skip =
                ((params.current ?? 1) - 1) * (params.pageSize ?? 1)
              const take = params.pageSize
              const order =
                orderBy == "asc" || orderBy == "desc" ? orderBy : "asc"

              return new Promise((resolve) => {
                props.request?.(
                  params,
                  sort,
                  filter,
                  skip,
                  take,
                  where,
                  sortBy,
                  order,
                  resolve
                )
              })
            }
            : undefined
        }
        editable={{
          type: "multiple",
        }}
        columnsState={{
          persistenceKey: "pro-table-singe-demos",
          persistenceType: "localStorage",
          onChange(value: any) {
            // console.log("value: ", value)
          },
        }}
        size={size}
        onSizeChange={(densitySize) => {
          setSize(densitySize)
        }}
        rowKey={props.rowKey}

        dataSource={props.dataSource}
        onDataSourceChange={(data) => {
          if (data.length === 0 && currentPage !== 1) {
            setCurrentPage(1)
            reload()
          }
        }}
        pagination={{
          showSizeChanger: props.pageSizeOptions !== undefined,
          pageSizeOptions:props.pageSizeOptions,
          pageSize: pageSize,
          current: currentPage,
          onChange: (page: any) => {
            // console.log("page", page)
          },
          onShowSizeChange(current, size) {
            setCurrentPage(current)
            setPageSize(size)
          },

          showQuickJumper: props.showQuickJumper,
        }}
        dateFormatter="string"
        headerTitle={props.headerTitle}
        scroll={props.scroll}
        style={props.style}
        toolbar={props.toolbar}
        toolBarRender={(action, { selectedRows }) => [
          ...(typeof props.toolBarRender === "function"
            ? props.toolBarRender(action, { selectedRows })
            : []),

          props.showDeletedButton ? (
            <ProTableShowDeletedButton
              showDeleted={props.showDeletedButton.showDeleted}
              onClick={() => {
                setCurrentPage(1)
                reload()

                props.showDeletedButton?.setShowDeleted(
                  !props.showDeletedButton.showDeleted
                )
              }}
            />
          ) : null,
          props.newButton ? (
            <ProTableNewButton
              onClick={() => {
                props.newButton?.onClick()
              }}
            />
          ) : null,
        ]}
      />
    </ConfigProvider>
  )
}
